import * as THREE from 'three';
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

let currentBackgroundDisplayMesh; 

export const loadNewBackgroundDisplayImage = (newMapImage, scene, is360Seamless=false) => {
  let texture = new THREE.TextureLoader().load(newMapImage, null, null, (err) => console.log('err', err));
  scene.remove(currentBackgroundDisplayMesh)

  if (is360Seamless) {
    const geometry = new THREE.SphereGeometry( 25, 60, 40 );
    geometry.scale( - 1, 1, 1 );
    const material = new THREE.MeshBasicMaterial( { map: texture } );
    const mesh = new THREE.Mesh( geometry, material );
    scene.add( mesh );
    currentBackgroundDisplayMesh = mesh; 
  } else {
    scene.background = texture; 
  };
};

export const loadNewBackgroundDisplayImageForExr = (newMapImage, scene, is360Seamless=false) => {
  let texture = new EXRLoader().load(newMapImage,  null, null, (err) => console.log('EXR loader ERR', err))
  scene.remove(currentBackgroundDisplayMesh)

  if (is360Seamless) {
    const geometry = new THREE.SphereGeometry( 25, 60, 40 );
    geometry.scale( - 1, 1, 1 );
    const material = new THREE.MeshBasicMaterial( { map: texture } );
    const mesh = new THREE.Mesh( geometry, material );
    scene.add( mesh );
    currentBackgroundDisplayMesh = mesh; 
  } else {
    scene.background = texture; 
  };
};

export const loadNewBackgroundDisplayImageForHdr = (newMapImage, scene, is360Seamless=false) => {
  let texture = new RGBELoader().load(newMapImage)
  scene.remove(currentBackgroundDisplayMesh)
  
  if (is360Seamless) {
    const geometry = new THREE.SphereGeometry( 25, 60, 40 );
    geometry.scale( - 1, 1, 1 );
    const material = new THREE.MeshBasicMaterial( { map: texture } );
    const mesh = new THREE.Mesh( geometry, material );
    scene.add( mesh );
    currentBackgroundDisplayMesh = mesh; 
  } else {
    scene.background = texture; 
  };
};
