import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import { AppContext } from "../../../App";
import ToggleInput from "../inputs/ToggleInput";
import ToggleInputWithLabel from "../inputs/ToggleInputWithLabel";
import TextInput from "../inputs/TextInput";
import {
  PanelOption,
  PanelOptionHeading,
  TabContainer,
  TabSelector,
} from "./shared";

const RowContainer = styled.div`
  display: flex;
  padding: 8px 0;
`;

const RowItemContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LabelContainer = styled.h3`
  margin-top: 0;
  margin-bottom: 0.75rem;
  color: #072b62;
`;

const SliderInputContainer = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: center;
`;

const SliderTextInput = styled(TextInput)`
  width: 55px;
  margin-left: 16px;
`;

const GeneralPanel = () => {
  // State
  const [selectedCanvasAspectRatio, setSelectedCanvasAspectRatio] =
    useState("auto"); // auto, square, landscape, portrait
  const [isShadowIntensityTabSelected, setIsShadowIntensityTabSelected] =
    useState(true);

  // Context
  const {
    shouldEnableInteractionPrompt,
    setShouldEnableInteractionPrompt,
    shouldAutoRotateModel,
    setShouldAutoRotateModel,
    modelRotationSpeed,
    setModelRotationSpeed,
    productShadowIntensity,
    setProductShadowIntensity,
    productShadowBlur,
    setProductShadowBlur,
    canvasSize,
    context,
    setShowInteractionPrompt,
    enableWatermark,
    setEnableWatermark,
  } = useContext(AppContext);

  useEffect(() => {
    if (canvasSize.x === "Auto" || canvasSize.y === "Auto") {
      setSelectedCanvasAspectRatio("auto");
    } else if (parseInt(canvasSize.x) === parseInt(canvasSize.y)) {
      setSelectedCanvasAspectRatio("square");
    } else if (parseInt(canvasSize.x) > parseInt(canvasSize.y)) {
      setSelectedCanvasAspectRatio("landscape");
    } else if (parseInt(canvasSize.x) < parseInt(canvasSize.y)) {
      setSelectedCanvasAspectRatio("portrait");
    }
  }, [canvasSize]);

  // @Param: isEnabled = boolean
  const rotateHandler = (isEnabled) => {
    setShouldAutoRotateModel(isEnabled);

    // Create function to pause and start rotation
    let timeOut = null;
    const touchCall = () => {
      context.setPauseAutoRotateModel(true);

      if (timeOut) {
        clearTimeout(timeOut);
        timeOut = null;
        return timeOut;
      }

      return (timeOut = setTimeout(() => {
        context.setPauseAutoRotateModel(false);
      }, 7500));
    };

    // If to add and remove EventListeners for touch and rotate
    if (isEnabled) {
      "mousedown touch".split(" ").forEach((event) => {
        context.sceneRenderer.domElement.addEventListener(event, touchCall);
      });
    } else {
      if (timeOut) {
        clearTimeout(timeOut);
      }
      context.sceneRenderer.domElement.removeEventListener("click", touchCall);
      context.sceneRenderer.domElement.removeEventListener("touch", touchCall);
    }
  };

  return (
    <div>
      <ToggleInputWithLabel
        label={"Interaction Prompt:"}
        value={shouldEnableInteractionPrompt}
        onChange={(newValue) => setShouldEnableInteractionPrompt(newValue)}
        onClick={() => {
          context.componentJustMounted.current = true;
          setShowInteractionPrompt(0);
          context.tween.current && context.tween.current.stop();
        }}
      />
      <PanelOption>
        <RowContainer>
          <RowItemContainer style={{ marginRight: "2rem" }}>
            <LabelContainer>{"Auto Rotate:"}</LabelContainer>
            <ToggleInput
              value={shouldAutoRotateModel}
              onChange={(newValue) => rotateHandler(newValue)}
              onClick={() =>
                context.tween.current && context.tween.current.stop()
              }
            />
          </RowItemContainer>
          <RowItemContainer>
            <LabelContainer>{"Speed:"}</LabelContainer>
            <TextInput
              value={modelRotationSpeed}
              onChange={(newValue) => setModelRotationSpeed(Number(newValue))}
              unit={"/sec"}
            />
          </RowItemContainer>
        </RowContainer>
      </PanelOption>
      <PanelOption>
        <PanelOptionHeading>{"Product Shadow"}</PanelOptionHeading>
        <TabSelector>
          <div>
            <TabContainer
              isSelected={isShadowIntensityTabSelected}
              onClick={() => setIsShadowIntensityTabSelected(true)}
            >
              {"Intensity"}
            </TabContainer>
            <TabContainer
              isSelected={!isShadowIntensityTabSelected}
              onClick={() => setIsShadowIntensityTabSelected(false)}
            >
              {"Blur"}
            </TabContainer>
          </div>
        </TabSelector>
        <SliderInputContainer>
          <Slider
            value={
              isShadowIntensityTabSelected
                ? productShadowIntensity
                : productShadowBlur
            }
            onChange={(newValue) =>
              isShadowIntensityTabSelected
                ? setProductShadowIntensity(newValue)
                : setProductShadowBlur(newValue)
            }
            railStyle={{
              backgroundColor: "#eee",
            }}
            trackStyle={{
              backgroundColor: "#a7b2c2",
            }}
            handleStyle={{
              backgroundColor: "#072b62",
              border: "none",
              boxShadow: "none",
            }}
          />
          <SliderTextInput
            value={
              isShadowIntensityTabSelected
                ? productShadowIntensity
                : productShadowBlur
            }
            onChange={(newValue) =>
              isShadowIntensityTabSelected
                ? setProductShadowIntensity(newValue)
                : setProductShadowBlur(newValue)
            }
          />
        </SliderInputContainer>
      </PanelOption>
      <PanelOption>
        <RowContainer>
          <RowItemContainer>
            <LabelContainer style={{ textAlign: "left", width: "100%" }}>
              {"Watermark"}
            </LabelContainer>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p>Cela Logo</p>
              <ToggleInput
                value={enableWatermark}
                onChange={(newValue) => setEnableWatermark(newValue)}
              />
            </div>
          </RowItemContainer>
        </RowContainer>
      </PanelOption>
    </div>
  );
};

export default GeneralPanel;

// @todo: rename 'unit' in textinput to additionalText or such.
