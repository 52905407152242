import styled from "styled-components";

import GeneralPanel from "./client/panels/GeneralPanel";
import LightingPanel from "./client/panels/LightingPanel";
import ProductRedirectPanel from "./client/panels/ProductRedirectPanel";
import AugmentedRealityExperiencesPanel from "./client/panels/AugmentedRealityExperiencesPanel";
import AnnotationsPanel from "./client/panels/AnnotationsPanel";
import ScenePanel from "./client/panels/ScenePanel";
import SceneEditorPanel from "./client/panels/SceneEditorPanel";
import CustomizationPanel from "./client/panels/CustomizationPanel";
import MeasurementAndComparisonPanel from "./client/panels/MeasurementAndComparisonPanel";
import { primaryMenuItems } from "../config";

const ClientOptionsContainer = styled.div`
  min-width: 320px;
  max-width: 320px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  > a {
    text-decoration: none;
    color: #ffffff;
  }
`;

const AppBackButton = styled.div`
  background-color: #072b62;
  padding: 16px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #7689a4;
  }

  &:active {
    background-color: #d3d8de;
  }
`;

const Header = styled.div`
  padding: 32px;
`;

const Logo = styled.div`
  width: 128px;
  & > img {
    max-width: 100%;
  }
`;

const Title = styled.div`
  color: #000;
  margin-top: 16px;
  font-size: 22px;
  font-weight: 700;
`;

const PrimaryMenu = styled.div`
  overflow-y: auto;
`;

const PrimaryMenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 72px;
  height: 72px;
  padding: 0 24px;
  cursor: pointer;
  & > img {
    width: 48px;
  }
  & > p {
    color: #072b62;
    padding-left: 8px;
  }
  ${({ isPanelHeading }) =>
    !isPanelHeading &&
    `
    border-top: 1px solid #e7eaef;
    &:last-child {
      border-bottom: 1px solid #e7eaef;
    }
    &:hover {
      background-color: #7688a3;
    }
    &:active {
      background-color: #d3d8de;
    }
  `}
  ${({ isPanelHeading }) =>
    isPanelHeading &&
    `
    cursor: initial;
    background-color: #e7eaf0;
    border-top: 1px solid #7688a3;
    border-bottom: 1px solid #7688a3;
  `}
`;

const PanelContainer = styled.div`
  overflow-y: auto;
  color: #000;
`;

const PanelBackButton = styled.div`
  color: #072b62;
  padding: 16px 32px;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    font-weight: bold;
  }

  &:active {
    opacity: 0.5;
  }
`;

const PrimaryMenuItem = ({ menuItem, onClick, isPanelHeading }) => (
  <PrimaryMenuItemContainer onClick={onClick} isPanelHeading={isPanelHeading}>
    <img src={menuItem && menuItem.iconImageSource} alt={menuItem.title} />
    <p>{menuItem && menuItem.title}</p>
  </PrimaryMenuItemContainer>
);

const ClientOptions = ({ selectedPanel, setSelectedPanel, context }) => {
  return (
    <ClientOptionsContainer>
      <a
        href='https://thecela.com/member/inventory'
        rel='noreferrer'
        target='_blank'
      >
        <AppBackButton>{"< Back to Inventory"}</AppBackButton>
      </a>
      <Header>
        <Logo>
          <img src='/images/misc/logo_cela.svg' alt='Cela Logo' />
        </Logo>
        <Title>Showroom Editor</Title>
      </Header>
      {!selectedPanel && (
        <PrimaryMenu>
          {primaryMenuItems.map((menuItem, menuItemIndex) => (
            <PrimaryMenuItem
              menuItem={menuItem}
              key={menuItemIndex}
              onClick={() => setSelectedPanel(menuItem.slug)}
            />
          ))}
        </PrimaryMenu>
      )}
      {selectedPanel && (
        <>
          {selectedPanel === "scene-editor" && (
            <PrimaryMenuItem menuItem={primaryMenuItems[1]} isPanelHeading />
          )}
          {selectedPanel !== "scene-editor" && (
            <PrimaryMenuItem
              menuItem={primaryMenuItems.find((o) => o.slug === selectedPanel)}
              isPanelHeading
            />
          )}
          {/* {selectedPanel === 'scene-editor' && <PanelBackButton onClick={() => setSelectedPanel('scene')}>{'< Back'}</PanelBackButton>} */}
          {selectedPanel !== "scene-editor" && (
            <PanelBackButton onClick={() => setSelectedPanel(null)}>
              {"< Back"}
            </PanelBackButton>
          )}
          {/* @todo: above is a temporary measure. */}
          {/* <PrimaryMenuItem menuItem={primaryMenuItems.find(o => o.slug === selectedPanel)} isPanelHeading /> */}
          {/* <PanelBackButton onClick={() => setSelectedPanel(null)}>{'< Back'}</PanelBackButton> */}
          <PanelContainer>
            {selectedPanel === "general" && <GeneralPanel />}
            {/* {selectedPanel === 'scene' && <ScenePanel />} */}

            {/* @lewis-note: this is for testing sceneeditor*/}
            {selectedPanel === "scene" && (
              <ScenePanel
                setSelectedPanel={setSelectedPanel}
                context={context}
              />
            )}
            {selectedPanel === "scene-editor" && (
              <SceneEditorPanel context={context} />
            )}
            {/* {selectedPanel === 'camera' && <CameraPanel />} */}
            {selectedPanel === "customization" && (
              <CustomizationPanel setSelectedPanel={setSelectedPanel} />
            )}
            {selectedPanel === "lighting" && <LightingPanel />}
            {selectedPanel === "augmented-reality-experiences" && (
              <AugmentedRealityExperiencesPanel />
            )}
            {selectedPanel === "product-redirect" && <ProductRedirectPanel />}
            {selectedPanel === "annotations" && <AnnotationsPanel />}
            {selectedPanel === "measurement-and-comparison" && (
              <MeasurementAndComparisonPanel context={context} />
            )}
          </PanelContainer>
        </>
      )}
    </ClientOptionsContainer>
  );
};

export default ClientOptions;

// @todo: get exported PNG icons later.
