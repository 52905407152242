import styled from 'styled-components';

export const DualButtonsContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  margin: ${({ margin }) => margin ? margin : '24px 0 0 0'};
  color: #072B62;
  align-items: center;
  > *+* {
    margin-left: 16px;
  }
  > button {
    flex: 1;
    border: 1px solid #072B62;
    border-radius: 5px;
    padding: 8px 0;
    cursor: pointer;
    text-align: center;
  }
`;

export const OptionButtonContainer = styled.div`
  border: 1px solid #072B62;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px 12px;
  text-align: center;
  color: #072B62;
  cursor: pointer;
`;

export const DropDownContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid rgba(7, 43, 98, 0.5);
  > div:nth-child(2) {
    display: ${({ shouldShowDropDown }) => shouldShowDropDown ? 'block' : 'none'}
  };
`;

export const SelectedObjectContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  border-bottom: ${({ shouldShowDropDown }) => shouldShowDropDown ? '1px solid rgba(7, 43, 98, 0.5)' : 'none'};
  padding: 12px 16px 8px;
  justify-content: space-between;
  > *+* {
    padding-left: 16px;
  }
  > div > img {
    margin-top: 2px;
    width: 16px;
    height: 16px;
  }
`;

export const DropDownListItemContainer = styled.div`
  border-bottom: 1px solid rgba(7, 43, 98, 0.5);
  padding: 12px 16px 8px;
`;

export const DropDownListContainer = styled.div`
  overflow: auto;
  > div:last-child {
    border-bottom: none;
  }
`;