import { useEffect } from 'react';
import styled from 'styled-components';

import { CustomPicker } from "react-color";
import {
  EditableInput,
  Hue,
  Saturation
} from "react-color/lib/components/common";

/*

Used in the ChangeBackgroundDisplayInput input component

*/

const ColorPickerV2Container = styled.div`
  > .saturation {
	  margin-top: 16px;
    padding: 70px 0 128px 0;
	  position: relative;
  }
  > .hue {
    padding: 8px 0 8px 0;
	  position: relative;
    margin-top: 16px;
	  .hue-horizontal {
		  border-radius: 10px;
		  > div > div {
        padding-top: 12px !important;
			  border-radius: 7px !important;
			  margin-top: 0 !important;
			  cursor: pointer;
			  border: 2px solid #fff;
			  box-sizing: border-box;
			  background: none !important;
		  };
	  };
  };
  > .inputContainer {
	  display: flex;
	  justify-content: space-between;
	  box-sizing: border-box;
	  font-size: 11px;
	  /* margin-bottom: 24px; */
    margin-top: 16px;
	  line-height: 1.5;
	  padding: 0 10px;
	  align-items: center;
	  > .inputAndPercentContainer {
		  display: flex;
		  box-sizing: border-box;
		  border: 1px solid ${({hex}) => hex};
		  input {
			  border: none;
			  border-right: 1px solid rgba(0, 0, 0, 0.125);
			  outline: none;
			  height: 100%;
			  box-sizing: border-box;
			  padding: 6px;
		  };
		  > .percent {
			  display: flex;
			  flex-direction: column;
			  justify-content: center;
			  > p {
				  margin: 0;
				  padding: 0 6px;
				};
      };
    };
  };
`;

export const ColorPickerV2 = (props) => {
  const { 
    hex, setColor, 
    labelText, 
    setValueConvertedToHex, 
    } = props;
  useEffect(() => {
		setValueConvertedToHex(hex);
  }, [hex]);
  return (
    <ColorPickerV2Container hex={hex}>
      <div className='saturation'>
        <Saturation 
        {...props}
        onChange={setColor}
        />
      </div>
      <div className='hue'>
        <Hue
          {...props}
          onChange={setColor}
        />
      </div>
      <div className='inputContainer'>
        <div className='labelText'>{labelText}</div>
        <div className='inputAndPercentContainer'>
          <EditableInput
          value={hex}
          onChange={setColor}
          />
          <div className='percent'>
            <p>100%</p>
          </div>
        </div>
      </div>
    </ColorPickerV2Container>
  );
};

export default CustomPicker(ColorPickerV2);
