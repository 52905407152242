import { useRef } from "react";
import { 
  TextLineContainer,
  TextLineOutlineContainer,ImageBoxContainer, 
  ImagePreviewContainerV2, 
  ImageContainer, FileSelectorContainer, 
  FileNameDisplayContainer, UploadButtonContainer,
} from "./shared";

const UploadBackgroundImage = ({ backgroundImageFileInput, setBackgroundImageFileInput }) => {
	const imageFileRef = useRef(null);

  const onFileUpload = input => {
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = async function (e) {
        await setBackgroundImageFileInput({file: input.files[0], src: e.target.result});
      };
      reader.readAsDataURL(input.files[0]);
    };
  };

	return (
    <>
      <ImageBoxContainer>
        {backgroundImageFileInput ? (
          <ImageContainer><img src={backgroundImageFileInput.src} alt="Background"/></ImageContainer>
          ) : 
          (<ImagePreviewContainerV2>{'Image Preview'}</ImagePreviewContainerV2>) 
        }
      </ImageBoxContainer>
      <FileSelectorContainer>
        <FileNameDisplayContainer dashedborder={true} isEmpty={!backgroundImageFileInput}>
          {backgroundImageFileInput ? backgroundImageFileInput.file.name : 'Empty'}
        </FileNameDisplayContainer>
        <UploadButtonContainer onClick={() => imageFileRef.current.click()}>
          <input
            type='file'
            accept='.jpg, .jpeg, .exr, .png, .hdr'
            ref={imageFileRef}
            onChange={(event) => onFileUpload(event.target)}
            style={{display: 'none'}}
          />
          <span>{'Upload'}</span>
        </UploadButtonContainer>
      </FileSelectorContainer>
      <TextLineOutlineContainer>
        <TextLineContainer transparent={true}>{'Image Requirements:'}</TextLineContainer>
        <TextLineContainer>{'Minimal 960x540 px'}</TextLineContainer>
        <TextLineContainer>{'Max 500mb'}</TextLineContainer>
      </TextLineOutlineContainer>
		</>  
	);
};

export default UploadBackgroundImage;