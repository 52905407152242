import { useRef } from 'react';
import styled from 'styled-components';
import { useMousePosition } from '../../../custom-hooks/useMousePosition';

/* 

Used for setting the scene background

*/

const ColorPickerV4Container = styled.div`
position: relative;
${({ selectedBackgroundColor, selectedBackgroundType, selectedBackgroundPosition }) => {
  // console.log('height', -(selectedBackgroundPosition.height - 50)); 
        // console.log('width', selectedBackgroundPosition.width); 
     if (selectedBackgroundType === 'Linear') {
        return `background: linear-gradient(${selectedBackgroundColor} ${(selectedBackgroundPosition.height)}%, #ffffff) !important;`;
      } else if (selectedBackgroundType === 'Solid') {
        return `background: ${selectedBackgroundColor} !important;`; 
      } else if (selectedBackgroundType === 'Radial') {
        return `background: radial-gradient(at ${selectedBackgroundPosition.width}% ${selectedBackgroundPosition.height}%, ${selectedBackgroundColor}, rgba(0,0,0,0));`;
      } 
  }};
  box-sizing: border-box;
  padding: 32px;
  margin-top: 16px;
`;

const GradientPointerContainer = styled.div`
  position: absolute; 
  padding: 4px; 
  border: 2px solid #ffffff; 
  ${({ mousePosition }) => (
    `left: ${mousePosition.x}px; 
    top: ${mousePosition.y}px;`
  )};
`;

export const ColorPickerV4 = ({ value, onChange, selectedBackgroundDisplayType, selectedBackgroundDisplayPosition }) => {
  const colorPickerContainerRef = useRef(null)
  const [position, setPosition] = useMousePosition(colorPickerContainerRef); 

  return (
    <ColorPickerV4Container 
      ref={colorPickerContainerRef} 
      onMouseDown={setPosition} 
      onMouseUp={() => onChange(position)} 
      selectedBackgroundColor={value} 
      selectedBackgroundType={selectedBackgroundDisplayType}
      selectedBackgroundPosition={selectedBackgroundDisplayPosition}
    >
      <GradientPointerContainer mousePosition={position}/>
    </ColorPickerV4Container>
  );
}

export default ColorPickerV4;
