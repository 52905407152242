import { useContext, useEffect } from 'react';
import { AppContext } from '../../../App';
import ColorInput from '../inputs/ColorInput';
import ImageFileInput from '../inputs/ImageFileInput';
import MusicFileInput from '../inputs/MusicFileInput';
import SceneObjectsInput from '../inputs/SceneObjectsInput';
import { PanelOption, PanelOptionHeading, ElementContainer } from './shared';
import { loadSceneObjectsOutsideEditor } from '../../../threejs/helpers/scene-editor/dragAndDropObjects';
import { deleteAllObjectsFromScene } from '../../../threejs/helpers/scene-editor/dragAndDropObjects';

const ScenePanel = ({ setSelectedPanel }) => {
  const {
    logoImageFile, setLogoImageFile,
    musicFileList, setMusicFileList,
    scenes, setScenes,
    setCurrentlySelectedSceneBackgroundDisplayColor,
    sceneTitles, setSceneTitles,
    selectedScene, setSelectedScene,
    context
  } = useContext(AppContext);

  useEffect(() => {
    deleteAllObjectsFromScene(context);
    loadSceneObjectsOutsideEditor([...scenes[selectedScene].sceneObjects], context.addModelToScene);
    if (scenes[selectedScene].preLoadedObjects && scenes[selectedScene].preLoadedObjects.length > 0) {
      context.themeModelsGroup.remove(...context.themeModelsGroup.children);
      context.currentSceneObjects.length = 0;
      for (const model of scenes[selectedScene].preLoadedObjects) {
        if (model.isLoaded) return; 
        context.addPreLoadedModels(model, true);
      }
    }
    setCurrentlySelectedSceneBackgroundDisplayColor(scenes[selectedScene].backgroundDisplayColor)
  }, [selectedScene]); 

  const setButtonOutlineColor = (color) => {
    setScenes(prev => {
      const copy = {...prev};
      copy[selectedScene].buttonOutlineColor = color;
      return copy;
    });
  };

  return (
    <div>
      <PanelOption>
        <PanelOptionHeading>{'Scene'}</PanelOptionHeading>
        <ElementContainer>
          <SceneObjectsInput 
            value={sceneTitles}
            onChange={setSceneTitles}
            setSelectedPanel={setSelectedPanel}
            selectedScene={selectedScene}
            setSelectedScene={setSelectedScene}
            scenes={scenes}
            setScenes={setScenes}
          />
        </ElementContainer>
      </PanelOption>
      <PanelOption>
        <PanelOptionHeading>{'Button Outline Colour'}</PanelOptionHeading>
        <ElementContainer>
        {/* Sets the customer side button color */}
          <ColorInput
            value={scenes[selectedScene].buttonOutlineColor}
            onChange={setButtonOutlineColor}
          />
        </ElementContainer>
      </PanelOption>
      <PanelOption>
        <PanelOptionHeading>{'Logo'}</PanelOptionHeading>
        {/* Sets the logo for the top middle of the customer view */}
        <ElementContainer>
          <ImageFileInput 
            value={logoImageFile} 
            onChange={setLogoImageFile} 
          />
        </ElementContainer>
      </PanelOption>
      <PanelOption>
        <PanelOptionHeading>{'Music'}</PanelOptionHeading>
        <ElementContainer>
          <MusicFileInput
            value={musicFileList}
            onChange={setMusicFileList}
          />
        </ElementContainer>
      </PanelOption>
    </div>
  );
};
export default ScenePanel;

