import styled from "styled-components";
import SelectInput from "./client/inputs/SelectInput";

const PreviewBarContainer = styled.div`
  height: 64px;
  background-color: #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  z-index: 2;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.div`
  width: 96px;
  & > img {
    max-width: 100%;
  }
`;

const Title = styled.div`
  color: #000;
  font-size: 20px;
  margin-left: 24px;
`;

const CopyCodeButton = styled.div`
  margin: 0;
  color: #072b62;
  background-color: #fff;
  line-height: 40px;
  padding: 0 64px;
  border: 1px solid #072b62;
  border-radius: 4px;
  cursor: pointer;
`;

const previewTypeOptions = [
  {
    label: "Desktop Fitted Canvas",
    value: "desktop-fitted-canvas",
  },
  {
    label: "Desktop Full Screen",
    value: "desktop-full-screen",
  },
  {
    label: "Mobile Full Screen",
    value: "mobile-full-screen",
  },
  {
    label: "Mobile Fitted Canvas",
    value: "mobile-fitted-canvas",
  },
];

const PreviewBar = ({ selectedPreviewType, setSelectedPreviewType }) => {
  return (
    <PreviewBarContainer>
      <Header>
        <Logo>
          <img src='/images/misc/logo_cela.svg' alt='' />
        </Logo>
        <Title>Showroom Editor</Title>
      </Header>
      <SelectInput
        options={previewTypeOptions}
        value={selectedPreviewType}
        onChange={(newValue) => setSelectedPreviewType(newValue)}
      />
      <CopyCodeButton>Copy iFrame Code</CopyCodeButton>
    </PreviewBarContainer>
  );
};

export default PreviewBar;
