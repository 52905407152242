import styled from "styled-components";

const GifParentContainer = styled.div`
  animation: slideInFromLeft 2s ease-in-out forwards infinite alternate;
  > img {
    pointer-events: none;
    width: 62px;
  }
`;
const GifGrandParentContainer = styled.div`
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(75%);
    }
  }
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5%;
`;

const InteractionPrompt = () => {
  return (
    <GifGrandParentContainer>
      <GifParentContainer>
        <img src='gifs/hand.gif' alt='Interaction Available' />
      </GifParentContainer>
    </GifGrandParentContainer>
  );
};
export default InteractionPrompt;
