import { useState } from "react";
import styled from "styled-components";

const ToggleInputContainer = styled.div`
  cursor: pointer;
  & > div {
    width: 64px;
    height: 28px;
    border-radius: 14px;
    box-shadow: 0px 0px 2px 0px #000;
    background-color: ${({ isOn }) => (isOn ? "#072b62" : "#969595")};

    &:hover {
      background-color: #7689a4;
    }
  }
  & > div > div {
    background-color: #fff;
    width: 28px;
    height: 28px;
    border-radius: 14px;
    position: relative;
    box-shadow: 0px 0px 2px 0px #000;
    transition: linear 100ms transform;
    ${({ isOn }) =>
      isOn &&
      `
        transform: translateX(36px);
    `}
    &:active {
      transform: translateX(18px);
    }
  }
  ${({ shouldShowHoverState }) =>
    shouldShowHoverState &&
    `
    & > div > div {
      &:active {
        transform: translateX(18px);
      }
    }
  `}
`;

const ToggleInput = ({ className, value, onChange }) => {
  const [shouldShowHoverState, setShouldShowHoverState] = useState(false);
  return (
    <ToggleInputContainer
      className={className}
      onMouseEnter={() => setShouldShowHoverState(true)}
      onMouseLeave={() => setShouldShowHoverState(false)}
      onClick={() => {
        setShouldShowHoverState(false);
        onChange(!value);
      }}
      isOn={!!value}
      shouldShowHoverState={shouldShowHoverState}
    >
      <div>
        <div></div>
      </div>
    </ToggleInputContainer>
  );
};

export default ToggleInput;
