import styled from 'styled-components';

// @todo: rename to PanelOptionContainer
export const PanelOption = styled.div`
  border-top: 1px solid #e7eaef;
  &:last-child {
    border-bottom: 1px solid #e7eaef;
  }
  padding: 16px 32px;
`;

export const PanelOptionV2 = styled.div`
  border-top: 1px solid #e7eaef;
  &:last-child {
    border-bottom: 1px solid #e7eaef;
  }
  padding: 16px 32px;
  opacity: ${({ onFinishEditingEnviornment }) => onFinishEditingEnviornment ? '0.1' : '1'};
`;

export const PanelOptionHeading = styled.div`
  color: #072b62;
  padding: 8px 0;
  font-size: 20px;
  font-weight: 600;
  ${({ variation = 1 }) => variation === 2 && `
    padding: 8px 32px;
    padding-bottom: 16px;
  `}
  /* @todo: fix above later? */
`;

export const ElementContainer = styled.div`
  padding: 16px 0;
  /* padding-top: 8px; */
`;

// @todo: rename.
export const PreviewButton = styled.div`
  margin: 16px 32px; // @todo: change this.
  cursor: pointer;
  padding: 9px 17px;
  border-radius: 4px;
  text-align: center;
  background-color: #072b62;
  color: #fff;
  &:hover {
    background-color: #7688a3;
  }
  &:active {
    background-color: #d3d8de;
    color: #072b62;
  }
`;

export const HotspotsViewContainer = styled.div`
  margin: 0 32px;
  ${({ shouldAddTopMargin }) => shouldAddTopMargin && `
    margin-top: 32px;
  `}
`;

export const ButtonsRow = styled.div`
  display: flex;
  border-bottom: 2px solid #ececec;
  background-color: #f9f9f9;
  > div:nth-child(1) {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    margin-left: 6px;
    padding: 12px 6px;
    cursor: pointer;
  }
  > div:nth-child(2) {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    padding: 12px 6px;
    padding-left: 0;
    cursor: pointer;
    > img {
      width: 24px;
      margin-top: -2px;
    }
  }
  > div:nth-child(3) {
    flex: 1;
  }
  > div:last-child {
    padding: 16px;
    color: #666;
    font-size: 14px;
    cursor: pointer;
  }
`;

export const ButtonsRowV2 = styled(ButtonsRow)`
  justify-content: space-between;
  > div:last-child {
    padding: 12px 16px;
    width: auto;
  }
`;

export const List = styled.div`
  min-height: 240px;
  background-color: #f9f9f9;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 0 16px;
  border-top: 2px solid #f2f2f2;
  background-color: ${({ isSelected }) => isSelected ? 'rgba(0, 0, 0, 0.05)' : 'initial'};
  &:focus {
    background-color: rgba(196, 196, 196, 0.5);
  }
  &:first-child {
    border-top: 0;
  }
  > div:nth-child(1) {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    > img {
      width: 24px;
    }
  }
  > div:nth-child(2) {
    padding: 16px 0;
    padding-left: 16px;
  }
`;

export const ItemV2 = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 0 16px;
  border-top: 2px solid #f2f2f2;
  background-color: ${({ isSelected }) => isSelected ? 'rgba(0, 0, 0, 0.05)' : 'initial'};
  justify-content: space-between;
  &:focus {
    background-color: rgba(196, 196, 196, 0.5);
  }
  &:first-child {
    border-top: 0;
  }
  > div:nth-child(1) {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    > img {
      width: 24px;
    }
  }
  > div:nth-child(2) {
    flex: 1;
    padding: 16px 0;
    padding-left: 16px;
  }
`;

export const Indicator = styled.div`
  padding: 12px;
`;

export const Label = styled.div`
  display: flex;
  font-size: 12px;
  color: #333;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
`;

export const FractionBarContainer = styled.div`
  background-color: #ececec;
  height: 4px;
  display: flex;
  justify-content: flex-start;
  border-radius: 4px;
`;

export const FractionBar = styled.div`
  background-color: #31578f;
  height: 4px;
  width: ${({ fillFraction }) => (fillFraction * 100)}%;
  border-radius: 4px;
`;
export const TabSelector = styled.div`
  padding: 16px 0;
  > div {
    cursor: pointer;
    display: flex;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 2px 0px #ddd;
  }
`;

export const TabContainer = styled.div`
  text-align: center;
  flex: 1;
  padding: 8px 0;
  color: #999;

  &:hover {
    background-color: #7689a4;
    border-color: #7689a4;
    color: #fff;
  }
  &:active {
    background-color: #d3d8de;
    border-color: #d3d8de;
    color: #072b62;
  }

  ${({ isSelected }) => isSelected && `
    border-radius: 4px;
    background-color: #072b62;
    color: #fff;

    &:hover {
      background-color: #7688a3;
      color: #fff;
    }
    &:active {
      background-color: #d3d8de;
      color: #072b62;
    }
  `}
`;

export const RadioButton = styled.div`
  display: flex;
  justify-content: flex-start;
  background: #FFFFFF;
  border: ${({ isSelected }) => isSelected ? '4px solid #072B62': '1.5px solid #D1D1D1'};
  box-sizing: border-box;
  border-radius: 50%;
  width: ${({ diameter }) => diameter ? diameter: '16px'};
  height: ${({ diameter }) => diameter ? diameter: '16px'};
`;

export const ButtonsContainer = styled.div`
  display: flex; 
  flex-direction: column; 
  margin: 16px;
  > *+* {
    margin-top: 16px;
  }
`;

export const DualButtonsContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  margin: ${({ margin }) => margin ? margin : '24px 0 0 0'};
  color: #072B62;
  align-items: center;
  > *+* {
    margin-left: 16px;
  }
  > button {
    flex: 1;
  }
`;

export const OptionButtonContainer = styled.div`
  border: 1px solid #072B62;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px 12px;
  text-align: center;
  color: #072B62;
  cursor: pointer;
  transition: all 0.25s;

  &:hover {
    background: rgba(7, 43, 98, 0.5);
  }

  &:focus {
    background: rgba(211, 216, 222, 1);
  }

  &:active {
    background: rgba(231, 234, 240, 1);
  }
`;

export const SingleButtonContainer = styled.div`
  width: 100%;
  align-self: center;
  background-color: #601818;
  color: #ffffff; 
  border: 2px solid #601818;
  border-radius: 10px;
  text-align: center;
  padding: 12px 0;
`;