import { useContext } from "react";
import { AppContext } from "../../../App";
import {
  HotspotsViewContainer,
  ButtonsRow,
  List,
  Item,
  Indicator,
  Label,
  FractionBarContainer,
  FractionBar,
} from "../panels/shared/index";

// @todo: abstract?
const HotspotsView = () => {
  const {
    isInAddHotspotMode,
    setIsInAddHotspotMode,
    maxHotspotCount,
    hotspots,
    setHotspots,
    selectedHotspotID,
    setSelectedHotspotID,
  } = useContext(AppContext);

  // function to remove individual hotspot
  const removeHotspot = (selectedHotspotID) => {
    let newHotspot = hotspots.filter(
      (hotspot) => hotspot.id !== selectedHotspotID
    );
    setHotspots(newHotspot);
  };

  return (
    <HotspotsViewContainer shouldAddTopMargin={true}>
      <ButtonsRow>
        <div
          onClick={() => {
            setIsInAddHotspotMode(!isInAddHotspotMode);
            setSelectedHotspotID(null);
          }}
        >
          <svg
            width='31'
            height='31'
            viewBox='0 0 31 31'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <g filter='url(#filter0_d_44_13965)'>
              <line
                x1='15.5'
                y1='3.5'
                x2='15.5'
                y2='23.5'
                stroke='#808080'
                strokeWidth='3'
                strokeLinecap='round'
              />
              <line
                x1='5.5'
                y1='13.5'
                x2='25.5'
                y2='13.5'
                stroke='#808080'
                strokeWidth='3'
                strokeLinecap='round'
              />
            </g>
            <defs>
              <filter
                id='filter0_d_44_13965'
                x='0'
                y='0'
                width='31'
                height='31'
                filterUnits='userSpaceOnUse'
                colorInterpolationFilters='sRGB'
              >
                <feFlood floodOpacity='0' result='BackgroundImageFix' />
                <feColorMatrix
                  in='SourceAlpha'
                  type='matrix'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                  result='hardAlpha'
                />
                <feOffset dy='2' />
                <feGaussianBlur stdDeviation='2' />
                <feComposite in2='hardAlpha' operator='out' />
                <feColorMatrix
                  type='matrix'
                  values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'
                />
                <feBlend
                  mode='normal'
                  in2='BackgroundImageFix'
                  result='effect1_dropShadow_44_13965'
                />
                <feBlend
                  mode='normal'
                  in='SourceGraphic'
                  in2='effect1_dropShadow_44_13965'
                  result='shape'
                />
              </filter>
            </defs>
          </svg>
        </div>
        <div>
          <img
            onClick={() => {
              removeHotspot(selectedHotspotID);
            }}
            src='/images/icons/client-panel/delete_icon.svg'
            alt='Delete Icon'
          />
        </div>
        <div></div>
        <div
          onClick={() => {
            setHotspots([]);
          }}
        >
          {"Delete All"}
        </div>
      </ButtonsRow>
      <List>
        {hotspots.map((hotspot) => (
          <Item key={hotspot.id} isSelected={selectedHotspotID === hotspot.id}>
            <div>
              {hotspot.visible ? (
                <img
                  src='/images/icons/client-panel/Show-Black.svg'
                  alt='Show Icon'
                />
              ) : (
                <img
                  src='/images/icons/client-panel/Hide-Black.svg'
                  alt='Hide Icon'
                />
              )}
            </div>
            <div>{hotspot.label}</div>
          </Item>
        ))}
      </List>
      <Indicator>
        <Label>
          <div>{"Hotspots On Product"}</div>
          <div>{hotspots.length + "/" + maxHotspotCount}</div>
        </Label>
        <FractionBarContainer>
          <FractionBar fillFraction={hotspots.length / maxHotspotCount} />
        </FractionBarContainer>
        {/* @todo: ^ rethink above maybe. */}
      </Indicator>
    </HotspotsViewContainer>
  );
};

export default HotspotsView;
