import { useContext } from "react";
import styled from "styled-components";
import "rc-slider/assets/index.css";

import { AppContext } from "../../../App";

const BuyProductPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BuyButton = styled.a`
  text-decoration: none;
  color: #fff;
  background-color: #000;
  font-weight: bold;
  font-size: 16px;
  padding: 12px 16px;
  border-radius: 4px;
  display: block;

  &:hover {
    background-color: #fffff;
  }
`;

const ProductDescription = styled.div`
  color: #000;
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  p {
    text-align: center;
  }
  ul {
    padding: 0;
  }
`;

const BuyProductPanel = () => {
  const { productCheckoutURL, briefProductDescription } =
    useContext(AppContext);
  return (
    <BuyProductPanelContainer>
      <BuyButton href={productCheckoutURL} target='_blank'>
        {"Product Page"}
      </BuyButton>
      <ProductDescription
        dangerouslySetInnerHTML={{
          __html: briefProductDescription.replace("<p><br></p>", ""),
        }}
      ></ProductDescription>
    </BuyProductPanelContainer>
  );
};

export default BuyProductPanel;
