import React, { useState, useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../../../App";
import ButtonBase from "./buttons/ButtonBase";
import { DualButtonsContainer } from "./shared/index";

/*

Sets the base scene preset (Cela black, Cela White, Cela Christmas)

*/

const SceneObjectsInputContainer = styled.div`
  > * + * {
    margin-top: 24px;
  }
`;

const SceneOutlineContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const SceneSelectContainer = styled.div`
  flex: 4;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const SceneCircleContainer = styled.div`
  background: ${({ backgroundColor }) =>
    `linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), ${backgroundColor}`};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  box-sizing: border-box;
  margin-left: 4px;
  height: 44px;
  width: 44px;
`;

const SelectedSceneContainer = styled.div`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 12px;
  margin-left: 24px;
  color: rgb(0, 0, 0);
  cursor: pointer;
`;

const SceneObjectsListContainer = styled.div`
  display: ${({ shouldShowSceneObjectsList }) =>
    shouldShowSceneObjectsList ? "block" : "none"};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  max-height: 124px;
  overflow: auto;
  margin-left: 24px;
  position: absolute;
  /* top: 0; */
  top: 100%;
  left: 0;
  width: calc(100% - 24px);
  background-color: #fff;
`;

const SceneObjectItemContainer = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 12px;
  cursor: pointer;
  &:focus {
    background: rgba(196, 196, 196, 0.5);
  }
`;

function SceneObjectsInput({
  value,
  onChange,
  setScenes,
  setSelectedPanel,
  selectedScene,
  setSelectedScene,
}) {
  const [shouldShowSceneObjectsList, setShouldShowSceneObjectsList] =
    useState(false);

  const { currentlySelectedSceneBackgroundDisplayColor, context } =
    useContext(AppContext);

  const setPanelAsEditor = () => {
    setSelectedPanel("scene-editor");
    context.setPauseAutoRotateModel(!context.pauseAutoRotateModel);
  };

  /* 
    Sets new scene, and changes the global selectedScene to the new title
  */
  const deleteButtonHandler = () => {
    if (value.length > 1) {
      setScenes((prev) => {
        const copy = { ...prev };
        delete copy[selectedScene];
        return copy;
      });
      onChange((prev) => {
        const copy = [...prev];
        copy.splice(prev.indexOf(selectedScene), 1);
        return copy;
      });
      // @todo: selectedScene is bad name, it's actually selectedSceneTitle.
      // Change Name
      setSelectedScene(value.filter((o) => o !== selectedScene)[0]);
    }
  };

  return (
    <SceneObjectsInputContainer>
      <SceneOutlineContainer>
        <SceneCircleContainer
          backgroundColor={currentlySelectedSceneBackgroundDisplayColor}
        />
        <SceneSelectContainer tabIndex={0}>
          <SelectedSceneContainer
            onClick={() =>
              setShouldShowSceneObjectsList(!shouldShowSceneObjectsList)
            }
          >
            {selectedScene}
          </SelectedSceneContainer>
          <SceneObjectsListContainer
            shouldShowSceneObjectsList={shouldShowSceneObjectsList}
          >
            {value.map((title, index) => (
              <SceneObjectItemContainer
                id={index}
                key={index}
                tabIndex={index + 1}
                onClick={() => {
                  setSelectedScene(title);
                  setShouldShowSceneObjectsList(false);
                }}
              >
                {title}
              </SceneObjectItemContainer>
            ))}
          </SceneObjectsListContainer>
        </SceneSelectContainer>
      </SceneOutlineContainer>
      <DualButtonsContainer>
        <ButtonBase primary onClick={setPanelAsEditor}>
          Edit
        </ButtonBase>
        <ButtonBase primary onClick={deleteButtonHandler}>
          Delete
        </ButtonBase>
      </DualButtonsContainer>
    </SceneObjectsInputContainer>
  );
}

export default SceneObjectsInput;
