import * as THREE from "three";		
import { CSS2DObject }  from 'three/examples/jsm/renderers/CSS2DRenderer';
export class RayysLinearDimension {
  constructor(domRoot, renderer, camera, length, width, heightLength, unit) {
    this.domRoot = domRoot;
    this.renderer = renderer;
    this.camera = camera;
    this.id = 0
    this.name = 'measurement';
    this.textContent = '';
    this.heightTextContent = '';

    this.cb = {
      onChange: [],
    };
    this.config = {
      units: unit,
      length,
      width,
      height: heightLength,
    };
  }

  updateLabels(length, width, height, unit) {
    this.config = {...this.config, length, width, height, units: unit};

    this.update(this.camera);
  }

  create(p0, p1, extrude, height = 0, length, width, heightLength, unit, remove = false) {

    this.from = p0;
    this.to = p1;
    this.height = height;
    this.extrude = extrude;

    this.node = new THREE.Object3D();
    this.hidden = undefined;

    this.config.units = unit;
    if(!this.config.length || !this.config.width || this.config.height) {
      this.config.length = length;
      this.config.width = width
      this.config.height = heightLength
    }
    
    this.update(this.camera, remove);
    return this.node;
  }

  update(camera, removeLabel) {
    this.camera = camera;

    // @Note Brandon - Refactor -> Reposition, delete on detach
    if(document.querySelector('.label')) {
      if( removeLabel && document.querySelector('.label').parentElement.childElementCount > 4) {
        document.querySelector('.label').remove()
        document.querySelector('.labelTop').remove()
        
      } else {if (!removeLabel && document.querySelector('.label').parentElement.childElementCount > 2) {
        document.querySelector('.label').remove()
        document.querySelector('.labelTop').remove()
      }}
    }

    // re-create arrow
    if (!this.node) {
      return;
    }

    this.node.children.length = 0;

    let p0 = this.from;
    let p1 = this.to;
    let extrude = this.extrude;

    let pmin, pmax
    if (extrude.x >= 0 && extrude.y >= 0 && extrude.z >= 0) {
      pmax = new THREE.Vector3(
        extrude.x + Math.min(p1.x, p0.x),
        extrude.y + Math.min(p1.y, p0.y),
        extrude.z + Math.min(p1.z, p0.z)
      );

      pmin = new THREE.Vector3(
        extrude.x < 1e-16 ? extrude.x + Math.max(p1.x, p0.x) : pmax.x,
        extrude.y < 1e-16 ? extrude.y + Math.max(p1.y, p0.y) : pmax.y,
        extrude.z < 1e-16 ? extrude.z + Math.max(p1.z, p0.z) : pmax.z
      );
      } else if (extrude.x <= 0 && extrude.y <= 0 && extrude.z <= 0) {
        pmax = new THREE.Vector3(
        extrude.x + Math.max(p1.x, p0.x),
        extrude.y + Math.max(p1.y, p0.y),
        extrude.z + Math.max(p1.z, p0.z)
        );

      pmin = new THREE.Vector3(
        extrude.x > -1e-16 ? extrude.x + Math.min(p1.x, p0.x) : pmax.x,
        extrude.y > -1e-16 ? extrude.y + Math.min(p1.y, p0.y) : pmax.y,
        extrude.z > -1e-16 ? extrude.z + Math.min(p1.z, p0.z) : pmax.z
        );
      }
      
      var origin = pmax.clone().add(pmin).multiplyScalar(0.5);
      let dir = pmax.clone().sub(pmin);
      dir.normalize();
      
      let length = pmax.distanceTo(pmin) / 2;
      let heightLength = p0.distanceTo(this.height)

    const material = new THREE.LineDashedMaterial({
      color: 0x000000,
      dashSize: 0.05,
      gapSize: 0.05,
      linewidth: 3,
      linecap: 'round'
    });

    const size = 0
 
    const points = [];
    if(extrude.x === 1 || extrude.x === -1) { 
      points.push( new THREE.Vector3( size, size, length * extrude.x) );
      points.push( new THREE.Vector3( size, size, length * -extrude.x) );
    } else {
      points.push( new THREE.Vector3( length * extrude.z, size, size ) );
      points.push( new THREE.Vector3( length * -extrude.z, size, size ) );
    }
    
    // @Note Brandon - Refactor creation of lines/Labels
    const geometry = new THREE.BufferGeometry().setFromPoints( points );
    
    const line = new THREE.LineSegments( geometry, material );
    line.name = this.name
    line.computeLineDistances();
    
    if(extrude.z === 1) {
      line.position.set(origin.x, p0.y, p0.z );
    }
    if(extrude.z === -1) {
      line.position.set(-origin.x, p0.y, -p0.z );
    }
    if(extrude.x === 1) {
      line.position.set(p0.x, pmin.y, origin.z);
    } 
    if(extrude.x === -1) {
      line.position.set(-p0.x, pmax.y, origin.z);
    }
    
    const measurementDiv = document.createElement( 'div' );
    measurementDiv.className = 'labelMeasurement';
    if(extrude.x === 1 || extrude.x === -1) {
      measurementDiv.textContent = `${this.config.width} ${this.config.units}`;
      measurementDiv.id = 'width';
    } else {
      measurementDiv.textContent = `${this.config.length} ${this.config.units}`;
      measurementDiv.id = 'length';
    }
    // this.textContent = measurementDiv.textContent;
    const measurementLabel = new CSS2DObject( measurementDiv );
    measurementLabel.name = 'label';
    measurementLabel.name = this.id;
    line.add(measurementLabel)

    // TODO: @note Brandon - Refactor
      const heightPoints = [];
      heightPoints.push( new THREE.Vector3(0, heightLength, 0) );
      heightPoints.push( new THREE.Vector3( 0, 0, 0 ) );
      heightPoints.push( new THREE.Vector3(0, heightLength, 0) );
  
      const heightGeo = new THREE.BufferGeometry().setFromPoints(heightPoints);
      const yLine = new THREE.LineSegments(heightGeo, material);
      yLine.name = 'heightLine';
      yLine.computeLineDistances();
      
      if(extrude.x === 1) {
        yLine.position.set(p0.x, pmin.y, p0.z);
      }
      if(extrude.x === -1) {
        yLine.position.set(-p0.x, pmin.y, p0.z);
      }
      if(extrude.z === 1) {
        yLine.position.set(p1.x, p0.y, p0.z );
      }
      if(extrude.z === -1) {
        yLine.position.set(-p1.x, p0.y, -p0.z );
      }
  
      const measurementDiv2 = document.createElement( 'div' );
      measurementDiv2.className = 'labelTopMeasurement';
      measurementDiv2.textContent = `${this.config.height} ${this.config.units}`
      measurementDiv2.id = this.id
      
      const measurementLabel2 = new CSS2DObject( measurementDiv2 );
      measurementLabel2.name = 'label';
      measurementLabel2.name = this.id;
      measurementLabel2.position.y = this.height.y;
      this.heightTextContent = measurementDiv2.textContent;
      yLine.add(measurementLabel2);
      this.node.add(yLine);

    this.node.add( line );
  }

  detach() {
    if (this.node && this.node.parent) {
      this.node.parent.remove(this.node);
    }
    
    if (this.domElement !== undefined) {
      this.domRoot.removeChild(this.domElement);
      this.domElement = undefined;
    }
  }
}
