import React, { useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
// import styled as styles from 'styled-components';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import VolumeUp from '@mui/icons-material/VolumeUp';

import { AppContext } from '../../App';
import { handleMainModelBrightness } from '../../threejs/helpers/lighting-controls/brightnessSlider';
const Input = styled(MuiInput)`
  border: 1px solid black;
  text-align: center;
`;

// const InputParentContainer = styled.div`
//   > input {
//     border: 1px solid black;
//     text-align: center;
//   }
// `
export default function SliderWithInputField({ lightBrightness, setLightBrightness, context }) {
  // const { 
  //   lightBrightness, setLightBrightness, context
  // } = useContext(AppContext)
  
  // console.log('INSIDE LIGHTBRIGHT', lightBrightness)

  useEffect(() => {
    if (context.mainModel) {
      handleMainModelBrightness(context.mainModel, lightBrightness)
    }
  }, [lightBrightness])

  const handleSliderChange = (event, newValue) => {
    setLightBrightness(newValue);
  };

  const handleInputChange = (event) => {
    setLightBrightness(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (lightBrightness < 0) {
      setLightBrightness(0);
    } else if (lightBrightness > 100) {
      setLightBrightness(100);
    }
  };

  return (
    <Box sx={{ width: 250 }}>
      <Grid container spacing={3} alignItems="center">
        <Grid item>
        </Grid>
        <Grid item xs>
          <Slider
            value={typeof lightBrightness === 'number' ? lightBrightness : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            size="small"
          />
          {/* <StyledCustomization /> */}
        </Grid>
        <Grid item>
          {/* <InputParentContainer> */}
            <Input
              value={lightBrightness}
              size="small"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: 10,
                min: 0,
                max: 100,
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
            />
          
          {/* </InputParentContainer> */}
        </Grid>
      </Grid>
    </Box>
  );
}

const SuccessSlider = styled(Slider)(({ theme }) => ({
  width: 156,
  height: 1,
  color: theme.palette.success.main,
  '& .MuiSlider-thumb': {
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px ${alpha(theme.palette.success.main, 0.16)}`,
    },
    '&.Mui-active': {
      boxShadow: `0px 0px 0px 14px ${alpha(theme.palette.success.main, 0.16)}`,
    },
  },
}));

const StyledCustomization = () => {
  return <SuccessSlider defaultValue={30} />;
}
