import { useMemo } from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextInputContainer = styled.div`
  .ql-editor.ql-blank::before {
    font-style: normal;
  }
  .ql-toolbar.ql-snow {
    border: none;
    padding: 0;
    margin-bottom: 16px;
  }
  .ql-container.ql-snow {
    box-shadow: 0px 0px 2px 0px #ddd;
    border: 1px solid #ddd;
    border-radius: 4px;
    font: inherit;
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 1px solid #ddd;
  }
  .ql-toolbar.ql-snow button {
    border: 1px solid #072b62;
    border-radius: 4px;
    width: 26px;
    height: 26px;
    padding: 4px;
  }
  .ql-toolbar.ql-snow button:hover {
    background-color: #7688a3;
  }
  .ql-toolbar.ql-snow button:active {
    background-color: #d3d8de;
  }
  .ql-toolbar.ql-snow button+button {
    margin-left: 8px;
  }
  .ql-toolbar.ql-snow button svg {
    color: #072b62 !important;
  }
  .ql-toolbar.ql-snow button svg .ql-stroke {
    stroke: #072b62 !important;
  }
  .ql-toolbar.ql-snow button.ql-clear {
    width: initial !important;
    color: #072b62;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
  }
  .ql-toolbar.ql-snow button.ql-clear:before {
    display: inline-block;
    font-size: 14px;
    content: "Clear";
  }
  .ql-toolbar.ql-snow {
    display: flex;
    justify-content: space-between;
  }
  .ql-toolbar.ql-snow:after {
    display: none;
  }
  .ql-toolbar.ql-snow .ql-formats {
    margin: 0;
  }
  .ql-editor {
    /* text-align: center; */
  }
  .ql-editor ol, .ql-editor ul {
    /* padding-left: 0; */
  }
  .ql-editor ol > li, .ql-editor ul > li {
    padding-left: 0;
  }
`;

const RichTextInput = ({ className, value, onChange }) => {
  const modules = useMemo(() => ({
    toolbar: {
      container: [['bold', 'italic', 'underline', { 'list': 'bullet' }], ['clear']],
      handlers: {
        'clear': function () {
          this.quill.setContents('');
        }
      }
    }
  }), []);
  return (
    // @todo: may rethink height param.
    <TextInputContainer className={className} style={{ height: '288px' }}>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        placeholder={'Enter Text'}
        modules={modules}
        style={{
          height: '240px',
        }}
      />
    </TextInputContainer>
  );
};

export default RichTextInput;

