import React, { useState, useRef } from "react";
import styled from "styled-components";
import ButtonBase from "./buttons/ButtonBase";
import { DualButtonsContainer } from "./shared/index";

// @todo: duplicate.
function truncate(str, n) {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
}

const MusicListOutlineContainer = styled.div`
  > div {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 12px;
    color: ${({ selectedMusicFile }) =>
      selectedMusicFile ? "rgba(0,0,0)" : "rgba(0,0,0,0.125)"};
    cursor: pointer;
  }
`;

const MusicListContainer = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  box-sizing: border-box;
  max-height: 124px;
  overflow: auto;
  padding: 0 !important;
  ${({ shouldShow }) =>
    !shouldShow &&
    `
    display: none;
  `}
`;

const MusicListItemContainer = styled.div`
  color: rgb(0, 0, 0);
  padding: 12px;
  & + * {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
`;

function MusicFileInput({ value, onChange }) {
  const [selectedMusicFile, setSelectedMusicFile] = useState(null);
  const [shouldShowList, setShouldShowList] = useState(false);
  const MusicFileRef = useRef(null);
  const selectedMusicFileRef = useRef();
  const previewSelectedMusicFile = () => {
    if (selectedMusicFileRef.current) {
      selectedMusicFileRef.current.pause();
      selectedMusicFileRef.current.load();
      selectedMusicFileRef.current.play();
    }
  };
  const onFileUpload = (input) => {
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = async function (e) {
        const musicFile = { file: input.files[0], src: e.target.result };
        setSelectedMusicFile(musicFile);
        onChange((prev) => {
          const copy = [...prev];
          copy.push(musicFile);
          return copy;
        });
      };
      reader.readAsDataURL(input.files[0]);
    }
    setShouldShowList(false);
  };

  return (
    <>
      <MusicListOutlineContainer selectedMusicFile={selectedMusicFile}>
        <div onClick={() => setShouldShowList(!shouldShowList)}>
          {selectedMusicFile ? (
            <>
              {truncate(selectedMusicFile.file.name, 28)}
              <audio ref={selectedMusicFileRef}>
                <source src={selectedMusicFile.src} alt='Music snippet' />
              </audio>
            </>
          ) : (
            "No Music Uploaded"
          )}
        </div>
        <MusicListContainer shouldShow={shouldShowList && value.length > 0}>
          {value.map((musicFile, index) => (
            <MusicListItemContainer
              // id={index}
              key={index}
              onClick={() => {
                setSelectedMusicFile(musicFile);
                setShouldShowList(false);
              }}
            >
              {truncate(musicFile.file.name, 28)}
            </MusicListItemContainer>
          ))}
        </MusicListContainer>
      </MusicListOutlineContainer>
      <DualButtonsContainer>
        <ButtonBase primary onClick={previewSelectedMusicFile}>
          {"Preview"}
        </ButtonBase>
        <ButtonBase primary onClick={() => MusicFileRef.current.click()}>
          <input
            type='file'
            accept='.mp3, .wav, wma.'
            ref={MusicFileRef}
            onChange={(event) => onFileUpload(event.target)}
            style={{ display: "none" }}
          />
          {"Upload"}
        </ButtonBase>
      </DualButtonsContainer>
      {/* <ButtonContainer>
        <div onClick={previewSelectedMusicFile}>Preview</div>
        <div onClick={() => MusicFileRef.current.click()}>
          <input
            type='file'
            accept='.mp3, .wav, wma.'
            ref={MusicFileRef}
            onChange={(event) => onFileUpload(event.target)}
            style={{display: 'none'}}
          />
          {'Upload'}
        </div>
      </ButtonContainer> */}
    </>
  );
}

export default MusicFileInput;
