import React from 'react'
import styled from 'styled-components';

const RadioButton = styled.input`
margin-left: 1rem;
vertical-align: middle;
cursor: pointer;
`

const StyledLabel = styled.label`
  color: #000;
`

const RadioInput = ({ value, ID, name, label, onClick }) => {
  return (
    <div>
      <StyledLabel htmlFor={ID} >{label}</StyledLabel>
      <RadioButton type='radio' id={ID} name={name} checked={value} onChange={onClick} />
    </div>
  )
}

export default RadioInput