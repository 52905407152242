import { useState } from 'react';
import styled from 'styled-components';

const MultiSelectInputContainer = styled.div`
/* .. */
`;

const MultiSelectOptionContainer = styled.div`
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 32px;
  cursor: pointer;
  & > div {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0px 4px 6px 2px #ddd;
  }
  & > div > img {
    width: 36px;
  }
  & > p {
    color: #072b62;
    padding-left: 32px;
  }
  border-top: 1px solid #e7eaef;
  &:last-child {
    border-bottom: 1px solid #e7eaef;
  }
  ${({ isSelected }) => isSelected && `
    & > div {
      box-shadow: inset 0px 4px 6px 2px #ddd;
    }
  `}
  ${({ shouldShowHoverState }) => shouldShowHoverState && `
    & > div {
      border: 2px solid #7689a4;
      box-shadow: none;
      width: 44px;
      height: 44px;
    }
  `}
  &:active {
    & > div {
      border: 2px solid #d3d8de;
      box-shadow: none;
      width: 44px;
      height: 44px;
    }
  }
  /* apply to icon. border shdw etc */
`;

const MultiSelectOption = ({ option, onSelect, isSelected }) => {
  const [shouldShowHoverState, setShouldShowHoverState] = useState(false);
  return (
    <MultiSelectOptionContainer
      onMouseEnter={() => setShouldShowHoverState(true)}
      onMouseLeave={() => setShouldShowHoverState(false)}
      onClick={() => {
        setShouldShowHoverState(false);
        onSelect(option);
      }}
      isSelected={isSelected}
      shouldShowHoverState={shouldShowHoverState}
    >
      <div>
        <img src={option.iconImagePath} alt='MultiSelect Option Icon'/>
      </div>
      <p>{option.label}</p>
    </MultiSelectOptionContainer>
  );
};

const MultiSelectInput = ({ className, options, selectedOptions, onChange }) => {
  const handleSelectOption = (option) => {
    let updatedSelectedOptions = [...selectedOptions];
    const indexOfSelectedOption = updatedSelectedOptions.findIndex(o => o === option.value);
    if (indexOfSelectedOption > -1) {
      updatedSelectedOptions.splice(indexOfSelectedOption, 1);
    } else {
      updatedSelectedOptions.push(option.value);
    }
    onChange(updatedSelectedOptions);
  };

  return (
    <>
      <MultiSelectInputContainer className={className}>
        {options.map((option, optionIndex) => (
          <MultiSelectOption
            key={optionIndex}
            option={option}
            onSelect={handleSelectOption}
            isSelected={selectedOptions.findIndex(o => o === option.value) > -1}
          />
        ))}
      </MultiSelectInputContainer>
    </>
  );
};

export default MultiSelectInput;

