export const lightingOptions = [
  {
    label: "Default",
    value: "default",
    iconImagePath: "images/icons/sections/lighting/default.png",
    environmentMapImages: [
      "images/environment-maps/0/px.jpg",
      "images/environment-maps/0/nx.jpg",
      "images/environment-maps/0/py.jpg",
      "images/environment-maps/0/ny.jpg",
      "images/environment-maps/0/pz.jpg",
      "images/environment-maps/0/nz.jpg",
    ],
    isDefault: true,
  },
  {
    label: "Sunny",
    value: "sunny",
    iconImagePath: "images/icons/sections/lighting/sunny.png",
    environmentMapImages: [
      "images/environment-maps/1/px.jpg",
      "images/environment-maps/1/nx.jpg",
      "images/environment-maps/1/py.jpg",
      "images/environment-maps/1/ny.jpg",
      "images/environment-maps/1/pz.jpg",
      "images/environment-maps/1/nz.jpg",
    ],
  },
  {
    label: "Cloudy",
    value: "cloudy",
    iconImagePath: "images/icons/sections/lighting/cloudy.png",
    environmentMapImages: [
      "images/environment-maps/2/px.jpg",
      "images/environment-maps/2/nx.jpg",
      "images/environment-maps/2/py.jpg",
      "images/environment-maps/2/ny.jpg",
      "images/environment-maps/2/pz.jpg",
      "images/environment-maps/2/nz.jpg",
    ],
  },
  {
    label: "Indoor",
    value: "indoor",
    iconImagePath: "images/icons/sections/lighting/indoor.png",
    environmentMapImages: [
      "images/environment-maps/3/px.jpg",
      "images/environment-maps/3/nx.jpg",
      "images/environment-maps/3/py.jpg",
      "images/environment-maps/3/ny.jpg",
      "images/environment-maps/3/pz.jpg",
      "images/environment-maps/3/nz.jpg",
    ],
  },
  {
    label: "Night Mode",
    value: "night-mode",
    iconImagePath: "images/icons/sections/lighting/night-mode.png",
    environmentMapImages: [
      "images/environment-maps/1/px.jpg",
      "images/environment-maps/1/nx.jpg",
      "images/environment-maps/1/py.jpg",
      "images/environment-maps/1/ny.jpg",
      "images/environment-maps/1/pz.jpg",
      "images/environment-maps/1/nz.jpg",
    ],
  },
];

/* 
Default objects available in the scene editor
*/
export const sceneObjectCollections = [
  {
    title: "Food",
    items: [
      {
        title: "Cake",
        slug: "cake",
        iconImagePath: "images/icons/scene-editor-objects/food/cake.svg",
        modelFilePath: "models/editor/cake_slice/scene.gltf",
      },
      {
        title: "Pumpkin",
        slug: "pumpkin",
        iconImagePath: "images/icons/scene-editor-objects/food/pumpkin.svg",
        modelFilePath: "models/editor/pumpkin/scene.gltf",
      },
      {
        title: "Carrot",
        slug: "Carrot",
        iconImagePath: "images/icons/scene-editor-objects/food/carrot.svg",
        modelFilePath: "models/editor/carrot.glb",
      },
      {
        title: "Letter",
        slug: "letter",
        iconImagePath: "images/icons/scene-editor-objects/food/bread.svg",
        modelFilePath: "models/editor/cela_bread.glb",
      },
      {
        title: "Hamburger",
        slug: "hamburger",
        iconImagePath: "images/icons/scene-editor-objects/food/burger.svg",
        modelFilePath: "models/editor/cela_hamburger.glb",
      },
      {
        title: "Sandwich",
        slug: "sandwich",
        iconImagePath: "images/icons/scene-editor-objects/food/sandwich.svg",
        modelFilePath: "models/editor/cela_sandwich.glb",
      },
    ],
  },
  {
    title: "Space",
    items: [
      {
        title: "Earth",
        slug: "earth",
        iconImagePath: "images/icons/scene-editor-objects/space/earth.svg",
        modelFilePath: "models/editor/earth/scene.gltf",
      },
      {
        title: "Darth Vader",
        slug: "darth-vader",
        iconImagePath: "images/icons/scene-editor-objects/space/2.svg",
        modelFilePath: "models/editor/darth_vader_2_7/scene.gltf",
      },
      {
        title: "Death Star Ball",
        slug: "death-star-ball",
        iconImagePath: "images/icons/scene-editor-objects/space/3.svg",
        modelFilePath: "models/editor/robot_copernicus/scene.gltf",
      },
      {
        title: "Saturn",
        slug: "saturn",
        iconImagePath: "images/icons/scene-editor-objects/space/4.svg",
        modelFilePath: "models/editor/saturn/scene.gltf",
      },
      {
        title: "Rocket",
        slug: "rocket",
        iconImagePath: "images/icons/scene-editor-objects/space/5.svg",
        modelFilePath: "models/editor/rocket/scene.gltf",
      },
      {
        title: "Moon",
        slug: "moon",
        iconImagePath: "images/icons/scene-editor-objects/space/6.svg",
        modelFilePath: "models/editor/moon/scene.gltf",
      },
    ],
  },
  {
    title: "Cela Christmas",
    items: [
      {
        title: "Tree",
        slug: "tree",
        iconImagePath:
          "images/icons/scene-editor-objects/cela-christmas/tree.svg",
        modelFilePath: "models/editor/Cela-Christmas/xmasTree_centered.glb",
      },
      {
        title: "gift1",
        slug: "gift1",
        iconImagePath:
          "images/icons/scene-editor-objects/cela-christmas/present.svg",
        modelFilePath: "models/editor/Cela-Christmas/gift1_centered.glb",
      },
      {
        title: "gift2",
        slug: "gift2",
        iconImagePath:
          "images/icons/scene-editor-objects/cela-christmas/present.svg",
        modelFilePath: "models/editor/Cela-Christmas/gift2_centered.glb",
      },
      {
        title: "gift3",
        slug: "gift3",
        iconImagePath:
          "images/icons/scene-editor-objects/cela-christmas/present.svg",
        modelFilePath: "models/editor/Cela-Christmas/gift3_centered.glb",
      },
      {
        title: "gift4",
        slug: "gift4",
        iconImagePath:
          "images/icons/scene-editor-objects/cela-christmas/present.svg",
        modelFilePath: "models/editor/Cela-Christmas/gift4_centered.glb",
      },
      {
        title: "gift5",
        slug: "gift5",
        iconImagePath:
          "images/icons/scene-editor-objects/cela-christmas/present.svg",
        modelFilePath: "models/editor/Cela-Christmas/gift5_centered.glb",
      },
    ],
  },
  // {
  //   title: 'Cela Christmas V2',
  //   items: [
  //     {
  //       title: 'Red Circle Giftbox',
  //       slug: 'red circle giftbox',
  //       iconImagePath: 'images/icons/scene-editor-objects/cela-christmas/snowman.svg',
  //       modelFilePath: 'models/editor/Cela-Christmas/red_circle_giftbox.glb',
  //     },
  //     {
  //       title: 'Red Long Rectangle Giftbox',
  //       slug: "red long rectangle giftbox",
  //       iconImagePath: 'images/icons/scene-editor-objects/cela-christmas/candles.svg',
  //       modelFilePath: 'models/editor/Cela-Christmas/red_longRectangle_topLid_giftbox.glb',
  //     },
  //     {
  //       title: 'Red Square Giftbox',
  //       slug: 'red square giftbox',
  //       iconImagePath: 'images/icons/scene-editor-objects/cela-christmas/decoration.svg',
  //       modelFilePath: 'models/editor/Cela-Christmas/red_square_giftbox.glb',
  //     },
  //     {
  //       title: 'Red Thin Rectangle Giftbox',
  //       slug: 'red thin rectangle giftbox',
  //       iconImagePath: 'images/icons/scene-editor-objects/cela-christmas/hat.svg',
  //       modelFilePath: 'models/editor/Cela-Christmas/red_thinRectangle_sideLid_giftbox.glb',
  //     },
  //     {
  //       title: 'Tree',
  //       slug: 'tree',
  //       iconImagePath: 'images/icons/scene-editor-objects/cela-christmas/present.svg',
  //       modelFilePath: 'models/editor/Cela-Christmas/christmas_tree_W_ornaments.glb',
  //     },
  //     {
  //       title: 'Red Long Rectangle Giftbox',
  //       slug: 'red long rectangle giftbox',
  //       iconImagePath: 'images/icons/scene-editor-objects/cela-christmas/tree.svg',
  //       modelFilePath: 'models/editor/Cela-Christmas/red_longRectangle_sideLid_giftbox.glb',
  //     },
  //   ]
  // },
  // {
  // title: 'Halloween',
  // items: [
  //   {
  //     title: 'Bat',
  //     slug: 'bat',
  //     iconImagePath: 'images/icons/scene-editor-objects/halloween/1.svg',
  //     modelFilePath: 'models/editor/pumpkin/scene.gltf',
  //   },
  //   {
  //     title: 'Clown',
  //     slug: 'clown',
  //     iconImagePath: 'images/icons/scene-editor-objects/halloween/2.svg',
  //     modelFilePath: 'models/editor/gift/scene.gltf',
  //   },
  //   {
  //     title: 'Hand',
  //     slug: 'hand',
  //     iconImagePath: 'images/icons/scene-editor-objects/halloween/3.svg',
  //     modelFilePath: 'models/editor/pumpkin/scene.gltf',
  //   },
  //   {
  //     title: 'Skull',
  //     slug: 'skull',
  //     iconImagePath: 'images/icons/scene-editor-objects/halloween/4.svg',
  //     modelFilePath: 'models/editor/skull/scene.gltf',
  //   },
  //   {
  //     title: 'Black Witch Hat',
  //     slug: 'black-witch-hat',
  //     iconImagePath: 'images/icons/scene-editor-objects/halloween/5.svg',
  //     modelFilePath: 'models/editor/pumpkin/scene.gltf',
  //   },
  //   {
  //     title: 'Purple Witch Hat',
  //     slug: 'purple-witch-hat',
  //     iconImagePath: 'images/icons/scene-editor-objects/halloween/6.svg',
  //     modelFilePath: 'models/editor/gift/scene.gltf',
  //   },
  // ]
  // },
];

const initialCameraDistance = 5;
export const orbitBallCameraViews = {
  frontView: {
    position: {
      x: 0,
      y: 0,
      z: initialCameraDistance,
    },
  },
  backView: {
    position: {
      x: 0,
      y: 0,
      z: -initialCameraDistance,
    },
  },
  rightView: {
    position: {
      x: initialCameraDistance,
      y: 0,
      z: 0,
    },
  },
  leftView: {
    position: {
      x: -initialCameraDistance,
      y: 0,
      z: 0,
    },
  },
  bottomView: {
    position: {
      x: 0,
      y: -initialCameraDistance,
      z: 0,
    },
  },
  topView: {
    position: {
      x: 0,
      y: initialCameraDistance,
      z: 0,
    },
  },
};

/*
  Used for setting the background to a custom background 
*/
export const backgroundDisplayTypes = [
  {
    title: "Solid",
  },
  {
    title: "Linear",
  },
  {
    title: "Radial",
  },
];

// const isDevMode = true;
const isDevMode = false;

const modelFilePath = "models/ShoeG400.glb";
/* 
  Default Annotations
*/
const modelHotspots = [
  {
    id: 0,
    location: {
      x: -0.37329804293071145,
      y: -0.11321197608596223,
      z: 0.299172428958131,
    },
    facingVector: {
      x: -0.6734355562435402,
      y: -0.0026210835605496685,
      z: 0.7392412877457006,
    },
    visible: true,
    label: "Hotspot 1",
    color: "#072b62",
    stroke: "",
    icon: "",
    runTime: "",
    typesOfAnimation: "",
    bodyText: "",
    media: "",
  },
  {
    id: 1,
    location: {
      x: 0.13261021950674487,
      y: 0.2715430177786954,
      z: -0.020751549056905273,
    },
    facingVector: {
      x: 0.527840940569518,
      y: 0.6669619741386335,
      z: 0.525876094257748,
    },
    visible: true,
    // "label": "Hotspot 2",
    label: "Hotspot 2",
    color: "#072b62",
    stroke: "",
    icon: "",
    runTime: "",
    typesOfAnimation: "",
    // "bodyText": "",
    bodyText:
      "In finibus sapien nunc, ac egestas ex molestie eget. Phasellus fringilla odio sit amet lacus ultricies, ac viverra mi condimentum.",
    media: "/images/temp/an-media-1.jpg",
  },
  {
    id: 2,
    location: {
      x: 0.3404503039880408,
      y: -0.2484711945547414,
      z: -0.19858447629338996,
    },
    facingVector: {
      x: 0.9887231984978028,
      y: -0.1336605470877658,
      z: 0.0675373593241058,
    },
    visible: true,
    label: "Hotspot 3",
    color: "#072b62",
    stroke: "",
    icon: "",
    runTime: "",
    typesOfAnimation: "",
    bodyText: "",
    media: "",
  },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // params: {
  //   isDevMode: isDevMode,
  //   modelFilePath: modelFilePath,
  //   sceneBackgroundColor: '#c9c9c9',
  //   shouldRotateSceneOnLaunch: isDevMode ? false : true,
  //   shouldRotateSceneOnLaunch: false,
  //   // initialSceneTurnTableRotationAngle: 135, // @note: value in degrees (alt values: 90).
  //   // initialSceneTurnTableRotationAngle: 0, // @note: value in degrees (alt values: 90).
  //   initialSceneTurnTableRotationAngle: 180, // @note: value in degrees (alt values: 90).
  //   sceneRotationResumeDelayDuration: isDevMode ? 100000 : 2500,
  //   sceneRotationResumeDelayDuration: 100000,
  // },

  // @todo: rename defaults to stateDefaults maybe?
  defaults: {
    // General Panel
    shouldEnableInteractionPrompt: true,
    shouldAutoRotateModel: false,
    // shouldAutoRotateModel: true,
    modelRotationSpeed: 10, // @note: in degrees per second.
    productShadowIntensity: 50, // @note: 0 to 100.
    productShadowBlur: 25, // @note: 0 to 100.
    canvasSize: { x: "Auto", y: "Auto" },
    // canvasSize: { x: 800, y: 640 },
    // canvasSize: { x: 0, y: 0 }, // @note: 0 is auto.

    // Scene Panel
    logoImageFile: null,
    musicFileList: [],
    selectedScene: "Cela Black",
    // selectedScene: 'Cela Christmas',
    selectedSceneTheme: "Food",
    // @note: Add selectedSceneTheme for selectedDragAndDropObject and save its id
    sceneTitles: ["Cela Black", "Cela White", "Cela Christmas"],
    selectedSceneObject: null,
    /* 
      Scenes that are available in the 'Scene' edit window 
      The sceneObjects shows the outline of what each object should look like
    */
    scenes: {
      "Cela Black": {
        title: "Cela White",
        sceneObjects: [
          // {
          //   "id": 99,
          //   "visible": true,
          //   "locked": true,
          //   "label": "Product",
          //   "mesh": "threejs mesh",
          //   "modelScene": threejs scene,
          //   "modelFilePath: zyz/zyz/zyz/skull.jpeg"
          // }, // @todo: temporarily here.
        ],
        preLoadedObjects: [],
        backgroundDisplayColor: "#DADEFF",
        backgroundDisplayType: "Solid",
        backgroundImageFileInput: null,
        buttonOutlineColor: "#D7D7D7",
      },
      "Cela White": {
        title: "Cela White",
        /* 
          When drag and drop objects are added to the scene, they are stored here
        */
        sceneObjects: [],
        preLoadedObjects: [],
        backgroundDisplayColor: "#DADEFF",
        backgroundDisplayType: "Solid",
        backgroundImageFileInput: null,
        buttonOutlineColor: "#ffffff",
      },
      "Cela Christmas": {
        title: "Cela Christmas",
        sceneObjects: [],
        preLoadedObjects: [
          {
            iconImagePath: null,
            modelFilePath: "models/editor/Cela-Christmas/xmasTree_centered.glb",
            slug: "tree",
            title: "Tree",
            position: {
              x: 0.2,
              y: -0.75,
              z: -1.25,
            },
            scale: {
              x: 1,
              y: 1,
              z: 1,
            },
            isVisible: true,
            isLocked: false,
            isLoaded: false,
            index: 0,
          },
          {
            iconImagePath: null,
            modelFilePath: "models/editor/Cela-Christmas/gift1_centered.glb",
            slug: "gift1",
            title: "gift1",
            position: {
              x: 0,
              y: -0.75,
              z: 0,
            },
            scale: {
              x: 2,
              y: 2,
              z: 2,
            },
            isVisible: true,
            isLocked: false,
            isLoaded: false,
            index: 1,
          },
          {
            iconImagePath: null,
            modelFilePath: "models/editor/Cela-Christmas/gift2_centered.glb",
            slug: "gift2",
            title: "gift2",
            position: {
              x: -0.45,
              y: -0.75,
              z: 0.75,
            },
            isVisible: true,
            isLocked: false,
            isLoaded: false,
            index: 2,
          },
          {
            iconImagePath: null,
            modelFilePath: "models/editor/Cela-Christmas/gift2_centered.glb",
            slug: "gift2",
            title: "gift2",
            position: {
              x: 0.55,
              y: -0.75,
              z: 0.85,
            },
            scale: {
              x: 0.8,
              y: 0.8,
              z: 0.8,
            },
            isVisible: true,
            isLocked: false,
            isLoaded: false,
            index: 2,
          },
          {
            iconImagePath: null,
            modelFilePath: "models/editor/Cela-Christmas/gift3_centered.glb",
            slug: "gift3",
            title: "gift3",
            position: {
              x: 0.65,
              y: -0.75,
              z: 0.5,
            },
            scale: {
              x: 1.5,
              y: 2.5,
              z: 1.5,
            },
            isVisible: true,
            isLocked: false,
            isLoaded: false,
            index: 3,
          },
          {
            iconImagePath: null,
            modelFilePath: "models/editor/Cela-Christmas/gift3_centered.glb",
            slug: "gift3",
            title: "gift3",
            position: {
              x: -0.5,
              y: -0.75,
              z: -0.75,
            },
            scale: {
              x: 1.5,
              y: 5.5,
              z: 1.5,
            },
            isVisible: true,
            isLocked: false,
            isLoaded: false,
            index: 3,
          },
          {
            iconImagePath: null,
            modelFilePath: "models/editor/Cela-Christmas/gift4_centered.glb",
            slug: "gift4",
            title: "gift4",
            position: {
              x: -0.95,
              y: -0.75,
              z: 0,
            },
            scale: {
              x: 3,
              y: 4,
              z: 4,
            },
            isVisible: true,
            isLocked: false,
            isLoaded: false,
            index: 4,
          },
          {
            iconImagePath: null,
            modelFilePath: "models/editor/Cela-Christmas/gift4_centered.glb",
            slug: "gift4",
            title: "gift4",
            position: {
              x: 0.15,
              y: -0.75,
              z: 0.75,
            },
            scale: {
              x: 2,
              y: 1,
              z: 1,
            },
            isVisible: true,
            isLocked: false,
            isLoaded: false,
            index: 4,
          },
          {
            iconImagePath: null,
            modelFilePath: "models/editor/Cela-Christmas/gift5_centered.glb",
            slug: "gift5",
            title: "gift5",
            position: {
              x: 0.75,
              y: -0.75,
              z: 0,
            },
            scale: {
              x: 1.5,
              y: 2.5,
              z: 1.5,
            },
            isVisible: true,
            isLocked: false,
            isLoaded: false,
            index: 5,
          },
        ],
        backgroundDisplayColor: "#DADEFF",
        backgroundDisplayType: "Solid",
        backgroundImageFileInput: null,
        buttonOutlineColor: "#ffffff",
      },
    },
    currentlySelectedSceneBackgroundDisplayColor: "#DADEFF",
    currentlySelectedSceneBackgroundDisplayType: "Linear",
    currentlySelectedSceneBackgroundDisplayPosition: { height: 0, width: 0 },
    maxSceneObjectCount: 60,
    iconFileInput: null,
    mediaFileInput: null,
    viewsTitles: ["Default View"],
    selectedView: "Default View",
    views: {
      "Default View": {
        id: 0,
        title: "Default View",
        dataURL: "",
        cameraPosition: { x: 0, y: 0.5, z: 8 },
      },
    },

    // Colorway Panel
    selectedSwatch: "swatch1",
    colorSwatchesTitles: ["swatch1", "swatch2", "swatch3", "Add another"],
    colorSwatches: {
      swatch1: {
        title: "swatch1",
        color: "Dark Brown",
        hex: "#874A1C",
        locked: false,
        imageFileInput: null,
        isColorMostRecentlySelected: true,
      },
      swatch2: {
        title: "swatch2",
        color: "Dark Blue",
        hex: "#00008B",
        locked: false,
        imageFileInput: null,
        isColorMostRecentlySelected: true,
      },
      swatch3: {
        title: "swatch3",
        color: "Dark Red",
        hex: "#8B0000",
        locked: false,
        imageFileInput: null,
        isColorMostRecentlySelected: true,
      },
      "Add another": {
        title: "Add another",
        color: "Light Green",
        hex: "#90EE90",
        locked: true,
        imageFileInput: null,
        isColorMostRecentlySelected: true,
      },
    },
    selectedComponent: "component1",
    colorComponentTitles: ["component1", "component2", "Add another"],
    colorComponents: {
      component1: {
        title: "component1",
        color: "Mint Green",
        hex: "#90EE90",
        locked: false,
        imageFileInput: null,
        currentlySelectedSwatch: "swatch1",
        swatchTitles: ["swatch1", "swatch2"],
        isColorMostRecentlySelected: true,
        swatches: {
          swatch1: {
            title: "swatch1",
            color: "Light Green",
            hex: "#90EE90",
            locked: false,
            imageFileInput: null,
            isColorMostRecentlySelected: true,
          },
          swatch2: {
            title: "swatch2",
            color: "Blue",
            hex: "#00008B",
            locked: false,
            imageFileInput: null,
            isColorMostRecentlySelected: true,
          },
        },
      },
      component2: {
        title: "component2",
        hex: "#874A1C",
        color: "Coco Brown",
        locked: false,
        imageFileInput: null,
        currentlySelectedSwatch: "swatch1",
        swatchTitles: ["swatch1", "swatch2"],
        isColorMostRecentlySelected: true,
        swatches: {
          swatch1: {
            title: "swatch1",
            color: "Dark Brown",
            hex: "#874A1C",
            locked: false,
            imageFileInput: null,
            isColorMostRecentlySelected: true,
          },
          swatch2: {
            title: "swatch2",
            color: "Navy Blue",
            hex: "#00008B",
            locked: false,
            imageFileInput: null,
            isColorMostRecentlySelected: true,
          },
        },
      },
      component3: {
        title: "component3",
        hex: "#612B9C",
        color: "Grape Purple",
        locked: false,
        imageFileInput: null,
        currentlySelectedSwatch: "swatch1",
        swatchTitles: ["swatch1", "swatch2"],
        isColorMostRecentlySelected: true,
        swatches: {
          swatch1: {
            title: "swatch1",
            color: "Grape Purple",
            hex: "#612B9C",
            locked: false,
            imageFileInput: null,
            isColorMostRecentlySelected: true,
          },
          swatch2: {
            title: "swatch2",
            color: "Sky Blue",
            hex: "#94DDEC",
            locked: false,
            imageFileInput: null,
            isColorMostRecentlySelected: true,
          },
        },
      },
      "Add another": {
        title: "Add another",
        hex: "",
        locked: true,
        imageFileInput: null,
        currentlySelectedSwatch: "swatch1",
        swatchTitles: ["swatch1", "swatch2"],
        isColorMostRecentlySelected: true,
        swatches: {
          swatch1: {
            title: "swatch1",
            color: "Dark Brown",
            hex: "#874A1C",
            locked: false,
            imageFileInput: null,
            isColorMostRecentlySelected: true,
          },
          swatch2: {
            title: "swatch2",
            color: "Navy Blue",
            hex: "#00008B",
            locked: false,
            imageFileInput: null,
            isColorMostRecentlySelected: true,
          },
        },
      },
    },
    colorComponents: {
      component1: {
        title: "component1",
        color: "Mint Green",
        hex: "#90EE90",
        locked: false,
        imageFileInput: null,
        currentlySelectedSwatch: "swatch1",
        swatchTitles: ["swatch1", "swatch2"],
        isColorMostRecentlySelected: true,
        swatches: {
          swatch1: {
            title: "swatch1",
            color: "Light Green",
            hex: "#90EE90",
            locked: false,
            imageFileInput: null,
            isColorMostRecentlySelected: true,
          },
          swatch2: {
            title: "swatch2",
            color: "Blue",
            hex: "#00008B",
            locked: false,
            imageFileInput: null,
            isColorMostRecentlySelected: true,
          },
        },
      },
      component2: {
        title: "component2",
        hex: "#874A1C",
        color: "Coco Brown",
        locked: false,
        imageFileInput: null,
        currentlySelectedSwatch: "swatch1",
        swatchTitles: ["swatch1", "swatch2"],
        isColorMostRecentlySelected: true,
        swatches: {
          swatch1: {
            title: "swatch1",
            color: "Dark Brown",
            hex: "#874A1C",
            locked: false,
            imageFileInput: null,
            isColorMostRecentlySelected: true,
          },
          swatch2: {
            title: "swatch2",
            color: "Navy Blue",
            hex: "#00008B",
            locked: false,
            imageFileInput: null,
            isColorMostRecentlySelected: true,
          },
        },
      },
      component3: {
        title: "component3",
        hex: "#612B9C",
        color: "Grape Purple",
        locked: false,
        imageFileInput: null,
        currentlySelectedSwatch: "swatch1",
        swatchTitles: ["swatch1", "swatch2"],
        isColorMostRecentlySelected: true,
        swatches: {
          swatch1: {
            title: "swatch1",
            color: "Grape Purple",
            hex: "#612B9C",
            locked: false,
            imageFileInput: null,
            isColorMostRecentlySelected: true,
          },
          swatch2: {
            title: "swatch2",
            color: "Sky Blue",
            hex: "#94DDEC",
            locked: false,
            imageFileInput: null,
            isColorMostRecentlySelected: true,
          },
        },
      },
      "Add another": {
        title: "Add another",
        hex: "",
        locked: true,
        imageFileInput: null,
        currentlySelectedSwatch: "swatch1",
        swatchTitles: ["swatch1", "swatch2"],
        isColorMostRecentlySelected: true,
        swatches: {
          swatch1: {
            title: "swatch1",
            color: "Dark Brown",
            hex: "#874A1C",
            locked: false,
            imageFileInput: null,
            isColorMostRecentlySelected: true,
          },
          swatch2: {
            title: "swatch2",
            color: "Navy Blue",
            hex: "#00008B",
            locked: false,
            imageFileInput: null,
            isColorMostRecentlySelected: true,
          },
        },
      },
    },
    // Lighting Panel
    shouldEnableLightingFeature: true,
    selectedLightingOptions: ["sunny", "cloudy", "indoor"],
    selectedLightingOption: lightingOptions[0],
    lightRotationAngle: 0,
    lightBrightness: 50,

    // Augmented Reality Experiences Panel
    isVerticalARModeEnabled: false,
    isHorizontalARModeEnabled: false,
    isTryOnARModeEnabled: false,
    isSnapchatARModeEnabled: false,
    isImageARMarkerARModeEnabled: false,

    // Product Redirect Panel
    productCheckoutURL: "https://example.org",
    briefProductDescription:
      "<p>Train hard and find your rhythm on the road. From the moment you lace up, the lacing system wraps around your foot for a secure feel from start to finish.</p><ul><li>Colour Shown: Volt</li><li>Style: CT2392-700</li></ul>",

    // Annotations Panel
    shouldShowAnnotations: false,
    shouldEnableAnnotationsFeature: true,
    isEditingAnnotations: false,
    isInAddHotspotMode: false,
    maxHotspotCount: 5,
    hotspots: modelHotspots,
    selectedHotspotID: null,
    hotspotPositionByIDs: [],
    hotspotOpacityByIDs: [],
    hotspotIconImageFile: null,

    // Measurement/Compare Panel
    measurementUnit: "Metric (cm)",
    enableMeasurement: false,
    showMeasurements: false,
    objectMeasurements: {
      // User provided measurements
      length: 30,
      width: 12,
      height: 16,
      unit: "cm",
      // Cela provided measurements - These never change
      defaultMeasurement: {
        length: 30,
        width: 12,
        height: 16,
      },
      measurements: {
        length: 30,
        width: 12,
        height: 16,
      },
      originalScale: { x: 1, y: 1, z: 1 },
    },
    // Size/ x = 1meter
    comparisonObjects: [
      {
        name: "None",
        enabled: true,
      },
      {
        modelFilePath: "models/editor/mannequin.glb",
        iconPath: "images/icons/customer-panel/hold_human.svg",
        name: "Mannequin",
        enabled: false,
        size: { x: 1.8, y: 1.8, z: 1.8 },
      },
      {
        modelFilePath: "models/editor/table.glb",
        iconPath: "images/icons/customer-panel/top_table.svg",
        name: "Table",
        enabled: false,
        size: { x: 1, y: 0.74, z: 0.8 },
      },
    ],

    // Water mark
    watermarkEnabled: false,
    hotspotTempValueFinal: "#2D33AD",
  },

  // ..
  lightingOptions,
  sceneObjectCollections,
  orbitBallCameraViews,
  backgroundDisplayTypes,
};

export const primaryMenuItems = [
  {
    title: "General",
    slug: "general",
    iconImageSource: "/images/icons/client-panel/General-Black.svg",
  },
  {
    title: "Scene Settings",
    slug: "scene",
    iconImageSource: "/images/icons/client-panel/SceneSetting-Black.svg",
  },
  {
    title: "Customization",
    slug: "customization",
    iconImageSource: "/images/icons/client-panel/Colourway-Black.svg",
  },
  {
    title: "Lighting",
    slug: "lighting",
    iconImageSource: "/images/icons/client-panel/Lighting-Black.svg",
  },
  {
    title: "Augmented Reality Experiences",
    slug: "augmented-reality-experiences",
    iconImageSource: "/images/icons/client-panel/ARExperience-Black.svg",
  },
  {
    title: "Product Redirect",
    slug: "product-redirect",
    iconImageSource: "/images/icons/client-panel/ProductRedirect-Black.svg",
  },
  {
    title: "Annotations",
    slug: "annotations",
    iconImageSource: "/images/icons/client-panel/Annotation-Black.svg",
  },
  {
    title: "Measurement & Comparison",
    slug: "measurement-and-comparison",
    iconImageSource:
      "/images/icons/client-panel/Measurement_Comparison-Black.svg",
  },
];

export const desktopHelpImages = [
  {
    title: "Rotate",
    message: "Left click and drag",
    iconUrlPath: "images/icons/help-menu/mouse_leftClick_rotate.svg",
  },
  {
    title: "Anchor Rotate Point",
    message: "Left click twice",
    iconUrlPath: "images/icons/help-menu/mouse_double_click_reset.svg",
  },
  {
    title: "Zoom",
    message: "Scroll on mouse or pinch two fingers",
    iconUrlPath: "images/icons/help-menu/mouse_scroll_zoom.svg",
  },
  {
    title: "Move",
    message: "Right click and drag on mouse or two finger drag",
    iconUrlPath: "images/icons/help-menu/mouse_rightClick_move.svg",
  },
  {
    title: "Rotate Light",
    message: "Press shift then left click and drag",
    iconUrlPath:
      "images/icons/help-menu/mouse_shift_rightClick_movesLightingPosition.svg",
  },
];

export const mobileHelpImages = [
  {
    title: "Rotate",
    message: "One finger drag",
    iconUrlPath: "images/icons/help-menu/hand_oneFinger_rotate.svg",
  },
  {
    title: "Zoom",
    message: "Pinch two fingers",
    iconUrlPath: "images/icons/help-menu/hand_rotate.svg",
  },
  {
    title: "Move",
    message: "Two finger drag",
    iconUrlPath: "images/icons/help-menu/hand_twoFinger_move.svg",
  },
];

export const customerOptionItems = [
  {
    title: "Customization",
    slug: "select-colors",
    iconImagePath: "images/icons/customer-panel/colour-icon.svg",
  },
  {
    title: "Lighting Controls",
    slug: "lighting-controls",
    iconImagePath: "images/icons/customer-panel/light-icon.svg",
  },
  {
    title: "Augmented Reality Experience",
    slug: "experience-in-augmented-reality",
    iconImagePath: "images/icons/customer-panel/ar-icon.svg",
  },
  {
    title: "Redirect To",
    slug: "buy-product",
    iconImagePath: "images/icons/customer-panel/buy-link-icon.svg",
  },
  {
    title: "Annotations",
    slug: "annotations",
    iconImagePath:
      "images/icons/customer-panel/Button_Annotation_Clear_White.svg", // @todo: use correct icon.
  },
  {
    title: "Gesture Instructions",
    slug: "help",
    iconImagePath: "images/icons/customer-panel/help-icon.svg",
  },
  {
    title: "Fullscreen",
    slug: "fullscreen",
    iconImagePath: "images/icons/customer-panel/fullscreen-icon.svg",
    iconImagePathClose: "images/icons/customer-panel/close-fullscreen-icon.svg",
  },
  {
    title: "Measurement and Comparison",
    slug: "measurement-comparison",
    iconImagePath: "images/icons/customer-panel/ruler_white.svg",
  },
  {
    slug: "cela-logo",
    iconImagePath: "images/icons/customer-panel/cela_watermark.svg",
  },
];
