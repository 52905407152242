import { useState, useContext } from "react";
import styled from "styled-components";
import { CircleSlider } from "react-circle-slider";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import { AppContext } from "../../../App";
import { lightingOptions } from "../../../config";
import SliderWithInputField from "../../ui/SliderWithInputField";

const SingleSelectInputContainer = styled.div`
  padding: 0 32px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1023px) {
    justify-content: center;
  }
  @media (max-width: 639px) {
    padding: 0;
    margin-left: 11.43px;
    /* @todo: remove this fix later if better alternative - here for 'Night Mode' */
  }
`;

const SingleSelectOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  cursor: pointer;
  & > div {
    width: 56px;
    min-height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 56px;
    border: 1px solid #666;
    @media (max-width: 639px) {
      width: 48px;
      min-height: 48px;
      border-radius: 48px;
    }
  }
  & > div > img {
    width: 40px;
  }
  & > p {
    color: #444;
    font-size: 14px;
    margin: 8px 0 16px;
    @media (max-width: 639px) {
      /* .. */
    }
  }
  ${({ isSelected }) =>
    isSelected &&
    `
    & > div {
      background-color: rgba(0, 0, 0, 0.1);
    }
  `}
  ${({ shouldShowHoverState }) =>
    shouldShowHoverState &&
    `
    & > div {
      background-color: rgba(0, 0, 0, 0.05);
    }
  `}
  &:active {
    & > div {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  /* @todo: apply to icon. border shadow etc */
  @media (max-width: 1023px) {
    width: initial;
    padding: 0 12px;
  }
  @media (max-width: 639px) {
    padding: 0 8px;
  }
  &:last-child {
    @media (max-width: 639px) {
      margin-left: -11.43px;
    }
  }
`;

const SingleSelectOption = ({ option, onSelect, isSelected }) => {
  const [shouldShowHoverState, setShouldShowHoverState] = useState(false);
  return (
    <SingleSelectOptionContainer
      onMouseEnter={() => setShouldShowHoverState(true)}
      onMouseLeave={() => setShouldShowHoverState(false)}
      onClick={() => {
        setShouldShowHoverState(false);
        onSelect(option);
      }}
      isSelected={isSelected}
      shouldShowHoverState={shouldShowHoverState}
    >
      <div>
        <img src={option.iconImagePath} alt={option.label} />
      </div>
      <p>{option.label}</p>
    </SingleSelectOptionContainer>
  );
};

const SingleSelectInput = ({ options, selectedOption, onChange }) => {
  const handleSelectOption = (option) => {
    onChange(option);
  };
  return (
    <SingleSelectInputContainer>
      {options.map((option, optionIndex) => (
        <SingleSelectOption
          key={optionIndex}
          option={option}
          onSelect={handleSelectOption}
          isSelected={selectedOption && selectedOption.value === option.value}
        />
      ))}
    </SingleSelectInputContainer>
  );
};

const CircularSliderInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > svg {
    height: 160px;
  }
  & > svg > g > circle:nth-child(3) {
    stroke: #000;
    stroke-width: 1px;
  }
  @media (max-width: 1023px) {
    display: none;
  }
`;

const CircularSliderInput = ({ value, onChange }) => {
  return (
    <CircularSliderInputContainer>
      <CircleSlider
        value={value}
        onChange={onChange}
        max={360}
        size={144}
        circleWidth={1}
        progressWidth={1}
        circleColor={"#000000"}
        progressColor={"#000000"}
        knobRadius={12}
        knobColor={"#ffffff"}
        shadow={false}
      />
    </CircularSliderInputContainer>
  );
};

const SliderInputContainer = styled.div`
  width: 160px;
  margin: 0 auto;
  margin: 0 24px;
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
`;

const PanelOptionHeading = styled.div`
  color: #000;
  margin-top: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  @media (max-width: 1023px) {
    display: none;
  }
`;

const RotationAngleInputsWrapperContainer = styled.div`
  @media (max-width: 1023px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const RotationAngleInputsContainer = styled.div`
  @media (max-width: 1023px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    margin: 24px;
  }
`;

const PanelOptionText = styled.div`
  color: #000;
  margin-bottom: -8px;
  margin-top: 8px;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  @media (max-width: 1023px) {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const PanelOptionTextV2 = styled(PanelOptionText)`
  margin: 0;
`;

const LightingControlsPanel = ({}) => {
  const {
    // @todo: might move these into CustomerOptionsContext or such.
    selectedLightingOptions,
    selectedLightingOption,
    setSelectedLightingOption,
    lightRotationAngle,
    setLightRotationAngle,
    lightBrightness,
    setLightBrightness,
    context,
  } = useContext(AppContext);

  return (
    <div>
      <SingleSelectInput
        options={lightingOptions.filter(
          (o) =>
            o.isDefault ||
            selectedLightingOptions.reduce(
              (prev, curr) => prev || curr === o.value,
              false
            )
        )}
        selectedOption={selectedLightingOption}
        onChange={(updatedSelectedOption) => {
          setSelectedLightingOption(updatedSelectedOption);
        }} // @todo: or rename to newValue?
      />
      <PanelOptionHeading>{"Brightness"}</PanelOptionHeading>
      <SliderWithInputField
        lightBrightness={lightBrightness}
        setLightBrightness={setLightBrightness}
        context={context}
      />
      <PanelOptionHeading>Position</PanelOptionHeading>
      <RotationAngleInputsWrapperContainer>
        <RotationAngleInputsContainer>
          <PanelOptionText>Back</PanelOptionText>
          <CircularSliderInput
            value={lightRotationAngle}
            onChange={(changedValue) => setLightRotationAngle(changedValue)}
          />
          <SliderInputContainer>
            <Slider
              value={lightRotationAngle}
              onChange={(changedValue) => setLightRotationAngle(changedValue)}
              max={360}
              railStyle={{
                backgroundColor: "#000",
                height: "1px",
              }}
              trackStyle={{
                backgroundColor: "#000",
                height: "1px",
              }}
              handleStyle={{
                width: "24px",
                height: "24px",
                backgroundColor: "#fff",
                borderColor: "#000",
                borderWidth: "1px",
                boxShadow: "none",
                marginTop: "-11px",
              }}
            />
          </SliderInputContainer>
          <PanelOptionTextV2>Front</PanelOptionTextV2>
        </RotationAngleInputsContainer>
      </RotationAngleInputsWrapperContainer>
    </div>
  );
};

export default LightingControlsPanel;
