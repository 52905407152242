import React from "react";
import styled from "@emotion/styled";

const ArticleContainer = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem 0 2rem;
  text-align: center;
`;

const HelpIcon = styled.img`
  width: auto;
  height: 8rem;
  margin: 0;
`;

const Title = styled.h4`
  color: #000;
  margin: 0;
`;

const Message = styled.p`
  color: #595959;
  margin: 0;
  font-size: 0.75em;
`;

const HelpPanelItem = ({ title, message, iconUrlPath }) => {
  return (
    <ArticleContainer>
      <HelpIcon src={iconUrlPath} alt={title} />
      <Title>{title}</Title>
      <Message>{message}</Message>
    </ArticleContainer>
  );
};

export default HelpPanelItem;
