import { useState, useRef } from "react";
import styled from "@emotion/styled";
import * as THREE from "three";
import config from "../../config";
import useGetModalPosition from "../../custom-hooks/useGetModalPosition";
import ImageFileInputV2 from "../client/inputs/ImageFileInputV2";
import RichTextInput from "../client/inputs/RichTextInput";
import { FloatingModalContainerV7 } from "../client/modals/DoneEditingWarning";
import { ModalContainer } from "../client/modals/shared";
import ColorPicker from "../client/modals/ColorPicker";

const HotspotOverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: auto;
  opacity: ${({ hotspotOpacity }) => (hotspotOpacity === 1 ? 1 : 0.125)};
`;

const diameter1 = 48;
const diameter2 = 72;

const HotspotOverlayMarker = styled.div`
  ${({ iconFile, color }) =>
    iconFile
      ? `background-image: url(${iconFile.src});`
      : `background-color: ${color};`};
  width: ${diameter1}px;
  height: ${diameter1}px;
  border-radius: ${diameter1 * 0.5}px;
  margin-top: -${diameter1 * 0.5}px;
  margin-left: -${diameter1 * 0.5}px;
  border: 3px solid #fff;
  box-sizing: border-box;
`;

const HotspotOverlayHoverMarker = styled.div`
  width: ${diameter2}px;
  height: ${diameter2}px;
  border-radius: ${diameter2 * 0.5}px;
  margin-top: -${diameter2 * 0.5 + diameter1 * 0.5}px;
  margin-left: -${diameter2 * 0.5}px;
  border: 3px solid #fff;
  box-sizing: border-box;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
  cursor: pointer;
`;

const HotspotOverlayDetailBox = styled.div`
  padding: 32px;
  border-radius: 16px;
  display: ${({ shouldShow }) => (shouldShow ? "block" : "none")};
  position: absolute;
  /* @todo: use diameter2 below */
  margin-top: -72px;
  margin-left: 44px;
  z-index: 10;
  width: 240px;
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(16px);
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #000;
  p {
    margin: 0;
  }
  > div:nth-child(1) {
    margin-bottom: 24px;
    > img {
      max-width: 100%;
    }
  }
  > .label {
    font-weight: bold;
  }
  > .body-text {
    margin-top: 24px;
    line-height: 1.5;
    p + p {
      margin-top: 8px;
    }
  }
`;

const ColorInputCotainer = styled.div`
  width: 36px;
  height: 36px;
  background-color: ${({ color }) => color};
  border-radius: 18px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
`;

const HotspotOverlayDetailEditorBox = styled.div`
  border-radius: 16px;
  display: ${({ shouldShow }) => (shouldShow ? "block" : "none")};
  position: absolute;
  /* @todo: use diameter2 below */
  margin-top: -72px;
  margin-left: 44px;
  z-index: 10;
  width: 360px;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(16px);
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #000;
  /* @todo: avoid duplicate code above. */
  > .header-row {
    padding: 20px 24px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    > .label {
      margin: 0;
      margin-top: -14px;
      color: #072b61;
      font-weight: bold;
      font-size: 14px;
    }
    > .close-icon {
      margin-right: -8px;
      cursor: pointer;
      > svg {
        zoom: 0.75;
      }
    }
  }
  > .inputs-section {
    padding: 16px 24px;
    .inputs-row {
      display: flex;
      margin-bottom: 16px;
      > * + * {
        margin-left: 16px;
      }
    }
    .input-pair {
      flex: 1;
      /* @todo: ^ exception for run time. */
      > .label {
        font-size: 14px;
        color: #333;
        margin: 0;
        margin-bottom: 8px;
      }
      .text-input,
      .select-input {
        border: 0;
        appearance: none;
        margin: 0;
        line-height: 1.5;
        padding: 8px 16px;
        border-radius: 4px;
        width: 100%;
        box-sizing: border-box;
        outline: none;
      }
      .color-input {
        width: 36px;
        height: 36px;
        background-color: ${({ color }) => color};
        border-radius: 18px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
        cursor: pointer;
      }
      .stroke-input {
        width: 36px;
        height: 36px;
        border: 2px solid #fff;
        border-radius: 18px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
        box-sizing: border-box;
        cursor: pointer;
      }
      .icon-input {
        width: 36px;
        height: 36px;
        background-color: #fff;
        border: 2px dashed #666;
        border-radius: 18px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
        box-sizing: border-box;
        cursor: pointer;
      }
      &.text-area-row {
        > * {
          width: 100%;
        }
        > div {
          height: 208px !important;
          > .quill {
            height: 160px !important;
          }
        }
        .ql-toolbar.ql-snow {
          filter: saturate(0);
        }
        .ql-container.ql-snow {
          border: none !important;
        }
        > div > .quill .ql-editor {
          background-color: #fff;
          border-radius: 4px;
        }
      }
    }
    .save-button {
      background-color: #601818;
      color: #fff;
      cursor: pointer;
      padding: 8px;
      text-align: center;
      border-radius: 4px;
    }
  }
`;

const IconInputContainer = styled.div`
  background-image: ${({ iconFile }) => iconFile && `url(${iconFile.src})`};
`;

function truncate(str, n) {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
}

const HotspotOverlay = ({
  hotspot,
  hotspotPosition,
  hotspotOpacity,
  selectedHotspotID,
  setSelectedHotspotID,
  isEditingAnnotations,
  onChangeHotspot,
  context,
}) => {
  const [isHotSpotColorOpen, setIsHotSpotColorOpen] = useState(false);
  const [hotspotTempValue, setHotspotTempValue] = useState(
    config.defaults.hotspotTempValueFinal
  );
  const [hotspotTempValueFinal, setHotspotTempValueFinal] = useState(
    config.defaults.hotspotTempValueFinal
  ); // @todo: rethink.

  const [imageFileInput, setImageFileInput] = useState(
    config.defaults.hotspotIconImageFile
  );
  const [imageFileInputPerm, setImageFileInputPerm] = useState(null);

  const hotspotRef = useRef(null);
  const [modalPosition, getModalPositionHandler] = useGetModalPosition(null);

  // console.log('modalPosition', modalPosition);
  // console.log('hotspotRef', hotspotRef);
  const imageFileRef = useRef(null);

  return (
    <>
      <HotspotOverlayContainer
        hotspotOpacity={hotspotOpacity}
        style={
          hotspotPosition
            ? { top: hotspotPosition.y, left: hotspotPosition.x }
            : {}
        }
      >
        <HotspotOverlayMarker
          ref={hotspotRef}
          color={hotspot.color}
          iconFile={imageFileInputPerm}
        />
        <HotspotOverlayHoverMarker
          onClick={() => {
            if (hotspot.id === selectedHotspotID) {
              setSelectedHotspotID(null);
            } else {
              setSelectedHotspotID(hotspot.id);
              if (context.modelAnimations) {
                console.log(context.modelAnimations);
                const animationIndex = context.modelAnimations.findIndex(
                  (o) => o.name === hotspot.animationType
                );
                if (animationIndex > -1) {
                  const action = context.mixer.clipAction(
                    context.modelAnimations[animationIndex]
                  );
                  action.setLoop(THREE.LoopRepeat);
                  // action.setLoop(THREE.LoopOnce);
                  action.clampWhenFinished = true;
                  action.play();
                  setTimeout(() => {
                    action.stop();
                    setSelectedHotspotID(null);
                  }, 2500);
                }
              }
              getModalPositionHandler(hotspotRef);
            }
          }}
        />
        {isEditingAnnotations ? (
          <HotspotOverlayDetailEditorBox
            shouldShow={selectedHotspotID === hotspot.id}
          >
            <div className={"header-row"}>
              <p className={"label"}>{"Add Hotspot"}</p>
              <div
                className={"close-icon"}
                onClick={() => setSelectedHotspotID(null)}
              >
                <svg
                  width='35'
                  height='36'
                  viewBox='0 0 35 36'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g filter='url(#filter0_d_1147_44080)'>
                    <line
                      x1='8.35355'
                      y1='4.64645'
                      x2='26.3536'
                      y2='22.6464'
                      stroke='#161616'
                    />
                    <line
                      x1='26.3536'
                      y1='5.35355'
                      x2='8.35355'
                      y2='23.3536'
                      stroke='#161616'
                    />
                  </g>
                  <defs>
                    <filter
                      id='filter0_d_1147_44080'
                      x='0'
                      y='0.292969'
                      width='34.7072'
                      height='35.4141'
                      filterUnits='userSpaceOnUse'
                      colorInterpolationFilters='sRGB'
                    >
                      <feFlood floodOpacity='0' result='BackgroundImageFix' />
                      <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                      />
                      <feOffset dy='4' />
                      <feGaussianBlur stdDeviation='4' />
                      <feComposite in2='hardAlpha' operator='out' />
                      <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
                      />
                      <feBlend
                        mode='normal'
                        in2='BackgroundImageFix'
                        result='effect1_dropShadow_1147_44080'
                      />
                      <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='effect1_dropShadow_1147_44080'
                        result='shape'
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            </div>
            <div className={"inputs-section"}>
              <div className={"inputs-row"}>
                <div className={"input-pair"}>
                  <p className={"label"}>{"Label*"}</p>
                  <input
                    className={"text-input"}
                    type='text'
                    value={hotspot.label}
                    onChange={(e) =>
                      onChangeHotspot(hotspot.id, "label", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className={"inputs-row"}>
                <div className={"input-pair"}>
                  <p className={"label"}>{"Color"}</p>
                  <ColorInputCotainer
                    color={hotspot.color}
                    onClick={() => setIsHotSpotColorOpen((prev) => !prev)}
                  ></ColorInputCotainer>
                </div>
                <div className={"input-pair"}>
                  <p className={"label"}>{"Stroke"}</p>
                  <div className={"stroke-input"}></div>
                </div>
                <div className={"input-pair"}>
                  <p className={"label"}>{"Icon"}</p>
                  <IconInputContainer
                    className={"icon-input"}
                    onClick={() => imageFileRef.current.click()}
                    iconFile={imageFileInput}
                  />
                </div>
                <ImageFileInputV2
                  imageFileRef={imageFileRef}
                  onChange={setImageFileInput}
                />
              </div>
              <div className={"inputs-row"}>
                <div className={"input-pair run-time-input"}>
                  <p className={"label"}>{"Run Time"}</p>
                  <input
                    className={"text-input"}
                    type='text'
                    value={hotspot.runTime || "None"}
                    onChange={(e) =>
                      onChangeHotspot(hotspot.id, "runTime", e.target.value)
                    }
                  />
                </div>
                <div className={"input-pair"}>
                  <p className={"label"}>{"Type of Animation"}</p>
                  <select className={"select-input"}>
                    <option value={null}>None</option>
                    {context.modelAnimations &&
                      context.modelAnimations.map((anim, animIndex) => (
                        <option
                          selected={hotspot.animationType === anim.name}
                          value={anim.name}
                          onClick={(e) =>
                            onChangeHotspot(
                              hotspot.id,
                              "animationType",
                              anim.name
                            )
                          }
                        >
                          {truncate(
                            anim.name.slice(14).replace(/_/g, " ").trim(),
                            20
                          )}
                        </option>
                        // @todo: may use uuid later?
                      ))}
                  </select>
                </div>
              </div>
              <div className={"inputs-row"}>
                <div className={"input-pair text-area-row"}>
                  <p className={"label"}>{"Body Text"}</p>
                  <RichTextInput
                    value={hotspot.bodyText}
                    onChange={(v) => onChangeHotspot(hotspot.id, "bodyText", v)}
                  />
                </div>
              </div>
              <div
                className={"save-button"}
                onClick={() => {
                  setSelectedHotspotID(null);
                  setImageFileInputPerm(imageFileInput);
                }}
              >
                {"Save"}
              </div>
            </div>
          </HotspotOverlayDetailEditorBox>
        ) : (
          <HotspotOverlayDetailBox
            shouldShow={selectedHotspotID === hotspot.id}
          >
            {hotspot.media !== "" && (
              <div>
                <img src={hotspot.media} />
              </div>
            )}
            <p className={"label"}>{hotspot.label}</p>
            {hotspot.bodyText !== "" && (
              <div
                className={"body-text"}
                dangerouslySetInnerHTML={{
                  __html: hotspot.bodyText.replace("<p><br></p>", ""),
                }}
              ></div>
            )}
          </HotspotOverlayDetailBox>
        )}
      </HotspotOverlayContainer>
      {isHotSpotColorOpen && (
        <FloatingModalContainerV7 position={modalPosition}>
          <ModalContainer>
            <ColorPicker
              color={hotspotTempValue}
              setColor={setHotspotTempValue}
              onChange={setHotspotTempValueFinal}
              onSave={() => {
                setHotspotTempValueFinal(hotspotTempValue.hex);
                setIsHotSpotColorOpen(false);
              }}
              onCancel={() => console.log("it cancelled")}
              labelText='Hex'
            />
          </ModalContainer>
        </FloatingModalContainerV7>
      )}
    </>
  );
};

export default HotspotOverlay;
