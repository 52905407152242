import styled from 'styled-components';

export const ImageContainer = styled.div`
  align-self: center;
  > img {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
`;

const ImageFileInputV2 = ({ onChange, imageFileRef }) => {

  const onFileUpload = input => {
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = async function (e) {
        await onChange({file: input.files[0], src: e.target.result});
      };
      reader.readAsDataURL(input.files[0]);
    };
  };

  return (
    <input
      type='file'
      accept='.png, .svg'
      ref={imageFileRef}
      onChange={(event) => onFileUpload(event.target)}
      style={{display: 'none'}}
    />
  );
};

export default ImageFileInputV2;
