import { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../App';
import DragAndDropObjectsInput from '../inputs/DragAndDropObjectsInput';
import { PanelOption, PanelOptionHeading, ElementContainer } from './shared';
import { loadSceneObjects } from '../../../threejs/helpers/scene-editor/dragAndDropObjects';
import config from '../../../config';
import ChangeBackgroundDisplayInput from '../inputs/ChangeBackgroundDisplayInput';
import FinishEditingEnviornmentInput from '../inputs/FinishEditingEnviornmentInput';
import styled from 'styled-components';
import ObjectsOnSceneInput from '../inputs/ObjectsOnSceneInput';
import { deleteAllObjectsFromScene, deleteSelectedObjectFromScene, switchTransformControlsToSelectedObject } from '../../../threejs/helpers/scene-editor/dragAndDropObjects';

const SceneEditorPanelContainer = styled.div`
  .set-opacity {
    opacity: ${({ finishEditingEnviornment }) => finishEditingEnviornment ? '0.5' : '1'};
  };
`;

const SceneEditorPanel = () => {
  const {
    maxSceneObjectCount,
    selectedScene,
    scenes, setScenes,
    currentlySelectedSceneBackgroundDisplayColor, setCurrentlySelectedSceneBackgroundDisplayColor,
    currentlySelectedSceneBackgroundDisplayType, setCurrentlySelectedSceneBackgroundDisplayType,
    currentlySelectedSceneBackgroundDisplayPosition, setCurrentlySelectedSceneBackgroundDisplayPosition,
    selectedSceneObject, setSelectedSceneObject,
    selectedSceneTheme, setSelectedSceneTheme,
    context,
  } = useContext(AppContext);
  const [whichModalIsOpen, setWhichModalIsOpen] = useState(null);
  // const [whichModalIsOpen, setWhichModalIsOpen] = useState('ChangeBackgroundDisplayInput');

  // @note: Lewis - this is to force this component to re-render when a threejs state is changed
  const [forceUpdateSceneEditor, setForceUpdateSceneEditor] = useState(false);

  context.setWhichModalIsOpen = setWhichModalIsOpen;
  context.setForceUpdateSceneEditor = setForceUpdateSceneEditor; 

  const { 
    currentlyAttachedScene, 
  } = context; 
  
  // @note: Lewis - this is used to load the background color
  // useEffect(() => {
  //   setCurrentlySelectedSceneBackgroundDisplayColor(scenes[selectedScene].backgroundDisplayColor)
  // }, []); 
  
  useEffect(() => {
    deleteAllObjectsFromScene(context);
    loadSceneObjects([...scenes[selectedScene].sceneObjects], context.addModelToScene);
    setCurrentlySelectedSceneBackgroundDisplayColor(scenes[selectedScene].backgroundDisplayColor)
    context.gridHelper.material.visible = true;
  }, [selectedScene]);

  const setBackgroundDisplayType = (newType) => {
    setScenes(prev => {
      const copy = {...prev}; 
      copy[selectedScene].backgroundDisplayType = newType; 
      return copy; 
    });
  };

  const setBackgroundImageFileInput = (newImageFile) => {
    setScenes(prev => {
      const copy = {...prev}; 
      copy[selectedScene].backgroundImageFileInput = newImageFile; 
      return copy; 
    });
  };


  return (
    <SceneEditorPanelContainer finishEditingEnviornment={whichModalIsOpen === 'FinishEditingEnviornmentInput'}>
      <PanelOption className="set-opacity">
        <PanelOptionHeading>{'Drag and Drop Objects'}</PanelOptionHeading>
        <ElementContainer>
          <DragAndDropObjectsInput
            value={config.sceneObjectCollections}
            selectedSceneTheme={selectedSceneTheme}
            setSelectedSceneTheme={setSelectedSceneTheme}
            maxSceneObjectCount={maxSceneObjectCount}
            context={context}
          />
        </ElementContainer>
      </PanelOption>
      <PanelOption className="set-opacity">
        <ObjectsOnSceneInput 
          selectedSceneObject={selectedSceneObject}
          setSelectedSceneObject={setSelectedSceneObject}
          deleteSelectedObjectFromScene={deleteSelectedObjectFromScene}
          deleteAllObjectsFromScene={deleteAllObjectsFromScene}
          switchTransformControlsToSelectedObject={switchTransformControlsToSelectedObject}
          currentlyAttachedScene={currentlyAttachedScene}
          maxSceneObjectCount={maxSceneObjectCount}
          context={context}
        /> 
      </PanelOption>
      <PanelOption>
        <ElementContainer>
          <ChangeBackgroundDisplayInput
            className="set-opacity"
            value={currentlySelectedSceneBackgroundDisplayColor}
            onChange={setCurrentlySelectedSceneBackgroundDisplayColor}
            scenes={scenes}
            setScenes={setScenes}
            selectedScene={selectedScene}
            currentlySelectedSceneBackgroundDisplayType={currentlySelectedSceneBackgroundDisplayType}
            setCurrentlySelectedSceneBackgroundDisplayType={setCurrentlySelectedSceneBackgroundDisplayType}
            selectedBackgroundDisplayType={scenes[selectedScene].backgroundDisplayType}
            setSelectedBackgroundDisplayType={setBackgroundDisplayType}
            currentlySelectedSceneBackgroundDisplayPosition={currentlySelectedSceneBackgroundDisplayPosition}
            setCurrentlySelectedSceneBackgroundDisplayPosition={setCurrentlySelectedSceneBackgroundDisplayPosition}
            backgroundDisplayTypes={config.backgroundDisplayTypes}
            whichModalIsOpen={whichModalIsOpen}
            setWhichModalIsOpen={setWhichModalIsOpen}
            backgroundImageFileInput={scenes[selectedScene].backgroundImageFileInput}
            setBackgroundImageFileInput={setBackgroundImageFileInput}
            context={context}
          />
          <FinishEditingEnviornmentInput 
           value={currentlySelectedSceneBackgroundDisplayColor}
           onChange={setCurrentlySelectedSceneBackgroundDisplayColor}
           currentlySelectedSceneBackgroundDisplayType={currentlySelectedSceneBackgroundDisplayType}
           setCurrentlySelectedSceneBackgroundDisplayType={setCurrentlySelectedSceneBackgroundDisplayType}
           whichModalIsOpen={whichModalIsOpen}
           setWhichModalIsOpen={setWhichModalIsOpen}
           context={context}
          />
        </ElementContainer>
      </PanelOption>
    </SceneEditorPanelContainer>
  );
};
export default SceneEditorPanel;

