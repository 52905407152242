import { useState, useRef } from 'react';
import styled from 'styled-components';
import ColorPickerV3 from '../modals/ColorPickerV3';
import { ModalContainer,FloatingModalContainerV5 } from '../modals/shared';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'; 
import { TabSelector, TabContainer } from '../panels/shared';
import UploadBackgroundImage from '../modals/UploadBackgroundImage';
import { SaveChangesButtonContainer } from '../modals/shared';
import DoneEditingWarning from '../modals/DoneEditingWarning'; 

const dragAndDropSVG = {
  title: '6-dots',
  iconImagePath: 'images/icons/colorway-panel/6dots.svg'
};

const pencilSVG = {
  title: 'pencil',
  iconImagePath: 'images/icons/colorway-panel/pencil.svg'
};

const ColorSwatchInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* background: rgba(196, 196, 196, 0.3); */
  background: rgba(196, 196, 196, 0.1);
  /* padding: 32px; */
  padding: 8px;
  margin: 0 16px;
  > *+* {
    margin-top: 16px;
  }
  > div {
    background: #ffffff;
  };
  ul {
    list-style-type: none;
    margin: 0; 
    padding: 0;
  };
`;

const IndividualColorSwatchContainer = styled.div`
  display: flex;
  /* position: relative; */
  flex-direction: row;
  box-sizing: border-box;
  background: #ffffff;
`;

const LockedColorSwatchParentContainer = styled.div`
  position: absolute; 
  > a {
    text-decoration: none;
  };
`;

const LockedColorSwatchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(235, 235, 235, 0.7);
  backdrop-filter: blur(3px);
  padding: 36px 55px;
  color: #696969;
  font-weight: 900;
  white-space: nowrap; 
`;

const PencilSVGContainer = styled.div`
  /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); */
  /* margin-right: 1px; */
  box-sizing: border-box;
  padding-top: 12px;
  .pencil {
    filter: invert(12%) sepia(51%) saturate(3548%) hue-rotate(208deg)
  }
`;

const TitleContainer = styled.div`
  /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); */
  /* margin-right: 1px; */
  color: #072B62;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  padding-top: 12px;
  .pencil {
    filter: invert(12%) sepia(51%) saturate(3548%) hue-rotate(208deg)
  }
`;

const DragAndReOrderContainer = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-right: 1px;
  padding-top: 32px;
`;

const EditSwatchesContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const EditSwatchContainer = styled.div`
  display: flex;
  align-items: center;
  > div {
    padding: 7px;
  }
`;

const EditSwatchNameInputContainer = styled.div`
  flex: 1;
  margin: 0 0 1px 1px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  > input {
    cursor: pointer;
    border: none;
    background-color: rgba(0,0,0,0);
  }
`;

const SelectedContainerParent = styled.div`
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  /* padding-top: 10px; */
`;

const SelectedContainer = styled.div`
  box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  border: ${({ isSelected }) => isSelected ? '6px solid #072B62' : '1px solid #BEBEBE'};
  border-radius: 50%;
  height: 20px !important;
  width: 20px !important;
  align-self: center;
  margin-top: 2px;
`;

const EditSwatchContainerV2 = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin: 1px 0 0 1px;
  > div {
    padding: 12px;
  }
  > *+* {
    margin-left: 6px;
  }
`;

const SwatchImageBoxContainer = styled.img`
  width: 42px; 
  height: 42px;
  margin-left: 6px;
`;

const SwatchColorBoxContainer = styled.div`
  background: ${({ backgroundColorOrImage }) => backgroundColorOrImage};
  border-radius: 25%;
  margin-left: 6px;
`;

const EditSwatchButtonContainer = styled.div`
  flex: 1;
  color: #072B62;
  &:hover {
    background: #7689A4;
  }
  &:focus, &:active {
    background: #D3D8DE;;
  }
  cursor: pointer;
`;

const TopOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  .tab-selector {
  }
`;

const HeaderContainer = styled.div`
  display: flex; 
  justify-content: space-between;
  border-bottom: 1px solid #E5E5E5;
`;

const CloseModalContainer = styled.div`
  > svg {
    /* position: absolute;
    top: -20px;
    right: -15px;
    z-index: 10; */
    height: 40px;
    width: 40px;
    color: #161616;
    /* border: 1px solid #ffffff;
    border-radius: 50%;
    background:rgb(249, 249, 249); */
    /* font-size: 50px;
    font-weight: bold;
    line-height: 42px;
    text-align: center; */
    cursor:pointer;
    /* transition: background-color linear 200ms;  */
  }

  > svg:hover {
    /* background-color: linear-gradient(to right bot, rgba(77, 77, 77), red); */
    background-color: rgba(110, 110, 110);
    color: #ffffff;
  }
`;
const ColorPickerModalContainer = styled.div`
  white-space: nowrap;
  margin: 0 !important;
`;

const EditComponentModalContainer = styled.div`
  .blurred {
    filter: ${({ isDoneEditing }) => isDoneEditing ? 'blur(8px)' : 'none'};
  }
`;

// @note - Lewis to export so it becomes a re-useable ui 
export const Header = ({ title, onCancel }) => {
  return (
    <HeaderContainer>
      {/* Used in multiple components, break out into its own component */}
      <PencilSVGContainer><img className='pencil' src={pencilSVG.iconImagePath} alt='Pencil Icon'/></PencilSVGContainer>
      <TitleContainer>{title}</TitleContainer>
      <CloseModalContainer onClick={onCancel}>
        <svg width="400px" height="400px" viewBox="-150 40 800 400" xmlns="http://www.w3.org/2000/svg">
          <polygon fill="var(--ci-primary-color, currentColor)" points="427.314 107.313 404.686 84.687 256 233.373 107.314 84.687 84.686 107.313 233.373 256 84.686 404.687 107.314 427.313 256 278.627 404.686 427.313 427.314 404.687 278.627 256 427.314 107.313" className="ci-primary"/>
        </svg>
      </CloseModalContainer>
    </HeaderContainer>
  )
};

const ColorPickerModal = ({ 
  value, onChange, position,
}) => {

  const [isColorTabOpen, setIsColorTabOpen] = useState(true); 
  const [unSavedImageFileInput, setUnSavedImageFileInput] = useState(value.imageFileInput); 
  const [unSavedColorSwatch, setUnSavedColorSwatch] = useState(value.hex); 
  const [valueConvertedToHex, setValueConvertedToHex] = useState(value.hex); 
  const [hasAnyPropertiesChangedOnEditColour, setHasAnyPropertiesChangedOnEditColour] = useState(false); 
  const [showDoneEditingWarning, setShowDoneEditingWarning] = useState(false); 


  const onSaveColour = () => {
    onChange(valueConvertedToHex); 
  };

  const onSaveImage = () => {
    onChange(null, unSavedImageFileInput); 
  };

  const noSaveAndExit = () => {
    onChange();
  };

  const onExit = () => {
    if (hasAnyPropertiesChangedOnEditColour) {
      setShowDoneEditingWarning(true);
      return; 
    }; 
    onChange();
  };

  
  return (
    <ColorPickerModalContainer>
      {showDoneEditingWarning && (
        <DoneEditingWarning position={position} isColorModal='true' saveHandler={isColorTabOpen ? onSaveColour : onSaveImage} noSaveHandler={noSaveAndExit}/>
      )}
       <EditComponentModalContainer isDoneEditing={showDoneEditingWarning}>
        <FloatingModalContainerV5 position={{x: position.x, y: position.y - 200}}>
          <ModalContainer className='blurred'>
            <TopOptionsContainer>
              <Header
                title='Edit Colour'
                onCancel={onExit}
              />
              <TabSelector>
                <div className="tab-selector">
                  <TabContainer isSelected={isColorTabOpen} onClick={() => setIsColorTabOpen(true)}>{'Colour'}</TabContainer>
                  <TabContainer isSelected={!isColorTabOpen} onClick={() => setIsColorTabOpen(false)}>{'Image'}</TabContainer>
                </div>
              </TabSelector>
            </TopOptionsContainer>
            {isColorTabOpen ? (
              // Legend #17
              <>
                <ColorPickerV3
                  color={unSavedColorSwatch}
                  setColor={setUnSavedColorSwatch}
                  labelText="Hex"
                  setValueConvertedToHex={setValueConvertedToHex}
                  setHasAnyPropertiesChangedOnEditColour={setHasAnyPropertiesChangedOnEditColour}
                />
                <SaveChangesButtonContainer onClick={onSaveColour}>
                  <div>{'Save Changes'}</div>
                </SaveChangesButtonContainer>
              </>
            ) : (
              // Legend #18
              <>
                <UploadBackgroundImage
                  backgroundImageFileInput={unSavedImageFileInput}
                  setBackgroundImageFileInput={setUnSavedImageFileInput} 
                  setHasAnyPropertiesChangedOnEditColour={setHasAnyPropertiesChangedOnEditColour}
                />
                <SaveChangesButtonContainer onClick={onSaveImage}>
                  <div>{'Save Changes'}</div>
                </SaveChangesButtonContainer>
              </>
              )
            }
          </ModalContainer>
        </FloatingModalContainerV5>
      </EditComponentModalContainer>
    </ColorPickerModalContainer>
  )
};


const IndividualSwatch = ({ 
  value,
  onChange,
  id, 
  setSelectedSwatch,
  whichSwatchIsOpen, setWhichSwatchIsOpen,
}) => {

  const swatchContainerRef = useRef(null); 

  const toggleWhichSwatchIsOpen = () => {
    if (whichSwatchIsOpen === value.title) {
      setWhichSwatchIsOpen(null, {x: 0, y: 0});
      setSelectedSwatch(null);
    } else {
      const boundingBox = swatchContainerRef.current.getBoundingClientRect(); 
      setWhichSwatchIsOpen(value.title, {x: boundingBox.left + boundingBox.width, y: boundingBox.top}); 
      setSelectedSwatch(value.title)
    }
  };

  return (
    <>
      <IndividualColorSwatchContainer ref={swatchContainerRef}> 
        {value.locked && (
          <LockedColorSwatchParentContainer>
            <a href="https://thecela.com/contact-us" rel='noreferrer' target="_blank">
              <LockedColorSwatchContainer>{'Contact Cela to Unlock'}</LockedColorSwatchContainer>
            </a>
          </LockedColorSwatchParentContainer>
        )}
        {/* TODO: Used in multiple components */}
        <DragAndReOrderContainer><img src={dragAndDropSVG.iconImagePath} alt='Drag and Drop Icon' /></DragAndReOrderContainer>
        <EditSwatchesContainer>
          <EditSwatchContainer>
            <SelectedContainerParent>
              <SelectedContainer isSelected={whichSwatchIsOpen === value.title} onClick={toggleWhichSwatchIsOpen}/>
            </SelectedContainerParent>
            <EditSwatchNameInputContainer>
              <input value={value.color} onChange={(e) => onChange(e.target.value)} onClick={() => setSelectedSwatch(value.title)}/>
            </EditSwatchNameInputContainer>
          </EditSwatchContainer>
          <EditSwatchContainerV2>
            {value.isColorMostRecentlySelected ? (
              <SwatchColorBoxContainer backgroundColorOrImage={value.hex}/>
              ) 
            : (
              <SwatchImageBoxContainer src={value.imageFileInput && value.imageFileInput.src}/>
              )
            }
            <EditSwatchButtonContainer onClick={toggleWhichSwatchIsOpen}>
              {'Edit Swatches'}
            </EditSwatchButtonContainer>
          </EditSwatchContainerV2>
        </EditSwatchesContainer>
      </IndividualColorSwatchContainer>
    </>
  );
};

const ColorSwatchInput = ({ 
    value, onChange,
    colorSwatchesTitles,
    setColorSwatchesTitles,
    selectedSwatch,
    setSelectedSwatch,
  }) => {

  const [whichSwatchIsOpen, setWhichSwatchIsOpen] = useState(null); 
  const [modalPosition, setModalPosition] = useState({x:0, y:0}); 

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(colorSwatchesTitles);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setColorSwatchesTitles(items);
  };

  const setNewSwatch = (newColor, newImageFile=null) => {
    onChange(prev => {
      const copy = {...prev}; 
      if (newColor) {
        copy[selectedSwatch].hex = newColor; 
        copy[selectedSwatch].isColorMostRecentlySelected = true;
      };
      if (newImageFile) {
        copy[selectedSwatch].imageFileInput = newImageFile; 
        copy[selectedSwatch].isColorMostRecentlySelected = false;
      };
      return copy;
    });
    setWhichSwatchIsOpen(null);
  };

  const changeSwatchTitle = (newTitle) => {
    onChange(prev => {
      const copy = {...prev}; 
      copy[selectedSwatch].color = newTitle; 
      return copy; 
    })
  }; 

  return (
    <ColorSwatchInputContainer>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="swatches">
          {(provided) => (
            <ul 
              className="swatches"
              {...provided.droppableProps} 
              ref={provided.innerRef}
            >
              {colorSwatchesTitles.filter(title => title !== 'Add another').map((title, index) => (
                // Legend #15
                <> 
                    <Draggable key={title} draggableId={title} index={index}>
                      {(provided) => (
                        <li 
                          ref={provided.innerRef} 
                          {...provided.draggableProps} 
                          {...provided.dragHandleProps}
                        >
                          <IndividualSwatch
                            value={value[title]}
                            onChange={changeSwatchTitle}
                            id={index + 1}
                            key={title}
                            setSelectedSwatch={setSelectedSwatch}
                            whichSwatchIsOpen={whichSwatchIsOpen}
                            // setWhichSwatchIsOpen={setWhichSwatchIsOpen}
                            setWhichSwatchIsOpen={(which, position) => {
                              setWhichSwatchIsOpen(which);
                              setModalPosition(position); 
                            }}
                          />
                        </li>
                      )}
                    </Draggable>
                </>
                ))}
              {provided.placeholder}
            </ul>
            )}
        </Droppable>
      </DragDropContext>
      {whichSwatchIsOpen && (
        // Legend #16
        <ColorPickerModal 
          value={value[selectedSwatch]}
          onChange={setNewSwatch}
          position={modalPosition}
        />
      )}
    </ColorSwatchInputContainer>
  );
};

export default ColorSwatchInput;















