import React from "react";
import styled from "styled-components";
import { desktopHelpImages, mobileHelpImages } from "../../../config";
import HelpPanelItem from "../components/HelpPanelItem";

const HelpIconContainer = styled.aside`
  display: flex;
  flex-direction: ${window.innerWidth <= 1024 ? "row" : "column"};
  justify-content: space-between;
  align-items: center;
`;

const HelpPanel = () => {
  const itemList = (imagesArray) => {
    return imagesArray.map((item, index) => {
      return (
        <HelpPanelItem
          key={index}
          title={item.title}
          message={item.message}
          iconUrlPath={item.iconUrlPath}
        />
      );
    });
  };

  return (
    <HelpIconContainer>
      {window.innerWidth <= 1024
        ? itemList(mobileHelpImages)
        : itemList(desktopHelpImages)}
    </HelpIconContainer>
  );
};

export default HelpPanel;
