import { useState, useEffect } from "react";

export const useFullScreen = () => {
  const [fullscreenEnabled, setFullScreenEnabled] = useState(false);

  useEffect(() => {
    if (fullscreenEnabled && !document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.fullscreenElement && document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }, [fullscreenEnabled]);

  return [fullscreenEnabled, setFullScreenEnabled];
};
