import React, { useState } from "react";
import styled from "styled-components";
import ButtonBase from "./buttons/ButtonBase";
import { FloatingModalContainer } from "../modals/shared";
import ColorPicker from "../modals/ColorPicker";

const ColorInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > * {
    width: 50%;
    box-sizing: border-box;
  }
  > * + * {
    margin-left: 16px;
  }
`;

const ColorPreviewContainer = styled.div`
  background: ${({ backgroundColor }) => `${backgroundColor}`};
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #666;
  border-radius: 4px;
  box-sizing: border-box;
  height: 36.39px;
`;

const ColorInput = ({ value, onChange }) => {
  const [shouldShowColorPicker, setShouldShowColorPicker] = useState(false);
  const [innerValue, setInnerValue] = useState(value);
  const [innerFinalValue, setInnerFinalValue] = useState(value); // @todo: rethink.

  return (
    <ColorInputContainer>
      <ColorPreviewContainer backgroundColor={value} />
      <ButtonBase
        primary
        onClick={() => setShouldShowColorPicker(!shouldShowColorPicker)}
      >
        Change
      </ButtonBase>
      {shouldShowColorPicker && (
        <FloatingModalContainer>
          <ColorPicker
            color={innerValue}
            setColor={setInnerValue}
            onChange={setInnerFinalValue}
            onSave={() => {
              onChange(innerFinalValue.hex);
              setShouldShowColorPicker(false);
            }}
            onCancel={setShouldShowColorPicker}
            labelText='Hex'
          />
        </FloatingModalContainer>
      )}
    </ColorInputContainer>
  );
};

export default ColorInput;
