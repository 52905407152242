import { ImageContainer } from "../inputs/ImageFileInput";
import styled from 'styled-components';

const ImageContainerParent = styled.div`
  position: absolute; 
  left: 50vw;
  top: 2vh;
  max-width: 100px;
  max-height: 100px;
`
const LogoDisplay = ({ imageFile }) => {
  return (
  <ImageContainerParent>
    {imageFile && (
      <ImageContainer>
        <img src={imageFile.src} alt="Logo"/>
      </ImageContainer>
    )}
  </ImageContainerParent>
  )
}

export default LogoDisplay; 