import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import ColorPickerV3 from '../modals/ColorPickerV3';
import { ModalContainer,FloatingModalContainerV5, FloatingModalContainerV4 } from '../modals/shared';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'; 
import { TabSelector, TabContainer } from '../panels/shared';
import UploadBackgroundImage from '../modals/UploadBackgroundImage';
import { SaveChangesButtonContainer } from '../modals/shared';
import DoneEditingWarning from '../modals/DoneEditingWarning'; 

const dragAndDropSVG = {
  title: '6-dots',
  iconImagePath: 'images/icons/colorway-panel/6dots.svg'
};

const pencilSVG = {
  title: 'pencil',
  iconImagePath: 'images/icons/colorway-panel/pencil.svg'
};

const DoneEditingWarningParentContainer = styled.div`
  /* position: relative;  */
`;
const ColorComponentInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* background: rgba(196, 196, 196, 0.3); */
  background: rgba(196, 196, 196, 0.1);
  /* padding: 32px; */
  padding: 8px;
  margin: 0 16px;
  > *+* {
    margin-top: 16px;
  }
  > div {
    background: #ffffff;
  };
  ul {
    list-style-type: none;
    margin: 0; 
    padding: 0;
  };
`;

const ParentContainer = styled.div`
  /* position: relative; */
`;

const IndividualColorSwatchContainer = styled.div`
  display: flex;
  /* position: relative; */
  flex-direction: row;
  box-sizing: border-box;
  background: #ffffff;
`;

const LockedColorSwatchParentContainer = styled.div`
  position: absolute; 
  > a {
    text-decoration: none
  };
`;

const LockedColorSwatchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(235, 235, 235, 0.7);
  backdrop-filter: blur(3px);
  padding: 36px 55px;
  color: #696969;
  font-weight: 900;
  white-space: nowrap; 
`;

const PencilSVGContainer = styled.div`
  /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); */
  /* margin-right: 1px; */
  box-sizing: border-box;
  padding-top: 12px;
  .pencil {
    filter: invert(12%) sepia(51%) saturate(3548%) hue-rotate(208deg)
  }
`;

const TitleContainer = styled.div`
  /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); */
  /* margin-right: 1px; */
  color: #072B62;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  padding-top: 12px;
  .pencil {
    filter: invert(12%) sepia(51%) saturate(3548%) hue-rotate(208deg)
  }
`;

const DragAndReOrderContainer = styled.div`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-right: 1px;
  padding-top: 32px;
`;

const EditSwatchesContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const EditSwatchContainer = styled.div`
  display: flex;
  align-items: center;
  > div {
    padding: 7px;
  }
`;

const EditSwatchNameInputContainer = styled.div`
  flex: 1;
  margin: 0 0 1px 1px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  > input {
    cursor: pointer;
    border: none;
    background-color: rgba(0,0,0,0);
  }
`;

const EditSwatchNameInputContainerV2 = styled.div`
  flex: 1;
  margin: 16px;
  background-color: #F1F1F1;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  > input {
    cursor: pointer;
    border: none;
    background-color: #F1F1F1;
    padding: 8px 11px; 
    flex: 1;
  }
`;

const SwatchParentContainer = styled.div`
  display: flex;
  flex-direction: column; 
  > *+* { 
    margin-top: 16px;
  }
`;

const SwatchContainer = styled.div`
  display: flex; 
  justify-content: start;
  background: #FFFFFF;
  /* border: 1px solid #072B62; */
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  > div {
    margin: 4px;
  }
`;

const SelectedContainerParent = styled.div`
  display: flex;
  align-items: center;
  > div {
    padding: 2px;
  }
`;

const SelectedContainer = styled.div`
/* position: absolute; */
  box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  border: ${({ isSelected }) => isSelected ? '6px solid #072B62' : '1px solid #BEBEBE'};
  border-radius: 50%;
  height: 18px !important;
  width: 18px !important;
  align-self: center;
`;

const EditSwatchContainerV2 = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin: 1px 0 0 1px;
  > div {
    padding: 12px;
  }
  > *+* {
    margin-left: 6px;
  }
`;

const SwatchColorBoxContainerV2 = styled.div`
  padding: 20px;
  background: ${({ backgroundColorOrImage }) => backgroundColorOrImage};
  border-radius: 25%;
  margin-left: 6px;
`;

const SwatchImageBoxContainer = styled.img`
  width: 42px; 
  height: 42px;
  margin-left: 6px;
`;

const EditSwatchTitleContainer = styled.div`
  > input {
    box-sizing: border-box;
    width: 100%;
    background-color: #F1F1F1;
    cursor: pointer;
    border: 1px solid #F1F1F1;
    border-radius: 7px;
    padding: 12px;
  }
`;

const SwatchColorBoxContainer = styled.div`
  background: ${({ backgroundColorOrImage }) => backgroundColorOrImage};
  border-radius: 25%;
  margin-left: 6px;
`;

const SwatchRankingContainer = styled.div`
  margin: 0 1px 1px 1px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
`;

const EditSwatchButtonContainer = styled.div`
  flex: 1;
  color: #072B62;
  &:hover {
    background: #7689A4;
  }
  &:focus, &:active {
    background: #D3D8DE;;
  }
  cursor: pointer;
`;

const TopOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  .tab-selector {
  }
`;

const HeaderContainer = styled.div`
  display: flex; 
  justify-content: space-between;
  border-bottom: 1px solid #E5E5E5;
`;

const CloseModalContainer = styled.div`
  > svg {
    /* position: absolute;
    top: -20px;
    right: -15px;
    z-index: 10; */
    height: 40px;
    width: 40px;
    color: #161616;
    /* border: 1px solid #ffffff;
    border-radius: 50%;
    background:rgb(249, 249, 249); */
    /* font-size: 50px;
    font-weight: bold;
    line-height: 42px;
    text-align: center; */
    cursor:pointer;
    /* transition: background-color linear 200ms;  */
  }

  > svg:hover {
    /* background-color: linear-gradient(to right bot, rgba(77, 77, 77), red); */
    background-color: rgba(110, 110, 110);
    color: #ffffff;
  }
`;
const ColorPickerInputContainer = styled.div`
  white-space: nowrap;
  margin: 0 !important;
`;

const EditComponentModalContainer = styled.div`
  .blurred {
    filter: ${({ isDoneEditing }) => isDoneEditing ? 'blur(8px)' : 'none'};
  }
`;

// @note - Lewis to export so it becomes a re-useable ui 
export const Header = ({ title, onCancel }) => {
  return (
    <HeaderContainer>
      <PencilSVGContainer><img className='pencil' src={pencilSVG.iconImagePath} alt='Pencil Icon'/></PencilSVGContainer>
      <TitleContainer>{title}</TitleContainer>
      <CloseModalContainer onClick={onCancel}>
        <svg width="400px" height="400px" viewBox="-150 40 800 400" xmlns="http://www.w3.org/2000/svg">
          <polygon fill="var(--ci-primary-color, currentColor)" points="427.314 107.313 404.686 84.687 256 233.373 107.314 84.687 84.686 107.313 233.373 256 84.686 404.687 107.314 427.313 256 278.627 404.686 427.313 427.314 404.687 278.627 256 427.314 107.313" className="ci-primary"/>
        </svg>
      </CloseModalContainer>
    </HeaderContainer>
  )
};

const ColorPickerInput = ({ 
  value, onChange, position
}) => {

  const [isColorTabOpen, setIsColorTabOpen] = useState(true); 
  const [unSavedImageFileInput, setUnSavedImageFileInput] = useState(value.imageFileInput); 
  const [unSavedColorSwatch, setUnSavedColorSwatch] = useState(value.hex); 
  const [valueConvertedToHex, setValueConvertedToHex] = useState(value.hex); 
  const [hasAnyPropertiesChangedOnEditColour, setHasAnyPropertiesChangedOnEditColour] = useState(false); 
  const [showDoneEditingWarning, setShowDoneEditingWarning] = useState(false); 


  const onSaveColour = () => {
    onChange(valueConvertedToHex); 
  };

  const onSaveImage = () => {
    onChange(null, unSavedImageFileInput); 
  };

  const noSaveAndExit = () => {
    onChange();
  };

  const onExit = () => {
    if (hasAnyPropertiesChangedOnEditColour) {
      setShowDoneEditingWarning(true);
      return; 
    }; 
    onChange();
  };

  
  return (
    <ColorPickerInputContainer>
      {showDoneEditingWarning && (
        <DoneEditingWarning position={{x: position.x - 64, y: position.y - 496}} isColorModal='true' saveHandler={isColorTabOpen ? onSaveColour : onSaveImage} noSaveHandler={noSaveAndExit}/>
      )}
       <EditComponentModalContainer isDoneEditing={showDoneEditingWarning}>
        <FloatingModalContainerV4 id="FloatingModalContainerV4">
          <ModalContainer className='blurred'>
            <TopOptionsContainer>
              <Header
                title='Edit Colour'
                onCancel={onExit}
              />
              <TabSelector>
                <div className="tab-selector">
                  <TabContainer isSelected={isColorTabOpen} onClick={() => setIsColorTabOpen(true)}>{'Colour'}</TabContainer>
                  <TabContainer isSelected={!isColorTabOpen} onClick={() => setIsColorTabOpen(false)}>{'Image'}</TabContainer>
                </div>
              </TabSelector>
            </TopOptionsContainer>
            {isColorTabOpen ? (
              // Legend #11
              <>
                <ColorPickerV3
                  color={unSavedColorSwatch}
                  setColor={setUnSavedColorSwatch}
                  labelText="Hex"
                  setValueConvertedToHex={setValueConvertedToHex}
                  setHasAnyPropertiesChangedOnEditColour={setHasAnyPropertiesChangedOnEditColour}
                />
                <SaveChangesButtonContainer onClick={onSaveColour}>
                  <div>{'Save Changes'}</div>
                </SaveChangesButtonContainer>
              </>
            ) : (
              // Legend #12
              <>
                <UploadBackgroundImage
                  backgroundImageFileInput={unSavedImageFileInput}
                  setBackgroundImageFileInput={setUnSavedImageFileInput} 
                  setHasAnyPropertiesChangedOnEditColour={setHasAnyPropertiesChangedOnEditColour}
                />
                <SaveChangesButtonContainer onClick={onSaveImage}>
                  <div>{'Save Changes'}</div>
                </SaveChangesButtonContainer>
              </>
              )
            }
          </ModalContainer>
        </FloatingModalContainerV4>
      </EditComponentModalContainer>
    </ColorPickerInputContainer>
  )
};

const IndividualSwatch = ({ 
  value, 
  onChange,
  position,
  whichColorPickerInputOpen, setWhichColorPickerInputOpen, 
  setHasAnyPropertiesChangedOnEditComponent, 
}) => {
  const [newSwatch, setNewSwatch] = useState(JSON.parse(JSON.stringify(value))); 

  useEffect(() => {
    onChange(newSwatch, newSwatch.title);
  }, [newSwatch])

  const setNewSwatch_ = (newColor, newImageFile=null) => {
    setNewSwatch(prev => {
      const copy = {...prev}; 
      if (newColor) {
        copy.hex = newColor; 
        copy.isColorMostRecentlySelected = true;
      };
      if (newImageFile) {
        copy.imageFileInput = newImageFile; 
        copy.isColorMostRecentlySelected = false;
      };
      return copy;
    })
    setHasAnyPropertiesChangedOnEditComponent(true);
  };

  const toggleColorPickerInput = () => {
    if (whichColorPickerInputOpen === newSwatch.title) {
      setWhichColorPickerInputOpen(null); 
    } else {
      setWhichColorPickerInputOpen(newSwatch.title);
      setHasAnyPropertiesChangedOnEditComponent(true);
    };
  }; 

  

  return (
    <SwatchContainer>
      <SelectedContainerParent>
        <div>
            <SelectedContainer isSelected={whichColorPickerInputOpen === newSwatch.title} onClick={toggleColorPickerInput}/>
        </div>
      </SelectedContainerParent>
      {newSwatch.isColorMostRecentlySelected ? (
        <SwatchColorBoxContainerV2 backgroundColorOrImage={newSwatch.hex}/>
      ) 
      : (
        <SwatchImageBoxContainer src={newSwatch.imageFileInput.src}/>
        )
      }
      {/* Legend #9 */}
      <EditSwatchTitleContainer>
        <input 
          type="text" 
          value={newSwatch.color} 
          onChange={(e) => {
            setNewSwatch(prev => {
              const copy = {...prev}; 
              copy.color = e.target.value; 
              setHasAnyPropertiesChangedOnEditComponent(true);
              return copy;
            });
          }}/>
      </EditSwatchTitleContainer>
      {whichColorPickerInputOpen === newSwatch.title && (
        // Legend #10
        <ColorPickerInput 
          value={newSwatch}
          onChange={setNewSwatch_}
          position={position}
        />
      )}
    </SwatchContainer>
  )
};

const EditComponentModal = ({ 
  value, 
  onChange,
  swatchTitles, 
  setWhichSwatchIsOpen,
  selectedComponentTitle,
  position
}) => {
  const [whichColorPickerInputOpen, setWhichColorPickerInputOpen] = useState(null); 
  const [hasAnyPropertiesChangedOnEditComponent, setHasAnyPropertiesChangedOnEditComponent] = useState(false); 
  const [showDoneEditingWarning, setShowDoneEditingWarning] = useState(false); 

  const [newSwatches, setNewSwatches] = useState(JSON.parse(JSON.stringify(value))); 
  const [newComponentTitle, setNewComponentTitle] = useState(selectedComponentTitle);

  const setIndividualSwatch = (newSwatch, id) => {
    setNewSwatches(prev => {
      const copy = JSON.parse(JSON.stringify(prev));
      copy[id] = newSwatch;
      return copy 
    });
    setWhichColorPickerInputOpen(null); 
  };

  const saveAndExit = () => {
    if (hasAnyPropertiesChangedOnEditComponent) {
      onChange(newSwatches, whichColorPickerInputOpen, newComponentTitle);
    };
    setWhichSwatchIsOpen(null);
    setHasAnyPropertiesChangedOnEditComponent(false);
  };

  const noSaveAndExit = () => {
    setWhichSwatchIsOpen(null);
    setHasAnyPropertiesChangedOnEditComponent(false);
  };

  const onExit = () => {
    if (hasAnyPropertiesChangedOnEditComponent) {
      setShowDoneEditingWarning(true);
      return; 
    }; 
    setWhichSwatchIsOpen(null);
    setHasAnyPropertiesChangedOnEditComponent(false);
  };

  return (
    <ParentContainer>
      {showDoneEditingWarning && (
        <DoneEditingWarningParentContainer> 
          <DoneEditingWarning saveHandler={saveAndExit} noSaveHandler={noSaveAndExit} position={position}/>
        </DoneEditingWarningParentContainer>
      )}
      <EditComponentModalContainer isDoneEditing={showDoneEditingWarning}>
        <FloatingModalContainerV5 position={position} className='blurred'>
          <ModalContainer>
          <Header 
            title='Edit Component'
            onCancel={onExit}
          />
            <EditSwatchNameInputContainerV2>
              <input 
                name='color' 
                value={newComponentTitle}
                onChange={(e) => {setNewComponentTitle(e.target.value);setHasAnyPropertiesChangedOnEditComponent(true)}}
                />
            </EditSwatchNameInputContainerV2>
            <SwatchParentContainer>
              {swatchTitles.map((swatch, index) => (
                // Legend #8
                <IndividualSwatch
                  key={index}
                  value={newSwatches[swatch]}
                  onChange={setIndividualSwatch}
                  position={position}
                  whichColorPickerInputOpen={whichColorPickerInputOpen}
                  setWhichColorPickerInputOpen={setWhichColorPickerInputOpen}
                  setHasAnyPropertiesChangedOnEditComponent={setHasAnyPropertiesChangedOnEditComponent}
                />
              )
              )}
            </SwatchParentContainer>
            <SaveChangesButtonContainer onClick={saveAndExit}>
              <div>{'Save Changes'}</div>
            </SaveChangesButtonContainer>
          </ModalContainer>
        </FloatingModalContainerV5>
      </EditComponentModalContainer>
    </ParentContainer>
  )
};

const IndividualColorComponent = ({ 
  value,
  id, 
  setSelectedComponent,
  whichSwatchIsOpen, setWhichSwatchIsOpen,
}) => {

  const swatchContainerRef = useRef(null); 

  const toggleWhichSwatchIsOpen = () => {
    if (whichSwatchIsOpen === value.title) {
      setWhichSwatchIsOpen(null, {x: 0, y: 0});
      setSelectedComponent(null);
    } else {
      const boundingBox = swatchContainerRef.current.getBoundingClientRect(); 
      setWhichSwatchIsOpen(value.title, {x: boundingBox.left + boundingBox.width, y: boundingBox.top}); 
      setSelectedComponent(value.title)
    }
  };

  // console.log('value', value);
  return (
    <>
      <IndividualColorSwatchContainer ref={swatchContainerRef}> 
        {value.locked && (
          <LockedColorSwatchParentContainer>
            <a href="https://thecela.com/contact-us" rel='noreferrer' target="_blank">
              <LockedColorSwatchContainer>{'Contact Cela to Unlock'}</LockedColorSwatchContainer>
            </a>
          </LockedColorSwatchParentContainer>
        )}
        <DragAndReOrderContainer><img src={dragAndDropSVG.iconImagePath} alt='Drag and Drop Icon' /></DragAndReOrderContainer>
        <EditSwatchesContainer>
          <EditSwatchContainer>
            <SwatchRankingContainer>{id}</SwatchRankingContainer>
            {/* <SelectedContainerV2 isSelected={whichSwatchIsOpen === value.title} onClick={toggleWhichSwatchIsOpen}/> */}
            <EditSwatchNameInputContainer>
              {value.color}
            </EditSwatchNameInputContainer>
          </EditSwatchContainer>
          <EditSwatchContainerV2>
            {value.isColorMostRecentlySelected ? (
              <SwatchColorBoxContainer backgroundColorOrImage={value.hex}/>
              ) 
            : (
              <SwatchImageBoxContainer src={value.imageFileInput && value.imageFileInput.src}/>
              )
            }
            <EditSwatchButtonContainer onClick={() => {setSelectedComponent(value.title);toggleWhichSwatchIsOpen()}}>
              {'Edit Components'}
            </EditSwatchButtonContainer>
          </EditSwatchContainerV2>
        </EditSwatchesContainer>
      </IndividualColorSwatchContainer>
    </>
  );
};

const ColorComponentInput = ({ 
   selectedComponent, setSelectedComponent, 
   colorComponentTitles, setColorComponentTitles,
   colorComponents, setColorComponents, 
  }) => {
    
  const [whichSwatchIsOpen, setWhichSwatchIsOpen] = useState(null); 
  const [modalPosition, setModalPosition] = useState({x:0, y:0}); 

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(colorComponentTitles);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setColorComponentTitles(items);
  };

  const setNewSwatches = (newSwatches, currentlySelectedSwatch, newTitle=null) => {
    setColorComponents(prev => {
      const copy = JSON.parse(JSON.stringify(prev))
      copy[selectedComponent].swatches = newSwatches;

      const swatchToChange = currentlySelectedSwatch ? currentlySelectedSwatch : 'swatch1'; 
      copy[selectedComponent].hex = copy[selectedComponent].swatches[swatchToChange].hex; 
      copy[selectedComponent].imageFileInput = copy[selectedComponent].swatches[swatchToChange].imageFileInput;
      copy[selectedComponent].isColorMostRecentlySelected = copy[selectedComponent].swatches[swatchToChange].isColorMostRecentlySelected;
      if (newTitle) {
        copy[selectedComponent].color = newTitle;
      };
      return copy
    });
  };

  return (
    <ColorComponentInputContainer>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="swatches">
          {(provided) => (
            <ul 
              className="swatches"
              {...provided.droppableProps} 
              ref={provided.innerRef}
            >
              {colorComponentTitles.filter(title => title !== 'Add another').map((title, index) => (
                <> 
                {/* Legend #6 */}
                    <Draggable key={title} draggableId={title} index={index}>
                      {(provided) => (
                        <li 
                          ref={provided.innerRef} 
                          {...provided.draggableProps} 
                          {...provided.dragHandleProps}
                        >
                          <IndividualColorComponent
                            value={colorComponents[title]}
                            id={index + 1}
                            key={title}
                            setSelectedComponent={setSelectedComponent}
                            whichSwatchIsOpen={whichSwatchIsOpen}
                            setWhichSwatchIsOpen={(which, position) => {
                              setWhichSwatchIsOpen(which);
                              setModalPosition(position); 
                            }}
                          />
                        </li>
                      )}
                    </Draggable>
                </>
                ))}
                <IndividualColorComponent
                  key={'Add another'}
                  value={colorComponents['Add another']}
                  id={0}
                  setSelectedComponent={setSelectedComponent}
                  whichSwatchIsOpen={whichSwatchIsOpen}
                  setWhichSwatchIsOpen={() => {}}
                />
              {provided.placeholder}
            </ul>
            )}
        </Droppable>
      </DragDropContext>
      {whichSwatchIsOpen && (
        // Legend #7
        <EditComponentModal 
          position={modalPosition}
          value={colorComponents[selectedComponent].swatches}
          onChange={setNewSwatches}
          swatchTitles={colorComponents[selectedComponent].swatchTitles}
          setWhichSwatchIsOpen={setWhichSwatchIsOpen}
          selectedComponentTitle={colorComponents[selectedComponent].color}
        />
      )}
    </ColorComponentInputContainer>
  );
};

export default ColorComponentInput;















