import { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import ToggleInputWithLabel from '../inputs/ToggleInputWithLabel';
import TextInput from '../inputs/TextInput';
import { PanelOptionHeading } from './shared';
import { AppContext } from '../../../App';
import DropDownInput from '../inputs/DropDownInput';
import { getScaleSize, handleMeasurementUnit, resetScene, sizeObjectForComparison, updateBoundingBox } from '../../../threejs/comparisonClasses/measurementHelpers';

const PanelLayout = styled.div`
  overflow: hidden;
`;

const TextInputContainer = styled.div `
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  margin-top: 1rem;
`

const ContainerItem = styled.div `
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  
   > div  {
     width: 3rem;
    > input { 
      width: 2rem;
    }
   }
`

const SaveContainer = styled.div `
  padding: 0 2rem 2rem 2rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  margin-top: 8rem
`

const Button = styled.div`
  border: 1px solid #072B62;
  box-sizing: border-box;
  border-radius: 3px;
  height: 1remp; 
  line-height: 28px;
  text-align: center;
  font-size: 0.7em;
  color: #072B62;
  cursor: pointer;
  width: 80%;
  margin: auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; 
  user-select: none;
  transition: all 0.25s;
  &:hover {
    background: rgba(7, 43, 98, 0.5);
  }

  &:focus {
    background: rgba(211, 216, 222, 1);
  }

  &:active {
    background: rgba(231, 234, 240, 1);
  }

`;

const SaveButton = styled.div`
box-sizing: border-box;
border-radius: 5px;
padding: 8px 12px;
text-align: center;
cursor: pointer;
transition: all 0.25s;
background-color: #601818;
color: #fff;
pointer-events: none;
opacity: 0.5;
${({ enabled }) => enabled && `
  pointer-events: all;
  opacity: 1;
  &:hover {
    background: rgba(96, 24, 24, 1);
  }
  
  &:focus {
    background: rgba(96, 24, 24, 1);
  }
  
  &:active {
   background: rgba(195, 177, 177, 1);
  }
  `}

`;

const MeasurementAndComparisonPanel = () => {
  const { measurementUnit, setMeasurementUnit, enableMeasurement, setEnableMeausrement, setShowMeasurements, objMeasurements, setObjMeasurements, comparisonObjects, setComparisonObjects, context } = useContext(AppContext);
  // State
  const measurementUnits = ['Metric (cm)', 'Imperial (inches)'];
  const [measurements, setMeasurements] = useState({
    ...objMeasurements.measurements
  });
  // const [previousMeasurements, setPreviousMeasurements] = useState({
  //   length: 30,
  //   width: 12,
  //   height: 16
  // });
  const [compareisonObjectNames, setComparisonObjectNames] = useState([]);
  const [update, setUpdate] = useState(false);
  const [activeComponent, setActiveComponent] = useState({name: 'None'});


  // Run on load
  useEffect(() => {
    setComparisonObjectNames(comparisonObjects.map(item => item.name));
    const enabledIndex = comparisonObjects.findIndex(item => item.enabled === true);
    setActiveComponent(comparisonObjects[enabledIndex]);
  }, []);

  // TODO: Notes: Needs refactoring
  // useEffect(() => {
  //   setMeasurements({...measurements, ...objMeasurements});
  // }, [objMeasurements]);

  // Refactor to use useReducer
  useEffect(() => {
    context.dimensionLines.forEach((item) => {
      item.updateLabels(measurements.width, measurements.length, measurements.height, objMeasurements.unit)
    });
    setObjMeasurements({...objMeasurements, measurements:{...measurements}})
  }, [measurements, setObjMeasurements, measurements.width, measurements.height, measurements.length]);

  // Input Handlers
  const handleUnitChange = (e) => {
    const unit = e.includes('(cm)') ? 'cm' : 'in.'
    handleMeasurementUnit(unit, measurements, setMeasurements, setMeasurementUnit);
    context.dimensionLines.forEach((item) => {
      item.updateLabels(measurements.width, measurements.length, measurements.height, objMeasurements.unit);
    });
  }

  const handleEnableMeasurement = (e) => {
    setEnableMeausrement(e);

    const scene = context.scene;
    const mainMesh =  scene.getObjectByName('model_main');
    setShowMeasurements(false);

    resetScene(context.comparisonGroup, mainMesh, objMeasurements);  

    if(document.querySelector('.labelRenderer')) {
      document.querySelector('.labelRenderer').style.display = 'none';
    }

    const dimensionGroup = scene.getObjectByName('dimensionGroup');
    if(dimensionGroup) {
      dimensionGroup.visible = false;
    }
  }

  const compareToggleHandler = (name) => {
    let objects = comparisonObjects;
    const scene = context.scene;
    const mainMesh =  scene.getObjectByName('model_main');

    if(name === 'None') {
      resetScene(context.comparisonGroup, mainMesh, objMeasurements);  
    }

    const oldActiveIndex = comparisonObjects.findIndex(item => item.name === activeComponent.name);
    activeComponent.enabled = !activeComponent.enabled;
    objects[oldActiveIndex] = activeComponent;
    
    
    const newActiveIndex = comparisonObjects.findIndex(item => item.name === name);
    setActiveComponent(comparisonObjects[newActiveIndex])
    let item = comparisonObjects[newActiveIndex];
    item.enabled = !item.enabled;
    objects[newActiveIndex] = item; 
    setComparisonObjects([...objects]);

    // TODO @NOTE Brandon: Needs refactoring
    if(context.comparisonGroup.currentlyViewed && context.comparisonGroup.currentlyViewed.visible) {
      if(!context.comparisonGroup.find(item.name)) {
        return context.comparisonGroup.addToGroup(item);
      } else if(context.comparisonGroup.currentlyViewed.name !== item.name) {
        context.comparisonGroup.hideObject(context.comparisonGroup.currentlyViewed);
        return context.comparisonGroup.changeCurrentlyViewed(context.comparisonGroup.find(item.name));
      }
    }
  }

  const resetHandler = () => {
    setMeasurements({ ...objMeasurements.defaultMeasurement });
    if(context.comparisonGroup.currentlyViewed && context.comparisonGroup.currentlyViewed.visible ) {
      const mainMesh =  context.scene.getObjectByName('model_root');

      sizeObjectForComparison(mainMesh.parent, getScaleSize(objMeasurements.defaultMeasurement));
      updateBoundingBox(mainMesh.parent.position, context.sceneCamera);
    }
  }

  const updateHandler = () => {
    if(update && context.comparisonGroup.currentlyViewed && context.comparisonGroup.currentlyViewed.visible ) {
      const mainMesh =  context.scene.getObjectByName('model_root');

      sizeObjectForComparison(mainMesh.parent, getScaleSize(measurements));

      setTimeout(() => {
        updateBoundingBox(mainMesh.parent.position, context.sceneCamera);
        setTimeout(() => {
          context.comparisonGroup.repositionMain();
        }, 250);
      }, 500);    
    }
  }

  // * TO BE RE-IMPLEMENTED LATER
  const saveHandler = () => {
    // setPreviousMeasurements({
    //   length: objMeasurements.length,
    //   width: objMeasurements.width,
    //   height: objMeasurements.height
    // })
    setObjMeasurements({...objMeasurements, ...measurements });
    setUpdate(false);
  }

  // * TO BE RE-IMPLEMENTED LATER
  // const redoUndoHandler = () => {
  //   const prev = measurements;
  //   setMeasurements({
  //     ...previousMeasurements
  //   });
  //   setPreviousMeasurements({...prev});
  // }
  
  return (
    <PanelLayout>
      <ToggleInputWithLabel label="Enable Feature" value={enableMeasurement} onChange={(e) => handleEnableMeasurement(e)} />
      <br></br>
      <PanelOptionHeading variation={2}>Measurement</PanelOptionHeading>
      <DropDownInput active={measurementUnit} items={measurementUnits} setItem={(e) => handleUnitChange(e)}/>
      <TextInputContainer>
        <ContainerItem>
          <span style={{marginRight: '0.25rem'}}>Width:</span>
          <TextInput 
            value={measurements.width} 
            type='number' 
            onChange={(e) => {setUpdate(true); setMeasurements({...measurements, width: Number(e)})}} 
          />
        </ContainerItem>
        <ContainerItem >
          <span>Height:</span> 
          <TextInput 
            value={measurements.height} 
            type='number' 
            onChange={(e) => {setUpdate(true); setMeasurements({...measurements, height: Number(e)})}}
          />
        </ContainerItem>
        <ContainerItem>
          <span>Length:</span> 
          <TextInput 
            value={measurements.length} 
            type='number' 
            onChange={(e) => {setUpdate(true); setMeasurements({...measurements, length: Number(e)})}}
          />
        </ContainerItem>
      </TextInputContainer>
      <Button enabled onClick={resetHandler}>Default Measurement</Button>
      <br></br>
      <PanelOptionHeading variation={2}>Comparison Object</PanelOptionHeading>
      <DropDownInput active={activeComponent.name} items={compareisonObjectNames} setItem={(e) => compareToggleHandler(e)}/>
      <Button onClick={updateHandler} style={{marginTop: '1rem'}}>Update Object Ratio</Button>

      <SaveContainer>
        <SaveButton enabled={update} onClick={saveHandler}>Save</SaveButton>
      </SaveContainer>
    </PanelLayout>
  )
}

export default MeasurementAndComparisonPanel