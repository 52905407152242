import { useState } from "react";
import { ModalContainerV2 } from "./shared";
import styled from "styled-components";
import * as THREE from "three";

const CurrentCustomerViewContainer = styled.div`
  ${({ isSceneEditor }) => !isSceneEditor && `display: none;`};
  color: #000000;
  box-sizing: border-box;
  width: ${(clientWidth) => clientWidth}px !important;
  height: ${(clientHeight) => clientHeight}px !important;
`;

const TextLineContainer = styled.div`
  font-size: 20px;
  font-weight: 600px;
  text-align: center;
  margin-top: 12px;
  text-align: left;
  padding-left: 1rem;
`;
const TopSectionContainer = styled.div`
  backdrop-filter: blur(20.51px);
  border: 1px solid rgba(255, 255, 255, 0.5);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
`;

const DisplayContainer = styled.div`
  display: flex;
  margin-top: 12px;
  width: 300px;
`;

const CurrentCustomerViewWebGLContainer = styled.div`
  box-sizing: border-box;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  align-self: center;
  position: "fixed";
  width: 1000px;
  canvas {
    ${({
      selectedBackgroundColor,
      selectedBackgroundType,
      selectedBackgroundPosition,
    }) => {
      if (selectedBackgroundType === "Linear") {
        return `background: linear-gradient(${selectedBackgroundColor} ${selectedBackgroundPosition.height}%, #ffffff) !important;`;
      } else if (selectedBackgroundType === "Solid") {
        return `background: ${selectedBackgroundColor}`;
      } else if (selectedBackgroundType === "Radial") {
        return `background: radial-gradient(at ${selectedBackgroundPosition.width}% ${selectedBackgroundPosition.height}%, ${selectedBackgroundColor}, rgba(0,0,0,0));`;
      }
    }};
  }
`;

const MiddleSectionContainer = styled.div`
  /* background: linear-gradient(260.87deg, rgba(255, 255, 255, 0.3) 48.28%, rgba(255, 255, 255, 0) 122.41%); */
  box-sizing: border-box;
  backdrop-filter: blur(20.51px);
  border: 1px solid rgba(255, 255, 255, 0.5);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin-top: 12px;
  border-radius: 5px;
`;

const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  > div:nth-child(2) {
    display: ${({ shouldShowDropDown }) =>
      shouldShowDropDown ? "block" : "none"};
  }
`;

const SelectedViewContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  border-bottom: ${({ shouldShowDropDown }) =>
    shouldShowDropDown ? "1px solid #E8E8E8" : "none"};
  /* padding: 12px 16px 8px; */
  border-radius: 5px;
  padding: 12px;
  justify-content: space-between;
  .selected-view {
    /* text-align: center; */
    padding-bottom: 2px;
  }
  &:hover {
    background: rgba(207, 207, 207, 0.8);
  }
  &:focus,
  &:active {
    background: #d3d8de;
  }
  cursor: pointer;
  > * + * {
    padding-left: 16px;
  }
  .arrows-container {
    color: #6d6d6d;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2px;
    .arrow-up {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #c4c4c4;
    }
    .arrow-down {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #c4c4c4;
    }
  }
`;

const DropDownListContainer = styled.div`
  /* text-align: center; */
  overflow: auto;
  max-height: 124px;
  > div:last-child {
    border-bottom: none;
  }
`;

const DropDownListItemContainer = styled.div`
  background: linear-gradient(
    260.87deg,
    rgba(255, 255, 255, 0.3) 48.28%,
    rgba(255, 255, 255, 0) 122.41%
  );
  border-radius: 0px 0px 5px 5px;
  /* padding: 12px 16px 8px; */
  padding: 12px;
  &:hover {
    background: rgba(207, 207, 207, 0.8);
  }
`;

const BottomSectionContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  margin-top: 12px;
  > * + * {
    margin-left: 12px;
  }
  > div {
    flex: 1;
    box-sizing: border-box;
    padding: 12px 12px 8px 12px;
    border: 1px solid #d2d2d2;
    background: #b0acac;
    background: rgba(176, 172, 172, 0.8);
    border-radius: 5px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      background: rgba(207, 207, 207, 0.8);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
  .delete-view {
    backdrop-filter: blur(20.51px);
    border: 1px solid rgba(255, 255, 255, 0.5);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    box-sizing: border-box;
    background: none;
    display: flex;
    justify-content: flex-start;
    white-space: nowrap;
    > * + * {
      padding-left: 8px;
      padding-top: 2px;
      box-sizing: border-box;
    }
  }
  .save-view {
    backdrop-filter: blur(20.51px);
    border: 1px solid rgba(255, 255, 255, 0.5);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background: none;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    > * + * {
      padding-left: 12px;
      padding-top: 2px;
      box-sizing: border-box;
    }
    white-space: nowrap;
  }
`;

const ConfirmationPopupContainer = styled.div`
  visibility: ${({ shouldConfirmationPopup }) =>
    shouldConfirmationPopup ? "visible" : "hidden"};
  opacity: ${({ shouldConfirmationPopup }) =>
    shouldConfirmationPopup ? "1" : "0"};
  transition: ${({ shouldConfirmationPopup }) =>
    shouldConfirmationPopup
      ? "opacity 1s ease-out"
      : "visibility 0s 1s, opacity 1s ease-out"};
  position: fixed;
  background: linear-gradient(
    112.91deg,
    rgba(255, 255, 255, 0.255) 3.51%,
    rgba(255, 255, 255, 0) 111.71%
  );
  filter: drop-shadow(14.6469px 17.5762px 29.2937px rgba(0, 0, 0, 0.05));
  backdrop-filter: blur(20.5056px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 10px;
  > div {
    margin: 24px 196px;
    color: #838383;
    font-weight: 500;
    font-size: 24px;
  }
  bottom: 4px;
  left: 524px;
`;

const CurrentCustomerView = ({
  context,
  viewsTitles,
  setViewsTitles,
  selectedView,
  setSelectedView,
  views,
  setViews,
  currentCustomerViewWebGLContainerRef,
  selectedBackgroundColor,
  selectedBackgroundType,
  selectedBackgroundPosition,
}) => {
  const [shouldShowDropDown, setShouldShowDropDown] = useState(false);
  const [shouldConfirmationPopup, setShouldShowConfirmationPopup] =
    useState(false);
  const [saveAndDeletePopupText, setSaveAndDeletePopupText] = useState(null);
  const { takeSnapShot } = context;
  context.currentSnapShot = views[selectedView].dataURL;
  context.selectedView = selectedView;
  // @note: Lewis - find alternative solution to setTimeout
  let transitionTimeout = null;
  const popUpMessageHandler = (viewTitle, save = true) => {
    const savedOrDeleted = save ? "saved" : "deleted";
    setSaveAndDeletePopupText(`${viewTitle} is successfully ${savedOrDeleted}`);
    setShouldShowConfirmationPopup(true);
    clearTimeout(transitionTimeout);
    transitionTimeout = setTimeout(() => {
      setShouldShowConfirmationPopup(false);
    }, 2000);
  };

  const saveViewHandler = () => {
    const { dataURL, snapShotCameraPosition } = takeSnapShot();
    const newViewId = viewsTitles.length;
    const newViewTitle = `View ${newViewId}`;
    const newView = {
      id: newViewId,
      title: newViewTitle,
      dataURL: dataURL,
      cameraPosition: snapShotCameraPosition,
    };

    context.currentCustomerViewCamera.position.copy(snapShotCameraPosition);
    context.currentCustomerViewCamera.lookAt(new THREE.Vector3(0, 0, 0));

    setViewsTitles((prev) => {
      const copy = [...prev];
      copy.push(newViewTitle);
      return copy;
    });
    setViews((prev) => {
      const copy = { ...prev };
      copy[newViewTitle] = newView;
      return copy;
    });
    setSelectedView(newViewTitle);
    popUpMessageHandler(newViewTitle);
  };

  const deleteViewHandler = () => {
    // @note: Lewis - Cannot delete default view
    if (selectedView === "Default View") return;
    const toBeDeletedViewTitleIndex = viewsTitles.indexOf(selectedView);
    const toBeDeletedViewTitle = viewsTitles[toBeDeletedViewTitleIndex];
    viewsTitles.splice(toBeDeletedViewTitleIndex, 1);
    delete views[selectedView];
    setSelectedView("Default View");
    popUpMessageHandler(toBeDeletedViewTitle, false);
  };

  return (
    <CurrentCustomerViewContainer
      isSceneEditor={context.selectedPanelForClientSide === "scene-editor"}
    >
      <ModalContainerV2
        clientWidth={Math.max(296, context.width * 0.2)}
        clientHeight={Math.max(296, context.height * 0.2)}
      >
        <TopSectionContainer>
          <TextLineContainer>{"Current Customer View"}</TextLineContainer>
          <DisplayContainer>
            <CurrentCustomerViewWebGLContainer
              ref={currentCustomerViewWebGLContainerRef}
              id='currentCustomerViewWebGLContainerRef'
              selectedBackgroundColor={selectedBackgroundColor}
              selectedBackgroundType={selectedBackgroundType}
              selectedBackgroundPosition={selectedBackgroundPosition}
            />
          </DisplayContainer>
        </TopSectionContainer>
        <MiddleSectionContainer>
          <DropDownContainer
            shouldShowDropDown={shouldShowDropDown}
            onClick={() => setShouldShowDropDown(!shouldShowDropDown)}
          >
            <SelectedViewContainer shouldShowDropDown={shouldShowDropDown}>
              <div className='selected-view'>{selectedView}</div>
              <div className='arrows-container'>
                <div className='arrow-up'></div>
                <div className='arrow-down'></div>
              </div>
            </SelectedViewContainer>
            {shouldShowDropDown && (
              <DropDownListContainer tabIndex='1'>
                {viewsTitles.map((view, index) => (
                  <DropDownListItemContainer
                    id={index}
                    key={index}
                    onClick={() => {
                      setSelectedView(view);
                      setShouldShowDropDown(false);
                      context.currentCustomerViewCamera.position.copy(
                        views[view].cameraPosition
                      );
                      context.currentCustomerViewCamera.lookAt(0, 0, 0);
                    }}
                  >
                    {view}
                  </DropDownListItemContainer>
                ))}
              </DropDownListContainer>
            )}
          </DropDownContainer>
        </MiddleSectionContainer>
        <BottomSectionContainer>
          <div
            className='save-view'
            onClick={() => {
              saveViewHandler();
              setShouldShowDropDown(false);
            }}
          >
            <div>
              <img
                style={{
                  width: "24px",
                  height: "24px",
                  boxSizing: "border-box",
                }}
                src='images/icons/scene-editor-objects/camera.svg'
                alt=''
              />
            </div>
            <div>{"Save View"}</div>
          </div>

          <div
            className='delete-view'
            onClick={() => {
              deleteViewHandler();
              setShouldShowDropDown(false);
            }}
          >
            <div>
              <img
                style={{
                  width: "24px",
                  height: "24px",
                  boxSizing: "border-box",
                }}
                src='images/icons/client-panel/delete_icon.svg'
                alt=''
              />
            </div>
            <div>{"Delete View"}</div>
          </div>
        </BottomSectionContainer>
        <ConfirmationPopupContainer
          shouldConfirmationPopup={shouldConfirmationPopup}
        >
          <div>{saveAndDeletePopupText}</div>
        </ConfirmationPopupContainer>
      </ModalContainerV2>
    </CurrentCustomerViewContainer>
  );
};

export default CurrentCustomerView;
