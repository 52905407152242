import styled from 'styled-components';

import { CustomPicker } from "react-color";
import {
  EditableInput,
  Hue,
  Saturation
} from "react-color/lib/components/common";
import { CancelAndSaveButtonsContainer, ModalContainer, OptionButtonContainer } from './shared';

/* 
 Only Used in the colorpicker input
 react-color package is used to get the HEX code colour picker
*/

const ColorPickerContainer = styled.div`
  > .saturation {
	  margin-top: 15px;
	  width: 100%;
	  height: 308px;
	  position: relative;
  }
  > .hue {
	  height: 14px;
	  position: relative;
	  margin: 10px 10px 10px 11px;
	  .hue-horizontal {
		  border-radius: 10px;
		  > div > div {
			  width: 14px !important;
			  height: 14px !important;
			  border-radius: 7px !important;
			  margin-top: 0 !important;
			  cursor: pointer;
			  border: 2px solid #fff;
			  box-sizing: border-box;
			  background: none !important;
		  };
	  };
  };
  > .inputContainer {
	  display: flex;
	  justify-content: space-between;
	  box-sizing: border-box;
	  font-size: 11px;
	  margin-bottom: 24px;
	  line-height: 1.5;
	  padding: 0 10px;
	  align-items: center;
	  > .labelText {
			padding-left: 12px;
		};
	  > .inputAndPercentContainer {
		  display: flex;
		  box-sizing: border-box;
		  border: 1px solid ${({hex}) => hex};
		  input {
			  border: none;
			  border-right: 1px solid rgba(0, 0, 0, 0.125);
			  outline: none;
			  height: 100%;
			  box-sizing: border-box;
			  padding: 4px 6px;
		  };
		  > .percent {
			  display: flex;
			  flex-direction: column;
			  justify-content: center;
			  > p {
				  margin: 0;
				  padding: 0 6px;
				};
		};
	};
  };
`;

export const ColorPicker = (props) => {
  const  { hex, setColor, onChange, labelText, onCancel, onSave } = props;

	const handleColorChange = (val) => {
		setColor(val);
		onChange(hex);
	};
  return (
	<ModalContainer>
		<ColorPickerContainer hex={hex}>
			<div className='saturation'>
				<Saturation 
				{...props}
				onChange={handleColorChange}
				/>
			</div>
			<div className='hue'>
				<Hue
					{...props}
					onChange={handleColorChange}/>
			</div>
			<div className='inputContainer'>
				<div className='labelText'>{labelText}</div>
				<div className='inputAndPercentContainer'>
					<EditableInput
					value={hex}
					onChange={handleColorChange}
					/>
					<div className='percent'>
						<p>100%</p>
					</div>
				</div>
			</div>
		</ColorPickerContainer>
		<CancelAndSaveButtonsContainer>
      <OptionButtonContainer onClick={() => onCancel(false)}>{'Cancel'}</OptionButtonContainer>
      <OptionButtonContainer onClick={onSave}>{'Save Changes'}</OptionButtonContainer>
			{/* @todo: implement save/cancel */}    
			</CancelAndSaveButtonsContainer>
	</ModalContainer>
  );
};

export default CustomPicker(ColorPicker);
