import React from "react";
import styled from "@emotion/styled";
import { dynamicFontWeight, PrimaryColours, SecondaryColours } from "./shared";

const Button = styled.button`
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  width: 100%;
  ${dynamicFontWeight}
  ${({ primary }) => primary && PrimaryColours}
  ${({ secondary }) => secondary && SecondaryColours}
`;

const ButtonBase = (props) => {
  return (
    <Button
      primary={props.primary}
      secondary={props.secondary}
      fontWeight={props.fontWeight || 400}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};

export default ButtonBase;
