import * as THREE from 'three';

export const setUpLights = (lightingGroup, { primaryDirectionalLightContainer }) => {
  // @todo: may investigate PCSS later for proper soft shadows.
  // DEMO: https://threejs.org/examples/?q=shado#webgl_shadowmap_pcss
  // CODE: https://github.com/mrdoob/three.js/blob/master/examples/webgl_shadowmap_pcss.html
  {
    primaryDirectionalLightContainer.light = new THREE.DirectionalLight( 0xffffff, 1 / 2 );
    const { light } = primaryDirectionalLightContainer; 
    light.position.set(0, 8, 0);
    light.castShadow = true;
    lightingGroup.add(light);

    light.shadow.mapSize.width = 512;
    light.shadow.mapSize.height = 512;
    light.shadow.camera.near = 0.5;
    light.shadow.camera.far = 500;
    // const light = primaryDirectionalLight; // @todo: check, is this a pointer?
    // light.position.set(0, 8, 0);
    // light.castShadow = true;
    // lightingGroup.add(light);

    // light.shadow.mapSize.width = 512;
    // light.shadow.mapSize.height = 512;
    // light.shadow.camera.near = 0.5;
    // light.shadow.camera.far = 500;
  }
  
  // ..

  {
    const light = new THREE.PointLight(0xffffff, 0.125 / 4, 100);
    light.position.set(-4, 4, 4);
    light.layers.set(10);
    lightingGroup.add(light);
  }

  {
    const light = new THREE.PointLight(0xffffff, 0.125 / 4, 100);
    light.position.set(4, 4, 4);
    light.layers.set(10);
    lightingGroup.add(light);
  }
  
  {
    const light = new THREE.PointLight(0xffffff, 0.125 / 4, 100);
    light.position.set(-4, 4, -4);
    light.layers.set(10);
    lightingGroup.add(light);
  }
  
  {
    const light = new THREE.PointLight(0xffffff, 0.125 / 4, 100);
    light.position.set(4, 4, -4);
    light.layers.set(10);
    lightingGroup.add(light);
  }
  
  // ..
  
  {
    const light = new THREE.PointLight(0xffffff, 0.125 / 4, 100);
    light.position.set(-4, -4, 4);
    light.layers.set(10);
    lightingGroup.add(light);
  }
  
  {
    const light = new THREE.PointLight(0xffffff, 0.125 / 4, 100);
    light.position.set(4, -4, 4);
    light.layers.set(10);
    lightingGroup.add(light);
  }
  
  {
    const light = new THREE.PointLight(0xffffff, 0.125 / 4, 100);
    light.position.set(-4, -4, -4);
    light.layers.set(10);
    lightingGroup.add(light);
  }
  
  {
    const light = new THREE.PointLight(0xffffff, 0.125 / 4, 100);
    light.position.set(4, -4, -4);
    light.layers.set(10);
    lightingGroup.add(light);
  }
}