import { useState } from 'react';
import styled from 'styled-components';

const SelectInputContainer = styled.div`
  position: relative;
  border: 1px solid #7688a3;
`;

const SelectedOptionContainer = styled.div`
  cursor: pointer;
  background-color: #fff;
  line-height: 40px;
  padding: 0 24px;
  color: #999;
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > p {
    margin: 0;
  }
  > svg {
    margin-top: 2px;
  }
`;

const OptionsContainer = styled.div`
  position: absolute;
  border: 1px solid #7688a3;
  border-top: 0;
  margin-left: -1px;
`;

const SelectOptionContainer = styled.div`
  cursor: pointer;
  background-color: #fff;
  line-height: 40px;
  padding: 0 24px;
  color: #444;
  border-top: 1px solid #7688a3;
  width: 240px;
  &:hover {
    background-color: #7688a3;
    color: #fff;
  }
  > p {
    margin: 0;
  }
`;

const SelectedOption = ({ option, onClick }) => {
  return (
    <SelectedOptionContainer onClick={onClick}>
      <p>{option.label}</p>
      <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.5 9.11719L0.272757 0.745094L16.7272 0.745095L8.5 9.11719Z" fill="#072B62"/>
      </svg>
    </SelectedOptionContainer>
  );
};

const SelectOption = ({ option, onSelect }) => {
  return (
    <SelectOptionContainer onClick={() => onSelect(option)}>
      <p>{option.label}</p>
    </SelectOptionContainer>
  );
};

const SelectInput = ({ className, options, value, onChange }) => {
  const [shouldShowOptions, setShouldShowOptions] = useState(false);
  return (
    <SelectInputContainer className={className}>
      <SelectedOption
        option={options.find(o => o.value === value)}
        onClick={() => setShouldShowOptions(!shouldShowOptions)}
      />
      <OptionsContainer>
        {shouldShowOptions && options.map((option, optionIndex) => (
          <SelectOption
            key={optionIndex}
            option={option}
            onSelect={(newOption) => {
              onChange(newOption.value);
              setShouldShowOptions(false);
            }}
          />
        ))}
      </OptionsContainer>
    </SelectInputContainer>
  );
};

export default SelectInput;

