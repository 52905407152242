import styled from "styled-components";
import ToggleInput from "./ToggleInput";

const ToggleInputWithLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
`;

const Label = styled.h3`
  font-weight: 600;
  color: #072b62;
`;

const ToggleInputWithLabel = ({
  className,
  label,
  value,
  onChange,
  onClick,
}) => {
  return (
    <ToggleInputWithLabelContainer className={className} onClick={onClick}>
      <Label>{label}</Label>
      <ToggleInput value={value} onChange={onChange} />
    </ToggleInputWithLabelContainer>
  );
};

export default ToggleInputWithLabel;
