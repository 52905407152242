import { useEffect, useRef, useState, createContext } from 'react';
import styled from 'styled-components';
import useResizeObserver from 'use-resize-observer';
import * as THREE from 'three';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';
import { initializeScene } from './threejs';
import config from './config';

import ClientOptions from './components/ClientOptions';
import CustomerOptions from './components/CustomerOptions';
import PreviewBar from './components/PreviewBar';

import CurrentCustomerView from './components/client/modals/CurrentCustomerView';
import { loadSceneObjects, switchTransformControlsToSelectedObject } from './threejs/helpers/scene-editor/dragAndDropObjects';
import LogoDisplay from './components/client/modals/LogoDisplay';
import InteractionPrompt from './components/ui/InteractionPrompt';
import HotspotOverlay from './components/ui/HotspotOverlay';
import queryString from 'query-string'

const AppContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  ${({ isPreviewMode }) => isPreviewMode && `
    flex-direction: column;
  `}
`;

const PreviewScreenImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  pointer-events: none;
`;

const PreviewScreenImage = styled.img`
  display: block;
  width: 590px;
  margin-left: -3px;
  margin-top: 96px;
`;

const WebGLWrapperParentContainer = styled.div`
  flex: 1;
  width: ${({ isClientSide }) => isClientSide ? `calc(100% - 320px)` : `100%`};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  ${({ isPreviewMode }) => isPreviewMode && `
    flex: initial;
    height: calc(100% - 64px);
  `}
`;


// @note: iPhone X dimensions.
const mobileWidth = 375;
const mobileHeight = 812;

const isCanvasSizeNumeric = canvasSize => (canvasSize.x !== 'Auto' && canvasSize.y !== 'Auto');

const WebGLWrapperContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  ${({ isPreviewMode, canvasSize, previewType }) => isPreviewMode ? `
    border-radius: 16px;
    ${(previewType === 'desktop-fitted-canvas' && isCanvasSizeNumeric(canvasSize)) ? `
      width: ${canvasSize.x}px;
      height: ${canvasSize.y}px;
    ` : (previewType === 'desktop-full-screen') ? `
      // ..
    ` : ((previewType === 'mobile-full-screen') || (previewType === 'mobile-fitted-canvas' && !isCanvasSizeNumeric(canvasSize))) ? `
      width: ${mobileWidth}px;
      height: ${mobileHeight}px;
    ` : (previewType === 'mobile-fitted-canvas' && isCanvasSizeNumeric(canvasSize)) ? `
      width: ${mobileWidth}px;
      height: ${(canvasSize.y / canvasSize.x) * mobileWidth}px;
      border-radius: 0px;
    ` : ''}
    overflow: hidden;
    align-self: center;
  ` : ''}
`;

const WebGLContainer = styled.div`
  width: ${({ isCustomerOptionsPanelOpen }) => isCustomerOptionsPanelOpen ? `calc(100% - 240px)` : `100%`};
  height: 100%;
  @media (max-width: 1023px) {
    width: 100%;
    height: 100%;
  }
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
  canvas {
  ${({ selectedBackgroundColor, selectedBackgroundType, selectedBackgroundPosition }) => {
     if (selectedBackgroundType === 'Linear') {
        return `background: linear-gradient(${selectedBackgroundColor} ${(selectedBackgroundPosition.height)}%, rgba(0,0,0,0));`;
      } else if (selectedBackgroundType === 'Solid') {
        return `background: ${selectedBackgroundColor}`; 
      } else if (selectedBackgroundType === 'Radial') {
        return `background: radial-gradient(at ${selectedBackgroundPosition.width}% ${selectedBackgroundPosition.height}%, ${selectedBackgroundColor}, rgba(0,0,0,0));`; 
      }
    }};
  }
`;

const CanvasOverlayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const OrbitBallWebGLContainer = styled.div`
  position: absolute;
  z-index: 5;
  /* right: 32px;
  bottom: 32px; */
  right: calc(32px - 3.125%);
  bottom: calc(32px - 3.125%);
  ${({ isVisible }) => !isVisible && `
    display: none;
  `}
`;

export const AppContext = createContext({});

const App = ({ publicConfig }) => {
  const [appMode, setAppMode] = useState(null);
  const webGLWrapperContainerRef = useRef(null);
  const wrapperDimensions = useResizeObserver({ ref: webGLWrapperContainerRef }); // @todo: maybe get width & height directly?
  const webGLContainerRef = useRef(null);

  // @todo.
  // const orbitBallWrapperDimensions = useResizeObserver({ ref: orbitBallWebGLWrapperContainerRef }); // @todo: maybe get width & height directly?
  const orbitBallWebGLContainerRef = useRef(null);

  // @note: Lewis - this is for current customer view
  const currentCustomerViewWebGLContainerRef = useRef(null); 
  
  const didToggleAutoRotationOffManually = useRef(false);
  const rotateModelTimeoutRef = useRef(null);
  const [isCustomerOptionsPanelOpen, setIsCustomerOptionsPanelOpen] = useState(false);

  const appRef = useRef({
    // handleContainerResize: null,
    shouldRotateModel: publicConfig.params.shouldRotateSceneOnLaunch, // @todo: rethink init?
  });
  const app = appRef.current;

  // @note - Lewis: moved to top level because I need this to be exported to TransformControls.js
  const contextRef = useRef({
    // @todo: rethink: listing here all the properties (for clarity).
    // camera,
    // cubeTextureloader,
    // environmentMap,
    // lightingGroup,
    // modelRotationSpeed,
    // modelWrapperGroup,
    // primaryDirectionalLight,
    // scene,
    // shouldAutoRotateModel,
    // productShadowBlur,
    // productShadowIntensity
    primaryDirectionalLightContainer: {},
  });
  const context = contextRef.current; // @note: helper variable.

  // Default client side set to null -> nothing open
  const [selectedPanelForClientSide, setSelectedPanelForClientSide] = useState(null);

  // General Panel
  const [shouldEnableInteractionPrompt, setShouldEnableInteractionPrompt] = useState(config.defaults.shouldEnableInteractionPrompt);
  const [shouldAutoRotateModel, setShouldAutoRotateModel] = useState(config.defaults.shouldAutoRotateModel);
  const [pauseAutoRotateModel, setPauseAutoRotateModel] = useState(false);
  const [modelRotationSpeed, setModelRotationSpeed] = useState(config.defaults.modelRotationSpeed);
  const [productShadowIntensity, setProductShadowIntensity] = useState(config.defaults.productShadowIntensity);
  const [productShadowBlur, setProductShadowBlur] = useState(config.defaults.productShadowBlur);
  const [canvasSize, setCanvasSize] = useState(config.defaults.canvasSize);

  // Scene Panel
  // const [buttonOutlineColor, setButtonOutlineColor] = useState(config.defaults.buttonOutlineColor);
  const [logoImageFile, setLogoImageFile] = useState(config.defaults.logoImageFile);
  const [musicFileList, setMusicFileList] = useState(config.defaults.musicFileList);

  // Scene Editor Panel
  const [sceneTitles, setSceneTitles] = useState(config.defaults.sceneTitles);
  const [selectedScene, setSelectedScene] = useState(config.defaults.selectedScene);
  const [scenes, setScenes] = useState(config.defaults.scenes);
  const [currentlySelectedSceneBackgroundDisplayColor, setCurrentlySelectedSceneBackgroundDisplayColor] = useState(config.defaults.currentlySelectedSceneBackgroundDisplayColor);
  const [currentlySelectedSceneBackgroundDisplayType, setCurrentlySelectedSceneBackgroundDisplayType] = useState(config.defaults.currentlySelectedSceneBackgroundDisplayType);
  const [currentlySelectedSceneBackgroundDisplayPosition, setCurrentlySelectedSceneBackgroundDisplayPosition] = useState(config.defaults.currentlySelectedSceneBackgroundDisplayPosition);

  const [selectedSceneTheme, setSelectedSceneTheme] = useState(config.defaults.selectedSceneTheme);
  const [selectedSceneObject, setSelectedSceneObject] = useState(config.defaults.selectedSceneObject);
  const [maxSceneObjectCount, setMaxSceneObjectCount] = useState(config.defaults.maxSceneObjectCount);
  // const [selectedBackgroundDisplayType, setSelectedBackgroundDisplayType] = useState(config.defaults.selectedBackgroundDisplayType);
  const [iconFileInput, setIconFileInput] = useState(config.defaults.iconFileInput);
  const [mediaFileInput, setMediaFileInput] = useState(config.defaults.mediaFileInput);
  // const [backgroundImageFileInput, setBackgroundImageFileInput] = useState(config.defaults.backgroundImageFileInput);
  const [viewsTitles, setViewsTitles] = useState(config.defaults.viewsTitles);
  const [views, setViews] = useState(config.defaults.views);
  const [selectedView, setSelectedView] = useState(config.defaults.selectedView);

  // Customization Panel
  // Legend #1
  const [colorComponentTitles, setColorComponentTitles] = useState(config.defaults.colorComponentTitles);
  // Legend #2
  const [colorComponents, setColorComponents] = useState(config.defaults.colorComponents);
  const [selectedComponent, setSelectedComponent] = useState(config.defaults.selectedComponent);

  // Legend #13
  const [colorSwatchesTitles, setColorSwatchesTitles] = useState(config.defaults.colorSwatchesTitles);
  // Legend #14
  const [colorSwatches, setColorSwatches] = useState(config.defaults.colorSwatches);
  const [selectedSwatch, setSelectedSwatch] = useState(config.defaults.selectedSwatch);

  
  // @note: This toggles whether single component or multi component tab is shown 
  // Legend #3
  const [isSingleComponent, setIsSingleComponent] = useState(false);

  // Legend #4
  const [unSavedComponents, setUnSavedComponents] = useState(isSingleComponent ? JSON.parse(JSON.stringify(colorSwatches)) :  JSON.parse(JSON.stringify(colorComponents)));

  // Lighting Panel
  const [shouldEnableLightingFeature, setShouldEnableLightingFeature] = useState(config.defaults.shouldEnableLightingFeature);
  const [selectedLightingOptions, setSelectedLightingOptions] = useState(config.defaults.selectedLightingOptions);
  const [selectedLightingOption, setSelectedLightingOption] = useState(config.defaults.selectedLightingOption);
  const [lightRotationAngle, setLightRotationAngle] = useState(config.defaults.lightRotationAngle);
  const [lightBrightness, setLightBrightness] = useState(config.defaults.lightBrightness);

    // Augmented Reality Experiences Panel
  const [isVerticalARModeEnabled, setIsVerticalARModeEnabled] = useState(config.defaults.isVerticalARModeEnabled);
  const [isHorizontalARModeEnabled, setIsHorizontalARModeEnabled] = useState(config.defaults.isHorizontalARModeEnabled);
  const [isTryOnARModeEnabled, setIsTryOnARModeEnabled] = useState(config.defaults.isTryOnARModeEnabled);
  const [isSnapchatARModeEnabled, setIsSnapchatARModeEnabled] = useState(config.defaults.isSnapchatARModeEnabled);
  const [isImageARMarkerARModeEnabled, setIsImageARMarkerARModeEnabled] = useState(config.defaults.isImageARMarkerARModeEnabled);
  
  // Product Redirect Panel
  const [productCheckoutURL, setProductCheckoutURL] = useState(config.defaults.productCheckoutURL);
  const [briefProductDescription, setBriefProductDescription] = useState(config.defaults.briefProductDescription);

  // Annotations Panel
  const [shouldShowAnnotations, setShouldShowAnnotations] = useState(config.defaults.shouldShowAnnotations);
  const [shouldEnableAnnotationsFeature, setShouldEnableAnnotationsFeature] = useState(config.defaults.shouldEnableAnnotationsFeature);
  const [isEditingAnnotations, setIsEditingAnnotations] = useState(config.defaults.isEditingAnnotations);
  const [isInAddHotspotMode, setIsInAddHotspotMode] = useState(config.defaults.isInAddHotspotMode);
  const [maxHotspotCount, setMaxHotspotCount] = useState(config.defaults.maxHotspotCount);
  const [hotspots, setHotspots] = useState(config.defaults.hotspots);
  const [selectedHotspotID, setSelectedHotspotID] = useState(config.defaults.selectedHotspotID);
  const [hotspotPositionByIDs, setHotspotPositionByIDs] = useState(config.defaults.hotspotPositionByIDs);
  const [hotspotOpacityByIDs, setHotspotOpacityByIDs] = useState(config.defaults.hotspotOpacityByIDs);
  const [showInteractionPrompt, setShowInteractionPrompt] = useState(0)

  // Measurement & Comparison Panel
  const [measurementUnit, setMeasurementUnit] = useState(config.defaults.measurementUnit)
  const [enableMeasurement, setEnableMeausrement] = useState(config.defaults.enableMeasurement)
  const [objMeasurements, setObjMeasurements] = useState({...config.defaults.objectMeasurements});
  const [showMeasurements, setShowMeasurements] = useState(config.defaults.showMeasurements)
  const [comparisonObjects, setComparisonObjects] = useState(config.defaults.comparisonObjects)

  // Watermark
  const [enableWatermark, setEnableWatermark] = useState(config.defaults.watermarkEnabled);

  // Preview Route
  const [selectedPreviewType, setSelectedPreviewType] = useState('desktop-fitted-canvas');

  const isClientSide = appMode === 'client';

  // Orbit Ball 
  const isHoveringOrbitBallCanvas = useRef(false);
  // context.views = views; 
  // context.selectedView = selectedView;
  // @todo: may reconsider this.
  context.shouldAutoRotateModel = shouldAutoRotateModel;
  context.modelRotationSpeed = modelRotationSpeed;
  context.pauseAutoRotateModel = pauseAutoRotateModel;
  context.setPauseAutoRotateModel = setPauseAutoRotateModel;
  context.productShadowBlur = productShadowBlur;
  context.productShadowIntensity = productShadowIntensity;
  context.selectedScene = selectedScene;
  context.setSelectedScene = setSelectedScene;
  context.scenes = scenes; 
  context.sceneTitles = sceneTitles; 
  context.setSceneTitles = setSceneTitles; 
  context.setScenes = setScenes; 
  context.selectedSceneObject = selectedSceneObject;
  context.setSelectedSceneObject = setSelectedSceneObject;
  context.selectedPanelForClientSide = selectedPanelForClientSide; 
  context.setSelectedPanelForClientSide = setSelectedPanelForClientSide; 
  context.enableMeasurement = enableMeasurement;
  context.objMeasurements = objMeasurements
  context.showMeasurements = showMeasurements
  context.views = views
  context.viewsTitles = viewsTitles
  context.selectedView = selectedView
  context.lightBrightness = lightBrightness
  context.setLightBrightness = setLightBrightness
  context.setShowInteractionPrompt = setShowInteractionPrompt

  useEffect(() => {
    const hash = window.location.hash;
    if (hash && hash.indexOf('#client') > -1) setAppMode('client');
    if (hash && hash.indexOf('#customer') > -1) setAppMode('customer');
    if (hash && hash.indexOf('#preview') > -1) setAppMode('preview');
    const handleLocationChange = () => {
      window.location.reload();
    };
    window.addEventListener('popstate', handleLocationChange);
    return () => window.removeEventListener('popstate', handleLocationChange);
  }, []);

  context.hotspotsForTJS = hotspots; // @todo: rethink this.
  context.hotspotPositionByIDsForTJS = hotspotPositionByIDs;
  context.hotspotOpacityByIDsForTJS = hotspotOpacityByIDs;

  useEffect(() => {
    initializeScene({
        // params: config.params,
      params: publicConfig.params,
      webGLContainerRef,
      orbitBallWebGLContainerRef,
      currentCustomerViewWebGLContainerRef,
      app,
      context,
      isHoveringOrbitBallCanvas,
      values: {
        selectedLightingOption,
      },
      callbacks: {
        setHotspotPositionByIDs,
        setHotspotOpacityByIDs,
      },
    });
  }, []);
  
  const handlePauseAutoRotation = () => {
    // app.shouldRotateModel = false;
    app.shouldRotateModel = true;
    if (rotateModelTimeoutRef.current) clearTimeout(rotateModelTimeoutRef.current);
  };
  context.tween = useRef(null)
  
  const handleResumeAutoRotation = () => {
    if (rotateModelTimeoutRef.current) clearTimeout(rotateModelTimeoutRef.current);
    rotateModelTimeoutRef.current = setTimeout(() => {
      if (!didToggleAutoRotationOffManually.current) app.shouldRotateModel = true;
    }, publicConfig.params.sceneRotationResumeDelayDuration);
  };
  
  const stopAnimation = (tween) => {
    if (context.tween.current) {
      context.tween.current.stop();
    }
  }
  context.componentJustMounted = useRef(true)

  const handleCanvasMouseDown = () => {
    if (context.tween.current) {
      stopAnimation(context.tween)
    }
    context.isTweenRunning = false;
    setShowInteractionPrompt(0);
    context.componentJustMounted.current = false; 
    if (context.transformControls.dragging) {
      context.previouslyTransformed = true; 
    };
    if (shouldAutoRotateModel) {
      handlePauseAutoRotation();
    };
  };
  
  
  const handleCanvasMouseDownForOrbitBall = (e) => {
    const { 
      orbitBallCamera, 
      orbitBallMeshes, 
      hoverSpokeMaterial, originalSpokeMaterial, 
      tween2
    } = context;
    const { orbitBallCameraViews } = config; 
  
    const canvas = context.orbitBallRenderer.domElement;
    const canvasPosition = canvas.getBoundingClientRect();

    const mousePosition = new THREE.Vector2();
    mousePosition.x = ((e.clientX - canvasPosition.left) / canvas.width) * 2 - 1;
    mousePosition.y = -((e.clientY - canvasPosition.top) / canvas.height) * 2 + 1;
     
    const rayCaster = new THREE.Raycaster();
    rayCaster.setFromCamera(mousePosition, orbitBallCamera);
    let intersects = rayCaster.intersectObjects(orbitBallMeshes, false);
    
    if (intersects.length > 0 && intersects[0].object.name !== 'sphere') {
      context.intersectedObjectParent = intersects[0].object.parent;
    } else {
      context.intersectedObjectParent = null;
    };

    const { intersectedObjectParent } = context;

    orbitBallMeshes.forEach((mesh, i) => {
      if (intersectedObjectParent && intersectedObjectParent.name === mesh.parent.name) {
        orbitBallMeshes[i].material = hoverSpokeMaterial;
        tween2.to(orbitBallCameraViews[intersectedObjectParent.name].position, 700)
        // .easing(TWEEN.Easing.Cubic.Out)
        .easing(TWEEN.Easing.Exponential.Out)
        .onUpdate(() => {
          context.cameraControls.target = new THREE.Vector3(0, 0.01, 0.01);
          context.isTweenRunning = true; 
          context.cameraControls.update();
        })
        .start()
        .onComplete(() => context.isTweenRunning = false);
      } else if (mesh.name !== 'sphere') {
        orbitBallMeshes[i].material = originalSpokeMaterial[mesh.parent.name];
      };
    });
  };

  const handleCanvasMouseUp = (e) => {

    if (selectedPanelForClientSide === 'scene-editor' && context.currentlyAttachedScene) {
      if (!context.previouslyTransformed) {
      const { 
        currentlyAttachedScene,
        // Gizmos === Spokes for orbit ball
        gizmoMeshes,
        transformControls,
        translateGizmoMeshes, translateMenuMeshes,      
        scaleGizmoMeshes, scaleMenuMeshes,      
        rotateGizmoMeshes, rotateMenuMeshes,  
        translateGizmoCenterMesh, translateMenuCenterMesh,
        scaleGizmoCenterMesh, scaleMenuCenterMesh,
        rotateGizmoCenterMesh, rotateMenuCenterMesh,    
      } = context;
      const canvas = context.sceneRenderer.domElement;
      const canvasPosition = canvas.getBoundingClientRect();
      const mousePosition = new THREE.Vector2();
      mousePosition.x = ((e.clientX - canvasPosition.left) / canvas.width) * 2 - 1;
      mousePosition.y = -((e.clientY - canvasPosition.top) / canvas.height) * 2 + 1;

      const rayCaster = new THREE.Raycaster();
      rayCaster.setFromCamera(mousePosition, context.sceneCamera);
      const gizmoMeshIntersects = rayCaster.intersectObjects(gizmoMeshes, false);

      const rayCaster2 = new THREE.Raycaster();
      rayCaster2.setFromCamera(mousePosition, context.sceneCamera);
      const dragAndDropMeshIntersects = rayCaster2.intersectObjects(context.dragAndDropMeshes, false);

      if (gizmoMeshes.length === 0) {
        switch(context.nameOfSelectedGizmoMeshParentContext) {
          case 'translateMenuMeshes':
            context.gizmoMeshes = [translateGizmoCenterMesh];
            break;
          case 'scaleMenuMeshes':
            context.gizmoMeshes = [scaleGizmoCenterMesh];
            break;
          case 'rotateMenuMeshes':
            context.gizmoMeshes = [rotateGizmoCenterMesh];
            break;
          default:
            context.gizmoMeshes = [translateMenuCenterMesh, scaleMenuCenterMesh, rotateMenuCenterMesh];
        };
      };
      function raycastOnGizmos () { 
        if (!context.currentlyAttachedScene) return ;
        context.nameOfSelectedGizmoMeshParentContext = gizmoMeshIntersects[0].object.parent.name;
        if (context.nameOfSelectedGizmoMeshParentContext === 'translateMenuMeshes') {
          translateMenuMeshes.visible = false;
          scaleMenuMeshes.visible = false;
          rotateMenuMeshes.visible = false;

          // gizmoScaleMeasureLineMesh.visible = false; 
          translateGizmoMeshes.visible = true;

          context.gizmoMeshes = [translateGizmoCenterMesh];
          transformControls.setMode('translate');
        } else if (context.nameOfSelectedGizmoMeshParentContext === 'scaleMenuMeshes') {
          translateMenuMeshes.visible = false;
          scaleMenuMeshes.visible = false;
          rotateMenuMeshes.visible = false;

          // gizmoScaleMeasureLineMesh.visible = true; 

          scaleGizmoMeshes.visible = true;
          context.gizmoMeshes = [scaleGizmoCenterMesh];
          transformControls.setMode('scale');
        } else if (context.nameOfSelectedGizmoMeshParentContext === 'rotateMenuMeshes') {
          translateMenuMeshes.visible = false;
          scaleMenuMeshes.visible = false;
          rotateMenuMeshes.visible = false;

          rotateGizmoMeshes.visible = true;

          // gizmoScaleMeasureLineMesh.visible = false; 

          context.gizmoMeshes = [rotateGizmoCenterMesh];
          transformControls.setMode('rotate');
        } else if (context.nameOfSelectedGizmoMeshParentContext) {
          translateMenuMeshes.visible = true;
          scaleMenuMeshes.visible = true;
          rotateMenuMeshes.visible = true;

          translateGizmoMeshes.visible = false;
          scaleGizmoMeshes.visible = false;
          rotateGizmoMeshes.visible = false;

          // gizmoScaleMeasureLineMesh.visible = false; 

          context.gizmoMeshes = [translateMenuCenterMesh, scaleMenuCenterMesh, rotateMenuCenterMesh];
        }
      };
      function raycastOnDragAndDrops (mesh) {
        // if raycasted mesh is the same that controls are already on, don't run function
        if (mesh.belongsTo === currentlyAttachedScene.belongsTo && !currentlyAttachedScene.selectedBeforeCanvasCleared) return; 
        currentlyAttachedScene.selectedBeforeCanvasCleared = true; 
        const object = context.currentSceneObjects.filter(object => object.modelScene.belongsTo === mesh.belongsTo)[0];
        switchTransformControlsToSelectedObject(object.modelScene, object.locked, object.visible, context)
        setSelectedSceneObject(object.label);
      };

      // @note: Lewis - this removes transform control when canvas is clicked
      if (gizmoMeshIntersects.length === 0 && dragAndDropMeshIntersects.length === 0) {
        context.sceneGroup.remove(context.transformControlsGizmoMeshesGroup, context.transformControlsMenuMeshesGroup)
        context.gizmoMeshes.length = 0;
        context.transformControls.detach();
        // context.setSelectedSceneObject(null);
        currentlyAttachedScene.selectedBeforeCanvasCleared = true; 
      } else if (gizmoMeshIntersects.length > 0) {
        raycastOnGizmos();
      } else if (dragAndDropMeshIntersects.length > 0) {
        raycastOnDragAndDrops(dragAndDropMeshIntersects[0].object);
      };
    }; 
    context.previouslyTransformed = false; 

    
    } else if (selectedPanelForClientSide === 'annotations') { // @todo: finish this. 
    // if (true) { // @todo: finish this. 
      const canvas = context.sceneRenderer.domElement;
      const canvasPosition = canvas.getBoundingClientRect();
      const mousePosition = new THREE.Vector2();
      mousePosition.x = ((e.clientX - canvasPosition.left) / canvas.width) * 2 - 1;
      mousePosition.y = -((e.clientY - canvasPosition.top) / canvas.height) * 2 + 1;
      const rayCaster = new THREE.Raycaster();
      rayCaster.setFromCamera(mousePosition, context.sceneCamera);

      if (isInAddHotspotMode) {
        const hotspotIntersects = rayCaster.intersectObjects(context.hotspotMeshes, true);
        if (hotspotIntersects.length > 0) {
          // const point = hotspotIntersects[0].point;
        } else {
          
          const intersects = rayCaster.intersectObjects(context.modelGroup.children, true);
          if (intersects.length > 0) {
            const point = intersects[0].point;
            const directionVector = ((new THREE.Vector3( 0, 0, -1 )).applyQuaternion(context.sceneCamera.quaternion));
            const hotspots_ = [...hotspots];
            const newHotspot = {
              id: hotspots_.length, // @todo: change later uuid, or timestamp maybe.
              location: { x: point.x, y: point.y, z: point.z },
              facingVector: { x: -directionVector.x, y: -directionVector.y, z: -directionVector.z },
              visible: true,
              label: 'Hotspot ' + (hotspots_.length + 1), // @todo. base on count?
              color: '#072b62',
              stroke: '',
              icon: '',
              runTime: '',
              animationType: '',
              bodyText: '',
              media: '',
            };
            hotspots_.push(newHotspot);
            setHotspots(hotspots_);
            setIsInAddHotspotMode(false);
          };
        };
      } else {
        // @todo: refine duplicate code.
        const hotspotIntersects = rayCaster.intersectObjects(context.hotspotMeshes, true);

        if (hotspotIntersects.length > 0) {
          const hotspotID = context.hotspotIDByHotspotMeshID[hotspotIntersects[0].object.uuid];
          if (hotspotID !== undefined) {
            const hotspot = context.hotspotsForTJS.find(o => o.id === hotspotID);
            if (context.modelAnimations) {
              const animationIndex = context.modelAnimations.findIndex(o => o.name === hotspot.animationType);
              if (animationIndex > -1) {
                const action = context.mixer.clipAction(context.modelAnimations[animationIndex]);
                action.setLoop(THREE.LoopRepeat);
                // action.setLoop(THREE.LoopOnce);
                action.clampWhenFinished = true;
                action.play();
                setTimeout(() => {
                  action.stop();
                }, 2500);
              }
            }
          }
        }
      }
    }
    if (shouldAutoRotateModel) {
      handleResumeAutoRotation();
    }
  };
  
  useEffect(() => {
    app.handleContainerResize();
  }, [isCustomerOptionsPanelOpen, appMode, wrapperDimensions]);

  useEffect(() => {
    // @todo: could use previous here, to ensure no reloading, later optimization.
    // @todo: do this in a worker thread if possible, to avoid ui freeze. performance optimization.
    context.environmentMap = context.cubeTextureloader.load(selectedLightingOption.environmentMapImages);
    context.scene.environment = context.environmentMap;
  }, [selectedLightingOption]);

  useEffect(() => {
    // context.environmentMap.rotation.y = Math.PI * (lightRotationAngle/360); // @todo: not this, will debug later.
    context.lightingGroup.rotation.y = Math.PI * (lightRotationAngle/180);
  }, [lightRotationAngle]);

  useEffect(() => {
    context.primaryDirectionalLightContainer.light.shadow.radius = (productShadowBlur / 100) * 100;
    // @todo: need to verify above - re-render maybe?
  }, [productShadowBlur]);

  useEffect(() => {
    context.shouldAutoRotateModel = shouldAutoRotateModel;
  }, [shouldAutoRotateModel]);

  useEffect(() => {
    context.modelRotationSpeed = modelRotationSpeed;
  }, [modelRotationSpeed]);

  useEffect(() => {
    if (isInAddHotspotMode) {
      context.cameraControls.enabled = false;
    } else {
      context.cameraControls.enabled = true;
    }
  }, [isInAddHotspotMode]);

  useEffect(() => {
    for (let hotspot of hotspots) {
      const hotspotMesh = context.hotspotMeshByHotspotID[hotspot.id];
      if (!hotspotMesh) {
        context.createHotspotMesh(hotspot);
      }
    }
    const IDsOfExistingHotspots = Object.keys(context.hotspotMeshByHotspotID);
    for (let hotspotID of IDsOfExistingHotspots) {
      const hotspotIndex = hotspots.findIndex(o => o.id === hotspotID);
      if (hotspotIndex > -1) {
        // @todo: destroy
      }
    }
  }, [hotspots]);

    // @note: Lewis - This is to load all sceneObjects when first going into scene
    useEffect(() => {
      loadSceneObjects([...scenes[selectedScene].sceneObjects], context.addModelToScene);
      setCurrentlySelectedSceneBackgroundDisplayColor(scenes[selectedScene].backgroundDisplayColor);
    }, []);

  const handleChangeHotspot = (hotspotID, key, value) => {
    const hotspots_ = [...hotspots];
    const hotspotIndex = hotspots_.findIndex(o => o.id === hotspotID);
    hotspots_[hotspotIndex] = {
      ...hotspots_[hotspotIndex],
      [key]: value,
    };
    setHotspots(hotspots_);
  };

  useEffect(() => {
    // set some set interval 
    let interactionPromptInterval = null; 
    if (shouldEnableInteractionPrompt) {
      interactionPromptInterval = setInterval(() => {
        setShowInteractionPrompt(prev => Math.min(7, prev + 1)); 
      }, 1000)
    }
      return (() => clearInterval(interactionPromptInterval))
    }, [showInteractionPrompt, shouldEnableInteractionPrompt])
  // @note: helper vars for renderer below.
  const isPreviewMode = appMode === 'preview';

  // This is for the interaction Prompt 
  const interactionTimeout = useRef(null); 
  const runAnimation = (camera, context, stop=false) => {
    context.tween.current = new TWEEN.Tween(camera.position)
          .to({ x: 0.5 }, 2150)
          .easing(TWEEN.Easing.Quadratic.InOut)
          .onUpdate(() => {
            if (showInteractionPrompt >= 7) {
              context.cameraControls.target = new THREE.Vector3(0, 0.01, 0.01);
              context.isTweenRunning = true; 
              context.cameraControls.update();
            } else {
              context.tween.current.stop()
              context.isTweenRunning = false; 
              return;
            }
          })
          .onComplete(() => {
            context.tween.current = new TWEEN.Tween(camera.position)
              .to({ x: -1 }, 2500)
              .easing(TWEEN.Easing.Quadratic.InOut)
  
              .onUpdate(() => {
                if (showInteractionPrompt >= 7) {
                  context.cameraControls.target = new THREE.Vector3(0, 0.01, 0.01);
                  context.isTweenRunning = true; 
                  context.cameraControls.update();
                } else {
                  context.tween.current.stop()
                  context.isTweenRunning = false; 
                  return;
                }
              })
              .onComplete(() => {
                context.tween.current = new TWEEN.Tween(camera.position)
                  .to({ x: 0 }, 2000)
                  .easing(TWEEN.Easing.Quadratic.InOut)
  
                  .onUpdate(() => {
                    if (showInteractionPrompt >= 7) {
                      context.cameraControls.target = new THREE.Vector3(0, 0.01, 0.01);
                      context.isTweenRunning = true; 
                      context.cameraControls.update();
                    } else {
                      context.tween.current.stop()
                      context.isTweenRunning = false; 
                      return;
                    }
                  })
                  .onComplete(() => {
                    context.isTweenRunning = false;
                  })
                  .start();
              })
              .start();
          })
          .start();
  }

  // context.tween = tween;

  useEffect(() => {
    if (selectedPanelForClientSide === 'scene-editor') return; 
    if (showInteractionPrompt === 0) {
      context.componentJustMounted.current = true; 
    }
    if (showInteractionPrompt < 7) return; 
    if (context.componentJustMounted.current && !shouldAutoRotateModel) {
      runAnimation(context.sceneCamera, context)
    } 
    if (shouldEnableInteractionPrompt && !shouldAutoRotateModel) {
      interactionTimeout.current = setInterval(() => runAnimation(context.sceneCamera, context), 6500)
    }
    context.componentJustMounted.current = false
    return (() => clearInterval(interactionTimeout.current))
  }, [selectedPanelForClientSide, shouldAutoRotateModel, showInteractionPrompt])

  // END OF INTERACTION PROMPT




  return (
    // Global context available in any child component using the useContext hook
    <AppContext.Provider value={{
      // ..
      shouldEnableInteractionPrompt, setShouldEnableInteractionPrompt,
      shouldAutoRotateModel, setShouldAutoRotateModel,
      modelRotationSpeed, setModelRotationSpeed,
      productShadowIntensity, setProductShadowIntensity,
      productShadowBlur, setProductShadowBlur,
      canvasSize, setCanvasSize,
      showInteractionPrompt, setShowInteractionPrompt,
      // context.componentJustMounted,
      // ..
      // buttonOutlineColor, setButtonOutlineColor,
      logoImageFile, setLogoImageFile,
      musicFileList, setMusicFileList,
      //..
      sceneTitles, setSceneTitles,
      selectedScene, setSelectedScene,
      scenes, setScenes,
      currentlySelectedSceneBackgroundDisplayColor, setCurrentlySelectedSceneBackgroundDisplayColor,
      currentlySelectedSceneBackgroundDisplayType, setCurrentlySelectedSceneBackgroundDisplayType,
      currentlySelectedSceneBackgroundDisplayPosition, setCurrentlySelectedSceneBackgroundDisplayPosition,
      selectedSceneTheme, setSelectedSceneTheme,
      selectedSceneObject, setSelectedSceneObject,
      maxSceneObjectCount, setMaxSceneObjectCount,
      // selectedBackgroundDisplayType, setSelectedBackgroundDisplayType,
      iconFileInput, setIconFileInput,
      mediaFileInput, setMediaFileInput,
      // backgroundImageFileInput, setBackgroundImageFileInput,
      views, setViews,
      viewsTitles, setViewsTitles,
      selectedView, setSelectedView,
      context,
      //..
      selectedSwatch, setSelectedSwatch,
      colorSwatchesTitles, setColorSwatchesTitles,
      colorSwatches, setColorSwatches,
      selectedComponent, setSelectedComponent, 
      colorComponentTitles, setColorComponentTitles,
      colorComponents, setColorComponents,
      isSingleComponent, setIsSingleComponent,
      unSavedComponents, setUnSavedComponents,
      // ..
      shouldEnableLightingFeature, setShouldEnableLightingFeature,
      selectedLightingOptions, setSelectedLightingOptions,
      selectedLightingOption, setSelectedLightingOption,
      lightRotationAngle, setLightRotationAngle,
      lightBrightness, setLightBrightness,
      // ..
      isVerticalARModeEnabled, setIsVerticalARModeEnabled,
      isHorizontalARModeEnabled, setIsHorizontalARModeEnabled,
      isTryOnARModeEnabled, setIsTryOnARModeEnabled,
      isSnapchatARModeEnabled, setIsSnapchatARModeEnabled,
      isImageARMarkerARModeEnabled, setIsImageARMarkerARModeEnabled,
      // ..
      productCheckoutURL, setProductCheckoutURL,
      briefProductDescription, setBriefProductDescription,
      // ..
      shouldEnableAnnotationsFeature, setShouldEnableAnnotationsFeature,
      shouldShowAnnotations, setShouldShowAnnotations,
      isEditingAnnotations, setIsEditingAnnotations,
      isInAddHotspotMode, setIsInAddHotspotMode,
      maxHotspotCount, setMaxHotspotCount,
      hotspots, setHotspots,
      selectedHotspotID, setSelectedHotspotID,
      // ..
      measurementUnit, setMeasurementUnit,
      setEnableMeausrement, enableMeasurement,
      objMeasurements, setObjMeasurements,
      showMeasurements, setShowMeasurements,
      comparisonObjects, setComparisonObjects,
      // ..
      enableWatermark, setEnableWatermark
    }}>
      <AppContainer isPreviewMode={isPreviewMode}>
        {isClientSide && <ClientOptions selectedPanel={selectedPanelForClientSide} setSelectedPanel={setSelectedPanelForClientSide} context={context}/>}
        {isPreviewMode && <PreviewBar
          selectedPreviewType={selectedPreviewType}
          setSelectedPreviewType={setSelectedPreviewType}
        />}
        {isPreviewMode && (selectedPreviewType === 'mobile-full-screen' || selectedPreviewType === 'mobile-fitted-canvas') && (
          <PreviewScreenImageContainer>
            <PreviewScreenImage src={'/images/misc/iPhoneXMockupScreen.png'} />
          </PreviewScreenImageContainer>
        )}
        <WebGLWrapperParentContainer
          isClientSide={isClientSide}
          isPreviewMode={isPreviewMode}
        >
          <WebGLWrapperContainer
            id="webGLWrapperContainer"
            ref={webGLWrapperContainerRef}
            isPreviewMode={isPreviewMode}
            canvasSize={{ x: 800, y: 640 }} // @todo: here for dev.
            previewType={selectedPreviewType}
          >
            <WebGLContainer
              selectedBackgroundColor={currentlySelectedSceneBackgroundDisplayColor}
              selectedBackgroundType={currentlySelectedSceneBackgroundDisplayType}
              selectedBackgroundPosition={currentlySelectedSceneBackgroundDisplayPosition}
              multiplier={1.15}
              ref={webGLContainerRef}
              onMouseDown={handleCanvasMouseDown}
              onMouseUp={handleCanvasMouseUp} // @todo: re-enable later.
              isCustomerOptionsPanelOpen={isCustomerOptionsPanelOpen}
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => {
                const modelObject = JSON.parse(e.dataTransfer.getData('model-object'));
                context.onDropMouseCoordinates = e; 
                context.addModelToScene(modelObject, null, false, true); // @todo: rename stuff later.
                // @note: Add the drag and drop objects 
              }}
            >
              {selectedPanelForClientSide !== 'scene-editor' && shouldEnableInteractionPrompt && showInteractionPrompt >= 7 && !shouldAutoRotateModel && <InteractionPrompt/>}
            </WebGLContainer>
            {selectedPanelForClientSide !== 'scene-editor' && (
            <CustomerOptions
              isCustomerOptionsPanelOpen={isCustomerOptionsPanelOpen}
              setIsCustomerOptionsPanelOpen={setIsCustomerOptionsPanelOpen}
              isCustomerPanelDarkMode={true} // @todo: temporary.
              isClientSide={isClientSide}
              shouldEnableLightingFeature={shouldEnableLightingFeature}
              shouldEnableAnnotationsFeature={shouldEnableAnnotationsFeature}
              shouldShowAnnotations={shouldShowAnnotations}
              shouldZoomForMobile={isPreviewMode && (selectedPreviewType === 'mobile-full-screen' || selectedPreviewType === 'mobile-fitted-canvas')}
              setShouldShowAnnotations={setShouldShowAnnotations}
              enableMeasurement={enableMeasurement}
              scenes={scenes}
              selectedScene={selectedScene}
              enableWatermark={enableWatermark}
            />)
            }
            <CanvasOverlayContainer>
              {(shouldShowAnnotations || (isClientSide && selectedPanelForClientSide === 'annotations')) && hotspots.map(hotspot => (
                <HotspotOverlay
                  key={hotspot.id}
                  hotspot={hotspot}
                  hotspotPosition={hotspotPositionByIDs[hotspot.id]}
                  hotspotOpacity={hotspotOpacityByIDs[hotspot.id]}
                  selectedHotspotID={selectedHotspotID}
                  setSelectedHotspotID={setSelectedHotspotID}
                  isEditingAnnotations={isEditingAnnotations}
                  onChangeHotspot={handleChangeHotspot}
                  context={context}
                />
              ))}
            </CanvasOverlayContainer>
            {/* put confirmation message popup */}
          </WebGLWrapperContainer>
        </WebGLWrapperParentContainer>
        <OrbitBallWebGLContainer
          ref={orbitBallWebGLContainerRef}
          onMouseDown={handleCanvasMouseDownForOrbitBall}
          isVisible={selectedPanelForClientSide === 'scene-editor'}
        >
        </OrbitBallWebGLContainer>
        <CurrentCustomerView
          selectedBackgroundColor={currentlySelectedSceneBackgroundDisplayColor}
          selectedBackgroundType={currentlySelectedSceneBackgroundDisplayType}
          selectedBackgroundPosition={currentlySelectedSceneBackgroundDisplayPosition}
          context={context}
          viewsTitles={viewsTitles}
          setViewsTitles={setViewsTitles}
          views={views}
          setViews={setViews}
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          currentCustomerViewWebGLContainerRef={currentCustomerViewWebGLContainerRef}
        />
        <LogoDisplay imageFile={logoImageFile}/>
      </AppContainer>
    </AppContext.Provider>
  );
};

const AppWrapper = () => {
  const [publicConfig, setPublicConfig] = useState(null);
  useEffect(() => {
    (async () => {
      const response = await fetch('data/config.json');
      const publicConfig_ = await response.json();
      const value=queryString.parse(window.location.search);
      const model=value.model;
      console.log(value);//123
      const responseCela = await fetch('https://www.thecela.com/api/v1/configurator?model='+model);
      const celaJSON = await responseCela.json();
      publicConfig_.params.modelFilePath = celaJSON.data.model_src
      setPublicConfig(publicConfig_);
    })();
  }, []); 
  if (!publicConfig) return null; // @todo: reconsider later.
  return (
    <App publicConfig={publicConfig} />
  )
};

export default AppWrapper;



