import styled from "styled-components";
import "rc-slider/assets/index.css";
import SelectColorsPanel from "./customer/panels/SelectColorsPanel";
import LightingControlsPanel from "./customer/panels/LightingControlsPanel";
import BuyProductPanel from "./customer/panels/BuyProductPanel";
import MeasurementComparisonPanel from "./customer/panels/MeasurementComparisonPanel";
import { useCustomerOptions } from "../custom-hooks/useCustomerOptions";
import { useFullScreen } from "../custom-hooks/useFullScreen";
import { customerOptionItems } from "../config";
import HelpPanel from "./customer/panels/HelpPanel";

const CustomerOptionsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  pointer-events: none;
  ${({ shouldZoomForMobile }) =>
    shouldZoomForMobile &&
    `
    zoom: 0.4;
  `}
  @media (max-width: 1023px) {
    top: initial;
    right: initial;
    bottom: 0;
    left: 0;
    width: 100%;
    height: initial;
    flex-direction: column;
  }
`;

const PrimaryMenu = styled.div`
  margin: 32px;
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  margin-right: 48px;
  pointer-events: none;
  @media (max-width: 1023px) {
    flex-direction: row;
    justify-content: center;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 48px;
    ${({ isAnyPanelOpen }) =>
      isAnyPanelOpen &&
      `
      margin-bottom: 24px;
    `}
    min-width: ${7 * 48 + 6 * 32}px;
    /* @todo: ^ use actual button count for above later. */
    /* @todo: overflow-x scroll - needs to be on a parent container. */
  }
`;

const PrimaryMenuItemContainer = styled.div`
  color: #444;
  width: 48px;
  height: 48px;
  pointer-events: auto;
  border-radius: 24px;
  ${({ isDarkMode }) => isDarkMode && `filter: invert(1);`}
  ${({ isWaterMark }) =>
    !isWaterMark
      ? `
    cursor: pointer;
    &:hover {
    background-color: rgba(255, 255, 255, 0.0625);
  }`
      : `
      pointer-events: none;
        > img {
          width: 5rem;
          position: relative;
          right: 1rem;
        }
      `}

  & + div {
    margin-top: 32px;
  }
  @media (max-width: 1023px) {
    background-color: rgba(0, 0, 0, 0.5);
    &:hover,
    &:active {
      background-color: rgba(0, 0, 0, 0.5);
      /* @todo: ^ might improve this. */
    }
    & + div {
      margin-top: 0px;
      margin-left: 32px;
    }
  }
  &::before {
    background-color: ${({ outlineColor }) =>
      outlineColor ? outlineColor : ""};
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    filter: invert(1);
    border-radius: 24px;
    opacity: 0.25;
  }

  ${({ annotationsEnabled }) =>
    annotationsEnabled && `background-color: rgba(0,0,0,0.8)`}
`;

const PanelContainer = styled.div`
  min-width: 240px;
  max-width: 240px;
  background-color: #fff;
  pointer-events: auto;
  @media (max-width: 1023px) {
    min-width: initial;
    max-width: initial;
    width: 100%;
  }
`;

const PanelHeading = styled.div`
  color: #000;
  margin: 32px;
  margin-top: 48px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  @media (max-width: 1023px) {
    margin-top: 24px;
  }
`;

const AnnotationMessage = styled.p`
  margin: 0;
  position: absolute;
  right: 4rem;
  width: 8rem;
  bottom: 1rem;
  color: black;
`;

const PrimaryMenuItem = ({
  menuItem,
  onClick,
  isDarkMode,
  buttonOutlineColor,
  fullscreenEnabled = false,
  annotationsEnabled = false,
}) => {
  return (
    <PrimaryMenuItemContainer
      onClick={onClick}
      isDarkMode={isDarkMode}
      outlineColor={buttonOutlineColor}
      annotationsEnabled={menuItem.slug === "annotations" && annotationsEnabled}
      isWaterMark={menuItem.slug === "cela-logo"}
    >
      <img
        src={
          fullscreenEnabled && menuItem.slug === "fullscreen"
            ? menuItem.iconImagePathClose
            : menuItem.iconImagePath
        }
        alt={menuItem.title}
      ></img>
      {menuItem.slug === "annotations" && annotationsEnabled ? (
        <AnnotationMessage>Annotation ON</AnnotationMessage>
      ) : null}
    </PrimaryMenuItemContainer>
  );
};

const CustomerOptions = ({
  isCustomerOptionsPanelOpen,
  setIsCustomerOptionsPanelOpen,
  isCustomerPanelDarkMode,
  isClientSide,
  shouldEnableLightingFeature,
  shouldEnableAnnotationsFeature,
  shouldZoomForMobile,
  setShouldShowAnnotations,
  shouldShowAnnotations,
  enableMeasurement,
  scenes,
  selectedScene,
  enableWatermark,
}) => {
  const [selectedPanel, setSelectedPanel] = useCustomerOptions(
    setIsCustomerOptionsPanelOpen,
    setShouldShowAnnotations,
    enableMeasurement,
    enableWatermark
  );
  const [fullscreenEnabled, setFullScreenEnabled] = useFullScreen();

  return (
    <CustomerOptionsContainer shouldZoomForMobile={shouldZoomForMobile}>
      <PrimaryMenu
        isAnyPanelOpen={isCustomerOptionsPanelOpen}
        buttonOutlineColor={scenes[selectedScene].buttonOutlineColor}
      >
        {customerOptionItems.map((menuItem, menuItemIndex) =>
          isClientSide &&
          ((menuItem.slug === "lighting" && !shouldEnableLightingFeature) ||
            (menuItem.slug === "annotations" &&
              !shouldEnableAnnotationsFeature) ||
            (menuItem.slug === "measurement-comparison" &&
              !enableMeasurement) ||
            (menuItem.slug === "cela-logo" && !enableWatermark)) ? null : (
            <PrimaryMenuItem
              menuItem={menuItem}
              key={menuItemIndex}
              onClick={() => {
                // full screen mode
                if (menuItem.slug === "fullscreen") {
                  return setFullScreenEnabled(!fullscreenEnabled);
                }

                // Annotations
                if (menuItem.slug === "annotations") {
                  return setShouldShowAnnotations(!shouldShowAnnotations);
                }

                // panels
                if (menuItem.slug === selectedPanel) setSelectedPanel(null);
                else setSelectedPanel(menuItem.slug);
              }}
              isDarkMode={isCustomerPanelDarkMode}
              buttonOutlineColor={
                menuItem.slug !== "cela-logo" &&
                scenes[selectedScene].buttonOutlineColor
              }
              fullscreenEnabled={fullscreenEnabled}
              annotationsEnabled={shouldShowAnnotations}
            />
          )
        )}
      </PrimaryMenu>
      {selectedPanel && (
        <PanelContainer>
          <PanelHeading>
            {customerOptionItems.find((o) => o.slug === selectedPanel).title}
          </PanelHeading>
          {selectedPanel === "select-colors" && <SelectColorsPanel />}
          {selectedPanel === "lighting-controls" && <LightingControlsPanel />}
          {/* Panels to be added are commented out */}
          {/* {selectedPanel === 'experience-in-augmented-reality' && <ExperienceInAugmentedRealityPanel />} */}
          {/* {selectedPanel === 'try-on' && <TryOnPanel />} */}
          {selectedPanel === "buy-product" && <BuyProductPanel />}
          {selectedPanel === "help" && <HelpPanel />}
          {selectedPanel === "measurement-comparison" && (
            <MeasurementComparisonPanel></MeasurementComparisonPanel>
          )}
        </PanelContainer>
      )}
    </CustomerOptionsContainer>
  );
};

export default CustomerOptions;

// @todo: may change isDarkMode to color logic - outline color in general settings section, later.

// @todo: may change panelcontainer to panelwrapper outside panel.
