import styled from "styled-components";

const TextInputContainer = styled.div`
  display: flex;
  width: 5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 8px;
  margin-top: -1px;
  box-shadow: 0px 0px 2px 0px #ddd;
  box-sizing: border-box;
`;

const StyledTextInput = styled.input`
  width: 2rem;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const UnitContainer = styled.div`
  color: #0443a3;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const TextInput = ({ className, value, onChange, unit, type = "text" }) => {
  return (
    <TextInputContainer className={className}>
      <StyledTextInput
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      {unit && <UnitContainer>{unit}</UnitContainer>}
    </TextInputContainer>
  );
};

export default TextInput;
