import styled from 'styled-components';

// @todo: rename to PanelOptionContainer
export const ModalContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background: #FFFFFF;
  width: 254px;
  border: 1px solid #072B62;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  z-index: 99999999;
  > * {
    margin: 0 16px 0;
  }
  z-index: 1000000;
`;

export const ModalContainerV2 = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 50;
  right: 42px;
  /* top: 42px; */
  top: 20px;
  width: ${({ clientWidth }) => clientWidth}px; 
  height: ${({ clientHeight }) => clientHeight}px; 
`;

export const FloatingModalContainer = styled.div`
  border-style: none !important;
  position: absolute;
  z-index: 50;
  left: 320px;
  bottom: 24px;
`;
export const FloatingModalContainerV2 = styled.div`
  border-style: none !important;
  position: absolute;
  z-index: 50;
  right: 0px;
  top: 0px;
`;

export const FloatingModalContainerV3 = styled.div`
  border-style: none !important;
  position: absolute;
  z-index: 50;
  bottom: 24px;
  width: calc(100% - 320px);
  height: 100%;
  top: 0;
  left: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FloatingModalContainerV4 = styled.div`
  border-style: none !important;
  position: absolute;
  z-index: 60;
  left: 254px;
  bottom: 0px;
`;
export const FloatingModalContainerV5 = styled.div`
  border-style: none !important;
  position: absolute;
  z-index: 50;
  left: ${({position}) => position.x + 24}px;
  top: ${({position}) => position.y}px;
`;

export const OptionButtonContainer = styled.div`
  border: 1px solid #072B62;
  box-sizing: border-box;
  border-radius: 5px;
  height: 28px; 
  line-height: 28px;
  text-align: center;
  font-size: 11px;
  color: #072B62;
  cursor: pointer;
`;

export const CancelAndSaveButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
  margin-bottom: 16px;
  > * {
	  flex: 1;
  }
  > *+* {
	margin-left: 10px;
  }
`;

export const SaveChangesButtonContainer = styled.div`
  display: flex;
  /* justify-content: space-around; */
  margin-top: 16px;
  margin-bottom: 16px;
  > * {
    border: 1px solid #072B62;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    font-size: 11px;
    color: #072B62;
    flex: 1;
    padding: 8px 64px;
    cursor: pointer;
  }
  
  align-self: center;
`;

export const ModalHeaderContainer = styled.div`
    background: #072B62;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    box-sizing: border-box;
    color: #FFFFFF;
    margin-top: 16px;
    > div {
      padding: 8px 12px;
    }    
`;

export const TextLineOutlineContainer = styled.div`
    margin-top: 16px;
  > div {
    align-self: flex-start;
    margin-bottom: 4px;
  }
  > div:last-child {
    margin-bottom: 0;
  }
`;

export const TextLineContainer = styled.div`
    opacity: ${({ transparent }) => transparent ? 0.5 : 1};
    /* margin-top: 4px; */
`;

export const ImageBoxContainer = styled.div`
  margin-top: 16px;
`;

export const ImagePreviewContainer = styled.div`
  background: #EEEEEE;
  color: rgba(0, 0, 0, 0.125);
  border: 1px dashed #000000;
	border-radius: 5px;
  box-sizing: border-box;
  align-self: center;
  text-align: center;
  padding: 64px; 
`;

export const ImagePreviewContainerV2 = styled.div`
  background: #EEEEEE;
  color: rgba(0, 0, 0, 0.125);
  border: 1px dashed #000000;
	border-radius: 5px;
  box-sizing: border-box;
  align-self: center;
  text-align: center;
  padding: 96px 64px; 
`;

export const ImageContainer = styled.div`
  align-self: center;
  > img {
    /* box-sizing: border-box; */
    /* padding: 0 64px; */
    width: 100%;
    height: 100%;
  }
`;

export const FileSelectorContainer = styled.div`
  display: flex; 
  justify-content: center;
  margin-top: 16px;
`;

export const FileNameDisplayContainer = styled.div`
  flex: 2;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, .25);
  border: ${({ dashedborder }) => dashedborder ? '1px dashed #969595' : null};
  border-radius: 5px;
  box-sizing: border-box;
  align-self: center;
  padding: 12px 0;
  text-align: center;
  ${({ isEmpty }) => isEmpty && `
    color: rgba(0, 0, 0, 0.125);
  `}
`;

export const UploadButtonContainer = styled.div`
  background: #072B62;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.25);
  box-sizing: border-box;
  border-radius: 5px;
  color: #FFFFFF;
  align-self: center;
  flex:1;
  padding: 12px 0;
  text-align: center;
  cursor: pointer;
`; 

export const WideButtonContainer = styled.div`
  text-align: center;
  background-color: #E7EAF0;
  color: #072B62;
  border-radius: 5px;
  padding: 12px;
  cursor: pointer;
  &:focus {
    background-color: rgba(7, 43, 98, 0.5);
  }
`;

export const WideButtonContainerV2 = styled.div`
margin-top: 24px;
text-align: center;
background-color: #072B62;
color: #FFFFFF;
border-radius: 5px;
padding: 12px;
cursor: pointer;
&:focus {
  background-color: rgba(7, 43, 98, 0.5);
}
`;

// -webkit-animation: onHover 1s; /* Safari, Chrome and Opera > 12.1 */
// -moz-animation: onHover 1s; /* Firefox < 16 */
// -ms-animation: onHover 1s; /* Internet Explorer */
// -o-animation: onHover 1s; /* Opera < 12.1 */
// animation: onHover 1s;
// @keyframes onHover {
//   to {  background: rgba(1, 1, 1, 0.3) }
// }
// /* Firefox < 16 */
// @-moz-keyframes onHover {
//   to {  background: rgba(1, 1, 1, 0.3) }
// }
// /* Safari, Chrome and Opera > 12.1 */
// @-webkit-keyframes onHover {
//   to {  background: rgba(1, 1, 1, 0.3) }
// }
// /* Internet Explorer */
// @-ms-keyframes onHover {
//   to {  background: rgba(1, 1, 1, 0.3) }
// }