import styled from 'styled-components';

const FloatingModalContainerV5 = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  z-index: 10000;
  left: ${({position}) => position.x - 64}px;
  top: ${({position}) => position.y + 64}px;
`;

export const FloatingModalContainerV6 = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  z-index: 10000;
  left: ${({position}) => position.x  - 64}px;
  top: ${({position}) => position.y + 124}px;
`;
export const FloatingModalContainerV7 = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  z-index: 10000;
  right: ${({position}) => position.x - 30}px;
  bottom: ${({position}) => position.y}px;
`;

const ModalContainer = styled.div`
  align-items: center;
  background: #ffffff; 
  border-radius: 10px;
  > svg {
    position: absolute;
    /* top: 1000px; */
    /* right: 100px; */
    z-index: 1000;
    color: #908E8A;
    border: 1px solid #ffffff;
    border-radius: 50%;
    background:rgb(24, 29, 249);
    font-size: 50px;
    font-weight: bold;
    line-height: 42px;
    text-align: center;
    cursor:pointer;
    transition: background-color linear 200ms; 
  }
`;

const CancelButtonContainer = styled.div`
`;

const MessageContainer = styled.div`
  display: flex; 
  flex-direction: column;
  align-items: center;
  /* padding: 24px; */
  margin: 32px 32px 0 32px;
  > *+* {
    margin-top: 8px;
  }

  > div {
    /* align-self: center; */
    font-size: 16px;
    font-weight: bold;
  }
`;

const SaveAndNoSaveButtonsContainer = styled.div`
  display: flex;
  /* margin: 16px 32px 16px 32px; */
  margin: 32px;
  > *+* {
    margin-left: 16px;
  }
  /* div:first-child{
    flex: 10;
  } */

  > div {
    padding: 16px 24px;
    background-color: #F1F1F1;
    border-radius: 10px;
    cursor: pointer;
  }

`;

const DoneEditingWarning = ({saveHandler, noSaveHandler, position, isColorModal=null}) => {
  return (
    <>
      {isColorModal ? (
        <FloatingModalContainerV6 position={position}>
          <ModalContainer> 
            <CancelButtonContainer>
              {/* <svg width="50px" height="50px" viewBox="-150 40 800 400" xmlns="http://www.w3.org/2000/svg">
                <polygon fill="var(--ci-primary-color, currentColor)" points="427.314 107.313 404.686 84.687 256 233.373 107.314 84.687 84.686 107.313 233.373 256 84.686 404.687 107.314 427.313 256 278.627 404.686 427.313 427.314 404.687 278.627 256 427.314 107.313" className="ci-primary"/>
              </svg> */}
            </CancelButtonContainer>
            <MessageContainer>
              <div>{'It looks like you\'re done editing!'}</div>
              <div>{'Click save changes to keep your progress'}</div>
            </MessageContainer>
            <SaveAndNoSaveButtonsContainer>
              <div onClick={noSaveHandler}>{'Don\'t save and leave'}</div>
              <div onClick={saveHandler}>{'Save changes'}</div>
            </SaveAndNoSaveButtonsContainer>
          </ModalContainer>
        </FloatingModalContainerV6>
      ) : (
        <FloatingModalContainerV5 position={position}>
          <ModalContainer> 
            {/* ? What is this used for  */}
            <CancelButtonContainer>
              {/* <svg width="50px" height="50px" viewBox="-150 40 800 400" xmlns="http://www.w3.org/2000/svg">
                <polygon fill="var(--ci-primary-color, currentColor)" points="427.314 107.313 404.686 84.687 256 233.373 107.314 84.687 84.686 107.313 233.373 256 84.686 404.687 107.314 427.313 256 278.627 404.686 427.313 427.314 404.687 278.627 256 427.314 107.313" className="ci-primary"/>
              </svg> */}
            </CancelButtonContainer>
            <MessageContainer>
              <div>{'It looks like you\'re done editing!'}</div>
              <div>{'Click save changes to keep your progress'}</div>
            </MessageContainer>
            <SaveAndNoSaveButtonsContainer>
              <div onClick={noSaveHandler}>{'Don\'t save and leave'}</div>
              <div onClick={saveHandler}>{'Save changes'}</div>
            </SaveAndNoSaveButtonsContainer>
          </ModalContainer>
        </FloatingModalContainerV5>
      )}
    </>
  )
}

export default DoneEditingWarning; 