import * as THREE from 'three';
import { OrbitControls } from './OrbitControls';
import { TransformControls } from './TransformControls';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader'
import { HorizontalBlurShader,  } from 'three/examples/jsm/shaders/HorizontalBlurShader.js'
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { VerticalBlurShader } from 'three/examples/jsm/shaders/VerticalBlurShader.js';
import { normalizeModelScale, placeModelOnFloor } from './helpers/scene-editor/models';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';
import { RayysLinearDimension } from './comparisonClasses/RaysLinearDimension';
import { renderLines } from './comparisonClasses/measurementHelpers';
import { generateOrbitBallProperties } from './helpers/scene-editor/orbitBall';
import { 
  generateTranslateGizmoMeshes, generateTranslateMenuMeshes, 
  generateScaleGizmoMeshes, generateScaleMenuMeshes,
  generateRotateGizmoMeshes, generateRotateMenuMeshes,
} from './helpers/scene-editor/transformControls';
import { toggleModelVisibility } from './helpers/scene-editor/dragAndDropObjects';
import { ComparisonGroup } from './comparisonClasses/ComparisonGroup';
import { setUpLights } from './helpers/scene-editor/lighting';

// @todo: refactor.
function nestedObjectToScreenXY(obj, camera, width, height) {
	var vector = new THREE.Vector3();
	vector.setFromMatrixPosition( obj.matrixWorld );
	var widthHalf = width / 2;
	var heightHalf = height / 2;
	vector.project(camera);
	vector.x = (vector.x * widthHalf) + widthHalf;
	vector.y = - (vector.y * heightHalf) + heightHalf;
	return new THREE.Vector2(vector.x, vector.y);
};
  
const updateRendererAndCameraOnResize = (renderer, camera, width, height) => {
  renderer.setSize(width, height);
  camera.aspect = width / height;
  camera.updateProjectionMatrix();
};

export const initializeScene = ({
  params, 
  webGLContainerRef,
  orbitBallWebGLContainerRef, // @note - Lewis OrbitBall
  // currentCustomerViewWebGLContainerRef, // @note - Lewis currentCustomerView
  app,
  context, // @todo: rename to threeJS or something?
  values: {
    selectedLightingOption,
  },
  callbacks: {
    setHotspotPositionByIDs,
    setHotspotOpacityByIDs,
  },
}) => {
  context.width = webGLContainerRef.current.clientWidth;
  context.height = webGLContainerRef.current.clientHeight;

  let { width, height } = context; 

  let animationFrameRequestID = null;

  context.scene = new THREE.Scene();
  const { scene } = context; // @note: here to simplify code. (prevent needless added impact of react.)

  context.sceneCamera = new THREE.PerspectiveCamera(50, width / height, 0.1, 100);
  const { sceneCamera } = context;
  sceneCamera.layers.enable(0);
  sceneCamera.layers.enable(1);

  //
  //// * SCENE RENDERER
  //
  context.sceneRenderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: false, alpha: true });
  const { sceneRenderer } = context;
  sceneRenderer.setClearColor( 0xffffff, 0);

  sceneRenderer.setSize(width, height);
  sceneRenderer.shadowMap.enabled = true;
  sceneRenderer.shadowMap.type = THREE.PCFSoftShadowMap;
  sceneRenderer.physicallyCorrectLights = true;
  sceneRenderer.toneMapping = THREE.ACESFilmicToneMapping;
  sceneRenderer.toneMappingExposure = 1;
  const mPmremGenerator = new THREE.PMREMGenerator(sceneRenderer);
  mPmremGenerator.compileEquirectangularShader();
  sceneRenderer.outputEncoding = THREE.GammaEncoding;
  webGLContainerRef.current.appendChild(sceneRenderer.domElement);

  // @note Lewis - For brightness slider in lighting options
  context.sceneBrightnessLight = new THREE.PointLight(0xffffff, 1)
  const { sceneBrightnessLight } = context; 
  sceneBrightnessLight.lookAt(new THREE.Vector3(0,0,0))
  scene.add(sceneBrightnessLight);
  // @note Lewis - OrbitBall
  context.orbitBallScene = new THREE.Scene();
  const { orbitBallScene } = context; 
  context.orbitBallCamera = new THREE.PerspectiveCamera(45, width/height , 0.1, 100);
  const { orbitBallCamera } = context;
  orbitBallCamera.position.z += 1.5

  context.orbitBallRenderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
  const { orbitBallRenderer } = context;
  orbitBallRenderer.setSize(width * 0.25, height * 0.25); // @todo: put in config later.
  orbitBallRenderer.setClearColor(0x000000, 0);
  orbitBallRenderer.shadowMap.enabled = true;
  orbitBallRenderer.shadowMap.type = THREE.PCFSoftShadowMap;
  orbitBallWebGLContainerRef.current.appendChild(orbitBallRenderer.domElement);

  // @note Lewis - Current Customer View
  context.currentCustomerViewCamera = new THREE.PerspectiveCamera(45, 1000/177 , 0.1, 100);
  const { currentCustomerViewCamera } = context;
  currentCustomerViewCamera.position.x = context.views[context.selectedView].cameraPosition.x
  currentCustomerViewCamera.position.y = context.views[context.selectedView].cameraPosition.y
  currentCustomerViewCamera.position.z = context.views[context.selectedView].cameraPosition.z
  currentCustomerViewCamera.lookAt(new THREE.Vector3(0,0,0))

  // currentCustomerViewCamera.layers.disable(1)

  context.currentCustomerViewRenderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
  const { currentCustomerViewRenderer } = context;

  currentCustomerViewRenderer.setClearColor(0x000000, 0);
  currentCustomerViewRenderer.shadowMap.enabled = true;
  currentCustomerViewRenderer.shadowMap.type = THREE.PCFSoftShadowMap;
  currentCustomerViewRenderer.physicallyCorrectLights = true;
  currentCustomerViewRenderer.toneMapping = THREE.ACESFilmicToneMapping;
  currentCustomerViewRenderer.toneMappingExposure = 2.5;
  const currentCustomerViewWebGLContainerRef = document.getElementById('currentCustomerViewWebGLContainerRef');
  currentCustomerViewWebGLContainerRef.appendChild(currentCustomerViewRenderer.domElement)
  
  context.cameraControls = new OrbitControls(sceneCamera, sceneRenderer.domElement, context);
  const { cameraControls } = context;
  cameraControls.enabled = false;

  cameraControls.maxDistance = 10;
  cameraControls.minDistance = 1;
  
  context.sceneGroup = new THREE.Group();
  const { sceneGroup } = context;
  scene.add(sceneGroup);

  context.lightingGroup = new THREE.Group();
  const { lightingGroup } = context;
  sceneGroup.add(lightingGroup);

  // @note: refactor below
  const ambientLight = new THREE.AmbientLight(0xffffff);
  ambientLight.layers.set(0);
  context.lightingGroup.add(ambientLight);

  context.modelWrapperGroup = new THREE.Group();
  const { modelWrapperGroup } = context;
  sceneGroup.add(modelWrapperGroup);

  const hotspotGeometry = new THREE.CircleGeometry( 0.05, 32 );

  context.hotspotMeshByHotspotID = {};
  context.hotspotMeshes = [];
  context.hotspotIDByHotspotMeshID = {};
  context.hotspotOutlineMeshByHotspotID = {}; // @todo: later do this. use LineBasicMaterial?
  // https://stackoverflow.com/questions/13756112/draw-a-circle-not-shaded-with-three-js

  context.createHotspotMesh = (hotspot) => {
    const hotspotMaterial = new THREE.MeshBasicMaterial( { color: 0xeeeeee, transparent: true, opacity: 0 } ); // @todo: change color, etc.
    const hotspotMesh = new THREE.Mesh( hotspotGeometry, hotspotMaterial ); // @todo: context here if needed.
    hotspotMesh.position.x = hotspot.location.x;
    hotspotMesh.position.y = hotspot.location.y;
    hotspotMesh.position.z = hotspot.location.z;
    modelWrapperGroup.add(hotspotMesh);
    context.hotspotMeshByHotspotID[hotspot.id] = hotspotMesh;
    context.hotspotMeshes.push(hotspotMesh);
    context.hotspotIDByHotspotMeshID[hotspotMesh.uuid] = hotspot.id;
  }

  // ..

  context.modelGroup = new THREE.Group();
  const { modelGroup } = context;
  modelWrapperGroup.add(modelGroup);
  context.themeModelsGroup = new THREE.Group();
  const { themeModelsGroup } = context;
  modelWrapperGroup.add(themeModelsGroup);  
  context.cubeTextureloader = new THREE.CubeTextureLoader();
  const { cubeTextureloader } = context;

  context.environmentMap = cubeTextureloader.load(selectedLightingOption.environmentMapImages);

  context.selectedBackgroundTexture = new THREE.CubeTextureLoader();

  // .. Measurement && Comparison Grouping
  context.comparisonGroup = new ComparisonGroup(scene, context.cameraControls);

  // scene.environment = environmentMap;
  // scene.background = environmentMap; // @note: here for testing.
  
  // @todo: abstract out into a dedicated function for model loading.
  // - handle various formats internally.
 // maybe it can return object? or pass it a key? eh. uuid?

 context.arrowGroup = new THREE.Group();
 context.arrowGroup.name = 'arrows';
 context.arrowMeshes = [];
  
  // @note: - Lewis Infinite plane for drag and drop items 
  const infinitePlaneGeometry = new THREE.PlaneGeometry( 999999, 999999, 1, 1 );
  const infinitePlaneMaterial = new THREE.MeshStandardMaterial( { color: 0xffffff, transparent: true } );
  infinitePlaneMaterial.opacity = 0;
  const infinitePlane = new THREE.Mesh( infinitePlaneGeometry, infinitePlaneMaterial );
  infinitePlane.visible = false;
  infinitePlane.rotation.x = -Math.PI / 2;
  infinitePlane.position.y -= 0.1;
  sceneGroup.add( infinitePlane );

  context.currentlyAttachedScene = null;

  context.dragAndDropMeshes = [];
  context.countOfEachModelOnScene = {};
  // context.modelScenes = [];
  context.mainModel = null; 
 context.addModelToGroup = (modelFilePath, group_, shouldPlaceOnFloor=false, modelObjectInfo=null, name='', onLoad = () => {}) => {
  const modelObject = {};
  // @todo: abstract out into a dedicated function for model loading.
  // - handle various formats internally.
  const modelFileExtension = modelFilePath.split('.').reverse()[0];
  // if (modelFileExtension === 'glb') {
  if (modelFileExtension === 'glb' || modelFileExtension === 'gltf') {
    const gltfLoader = new GLTFLoader();
    gltfLoader.load(modelFilePath, (gltfModel) => {
      // console.log('Finished Loading Model.');
      modelObject.model = gltfModel;
      gltfModel.scene.name = 'model_root';
      if (shouldPlaceOnFloor) {
        group_.add(placeModelOnFloor(normalizeModelScale(gltfModel.scene, true)));
      } else {
        group_.add(normalizeModelScale(gltfModel.scene));
        gltfModel.scene.position.y += 5;
      };

      if (!!modelObjectInfo) {
        if (!context.countOfEachModelOnScene[modelObjectInfo.title]) {
          context.countOfEachModelOnScene[modelObjectInfo.title] = 1; 
        } else {
          context.countOfEachModelOnScene[modelObjectInfo.title]++; 
        }
        context.currentlyAttachedScene = gltfModel.scene; 
        context.currentlyAttachedScene.belongsTo = modelObjectInfo.title + ' ' + context.countOfEachModelOnScene[modelObjectInfo.title];
      };
      
      group_.traverse((node) => {
        if (node.isMesh) {
          if (name === 'model_main') {
            context.mainModel = node; 
          } else {
          }
          node.material.envMapIntensity = 8;
          // @note: Lewis - this is causing all drag and drop objects to shift everytime a new one is placed
          // node.position.y = -5;
          node.name = name;
          // node.material.envMap = environmentMap;
          // @note: Lewis - this sets brightness for objects on scene
          node.castShadow = true;
          node.receiveShadow = false; // @todo: rethink?
        };
        
        if (node.isMesh && !modelObjectInfo) {
          node.layers.enable(10);
        }
        
        if (node.isMesh && !!modelObjectInfo && !node.alreadyAddedToDragAndDropMeshes) {
          node.belongsTo = modelObjectInfo.title + ' ' + context.countOfEachModelOnScene[modelObjectInfo.title];
          // context.currentlyAttachedScene = node; 
          context.dragAndDropMeshes.push(node);
          node.alreadyAddedToDragAndDropMeshes = true;        
        } 
      }); // @todo: might bring this OUT of this function. maybe. or if-block even.
      // // @todo: modify?
      
      if (context.currentlyAttachedScene) {
        transformControls.attach(gltfModel.scene);
      };
      onLoad(modelObject); // @todo: later async/await likely.
    }, (progress) => {
      // console.log('Loading Model:', ((progress.loaded / 1024) / 1024), 'MB');
    }, (error) => {
      console.error(error);
    });
  } else if (modelFileExtension === 'fbx') {
    const fbxLoader = new FBXLoader();
    fbxLoader.load(modelFilePath, (fbxModel) => {
      modelObject.model = fbxModel;

      if (fbxModel.animations.length > 0) {
        context.mixer = new THREE.AnimationMixer(fbxModel);
        // const { mixer } = context;

        context.modelAnimations = [...fbxModel.animations];
        // const { modelAnimations } = context;
      }

      group_.add(placeModelOnFloor(normalizeModelScale(fbxModel)));
      group_.traverse((node) => {
        if (node.isMesh) {
          node.material.envMapIntensity = 15;
          node.castShadow = true;
          node.receiveShadow = false;
        }
      });
      onLoad(modelObject);
    }, (progress) => {
      // console.log('Loading Model:', ((progress.loaded / 1024) / 1024), 'MB');
    }, (error) => {
      console.error(error);
    });
  };
};

const { addModelToGroup } = context; 

context.isSceneSaved = false; 
context.hasSceneChanged = false; 
context.currentSceneObjects = [];

/*  
  used whenever a drag and drop object is added to the scene
  modelObjectInfo: {
    title: string
    slug: string
    iconPath: url string
    modelPath: url string
  }
  modelScene = true if this is a presaved load of objects
  isLocked: boolean
  isVisible: boolean
  isLoadedOutsideEditor: boolean
  name: string - provide name for object
*/
context.addModelToScene = (modelObjectInfo, modelScene=null, isLocked_, isVisible_, isLoadedOutsideEditor=null, name='') => {
  addModelToGroup(modelObjectInfo.modelFilePath, themeModelsGroup, false, modelObjectInfo, name='', (modelObject) => {
    let model = modelObject.model;    
    let isVisible = isVisible_;
    let isLocked = isLocked_; 

    // @note: Lewis - modelScene = true if this is a presaved load of objects
    if (!modelScene) {
      const { onDropMouseCoordinates: e, sceneRenderer } = context; 
      const canvas = sceneRenderer.domElement;
      const canvasPosition = canvas.getBoundingClientRect();
      const mousePosition = new THREE.Vector2(); 
      mousePosition.set(
        ((e.clientX - canvasPosition.left) / canvas.width) * 2 - 1,
        -((e.clientY - canvasPosition.top) / canvas.height) * 2 + 1,
        0.5 );
      const rayCaster = new THREE.Raycaster();
      rayCaster.setFromCamera(mousePosition, sceneCamera);
      let intersects = rayCaster.intersectObject(infinitePlane);
      if (intersects.length > 0) {
        model.scene.position.x = intersects[0].point.x; 
        model.scene.position.y = intersects[0].point.y; 
        model.scene.position.z = intersects[0].point.z; 
      };
    } else {
      context.currentlyAttachedScene.position.copy(modelScene.position);
      context.currentlyAttachedScene.rotation.copy(modelScene.rotation);
      context.currentlyAttachedScene.scale.copy(modelScene.scale);
    };
    
    const newObjectOnScene = {
      id: context.currentlyAttachedScene.uuid,
      visible: isVisible,
      locked: isLocked, 
      label: modelObjectInfo.title + ' ' + context.countOfEachModelOnScene[modelObjectInfo.title],
      modelScene: context.currentlyAttachedScene, 
      modelObjectInfo: modelObjectInfo,
    }; 
    context.currentSceneObjects.push(newObjectOnScene);
    toggleModelVisibility(newObjectOnScene, context);
    context.setSelectedSceneObject(newObjectOnScene.label);

    if (!isLoadedOutsideEditor && isVisible) {
      sceneGroup.add(transformControlsGizmoMeshesGroup, transformControlsMenuMeshesGroup);
    } else {
      context.transformControls.detach();
      sceneGroup.remove(transformControlsGizmoMeshesGroup, transformControlsMenuMeshesGroup)
    };
  });
};

addModelToGroup(params.modelFilePath, modelGroup, true, null, 'model_main');

context.addPreLoadedModels = (modelObjectInfo, isSceneEditor=false) => {
  addModelToGroup(modelObjectInfo.modelFilePath, themeModelsGroup, false, modelObjectInfo, '', (modelObject) => {
    const model = modelObject.model; 
    model.scene.position.x = modelObjectInfo.position.x;
    model.scene.position.y = modelObjectInfo.position.y;
    model.scene.position.z = modelObjectInfo.position.z;

    if (modelObjectInfo.scale) {
      model.scene.scale.copy(modelObjectInfo.scale); 
    }
    context.currentlyAttachedScene = model.scene; 

    const newObjectOnScene = {
      id: context.currentlyAttachedScene.uuid,
      visible: modelObjectInfo.isVisible,
      locked: modelObjectInfo.isLocked, 
      label: modelObjectInfo.title + ' ' + context.countOfEachModelOnScene[modelObjectInfo.title],
      modelScene: context.currentlyAttachedScene, 
      modelObjectInfo: modelObjectInfo,
    }; 
    
    context.setScenes(prev => {
      const copy = {...prev};
      copy[context.selectedScene].sceneObjects.push(newObjectOnScene);
      copy[context.selectedScene].preLoadedObjects[modelObjectInfo.index].isLoaded = true; 
      return copy; 
    });
      context.transformControls.detach();
      sceneGroup.remove(transformControlsGizmoMeshesGroup, transformControlsMenuMeshesGroup)
  })
}

// @note: Lewis - helper functions for scene objects here
// Commented this line out, rotates modelGroup to not be facing the camera
// modelGroup.rotation.y = Math.PI * (params.initialSceneTurnTableRotationAngle / 180);
  const initialCameraDistance = 3;
  sceneCamera.position.z = initialCameraDistance;
  sceneCamera.position.y = 1;
  setUpLights(lightingGroup, context);

  // @note: Lewis - productShadowIntensity and productShadowBlur start here

  // setUpShadows(scene);
  let shadowGroup, renderTarget, renderTargetBlur, shadowCamera, depthMaterial, horizontalBlurMaterial, verticalBlurMaterial;
  // let blurPlane, plane, fillPlane;
  let blurPlane, plane;
  shadowGroup = new THREE.Group();
  shadowGroup.position.y = - 0.3;
  scene.add( shadowGroup );
  renderTarget = new THREE.WebGLRenderTarget( 2048, 2048 );
  renderTarget.texture.generateMipmaps = false;
  renderTargetBlur = new THREE.WebGLRenderTarget( 2048, 2048 );
  renderTargetBlur.texture.generateMipmaps = false;
  const planeSize = 4; 
  const planeGeometry = new THREE.PlaneGeometry( planeSize, planeSize ).rotateX( Math.PI / 2 );

  const planeMaterial = new THREE.MeshBasicMaterial( {
    map: renderTarget.texture,
    opacity: 0,
    // opacity: context.productShadowIntensity,
    transparent: true,
    depthWrite: false,
  } );
  plane = new THREE.Mesh( planeGeometry, planeMaterial);
  plane.renderOrder = 1;
  shadowGroup.add( plane );
  plane.scale.y = - 2;

  const size = 4;
  const divisions = 16;

  context.gridHelper = new THREE.GridHelper( size, divisions, 0x888888 );
  const { gridHelper } = context;
  gridHelper.material.visible = false;
  gridHelper.position.y -= 0.31;
  gridHelper.layers.set(15);
  sceneCamera.layers.enable(15);
  sceneGroup.add( gridHelper );

  blurPlane = new THREE.Mesh( planeGeometry );
  blurPlane.color = '#FFFFFF';
  blurPlane.visible = false;
  shadowGroup.add(blurPlane);

  const fillPlaneMaterial = new THREE.MeshBasicMaterial({
    // color: '#c9c9c9',
    opacity: 0,
    transparent: true,
    depthWrite: false,
  });

  context.fillPlane = new THREE.Mesh( planeGeometry, fillPlaneMaterial );
  const { fillPlane } = context; 
  fillPlane.rotateX( Math.PI );
  shadowGroup.add( fillPlane );
  shadowCamera = new THREE.OrthographicCamera( - planeSize / 2, planeSize / 2, planeSize / 2, - planeSize / 2, 0, 0.3 );
  shadowCamera.rotation.x = Math.PI / 2;
  shadowGroup.add( shadowCamera );
  depthMaterial = new THREE.MeshDepthMaterial();
  depthMaterial.userData.darkness = { value: 5 }; // @todo: arbitrarily chosen 5 over 1. figure out best methodology later.
  depthMaterial.onBeforeCompile = function ( shader ) {
    shader.uniforms.darkness = depthMaterial.userData.darkness;
    shader.fragmentShader = /* glsl */`
      uniform float darkness;
      ${shader.fragmentShader.replace(
    'gl_FragColor = vec4( vec3( 1.0 - fragCoordZ ), opacity );',
    'gl_FragColor = vec4( vec3( 0.0 ), ( 1.0 - fragCoordZ ) * darkness );'
  )}
    `;
  };

  depthMaterial.depthTest = false;
  depthMaterial.depthWrite = false;

  horizontalBlurMaterial = new THREE.ShaderMaterial(HorizontalBlurShader);
  horizontalBlurMaterial.depthTest = false;

  verticalBlurMaterial = new THREE.ShaderMaterial(VerticalBlurShader);
  verticalBlurMaterial.depthTest = false;
  
  // renderTarget --> blurPlane (horizontalBlur) --> renderTargetBlur --> blurPlane (verticalBlur) --> renderTarget
  function blurShadow( amount ) {
    blurPlane.visible = true;

    // blur horizontally and draw in the renderTargetBlur
    blurPlane.material = horizontalBlurMaterial;
    blurPlane.material.uniforms.tDiffuse.value = renderTarget.texture;
    horizontalBlurMaterial.uniforms.h.value = amount * 1 / 256;

    sceneRenderer.setRenderTarget( renderTargetBlur );
    sceneRenderer.render( blurPlane, shadowCamera ); 

    // blur vertically and draw in the main renderTarget
    blurPlane.material = verticalBlurMaterial;
    blurPlane.material.uniforms.tDiffuse.value = renderTargetBlur.texture;
    verticalBlurMaterial.uniforms.v.value = amount * 1 / 256;

    sceneRenderer.setRenderTarget( renderTarget );
    sceneRenderer.render( blurPlane, shadowCamera );

    blurPlane.visible = false;
  };
  // @note: Lewis - productShadowIntensity and productShadowBlur end here

  // @note: Lewis - tween for timed camera transitions
  context.tween2 = new TWEEN.Tween(sceneCamera.position);
  context.isTweenRunning = false;

  // @note: Lewis - start of orbitBall
    const orbitBallProperties = generateOrbitBallProperties(THREE);
    context.orbitBallGroup = orbitBallProperties.orbitBallGroup; 
    context.orbitBallMeshes = orbitBallProperties.orbitBallMeshes; 
    context.hoverSpokeMaterial = orbitBallProperties.hoverSpokeMaterial; 
    context.originalSpokeMaterial = orbitBallProperties.originalSpokeMaterial; 
    const { orbitBallGroup } = context; 
    orbitBallScene.add(orbitBallGroup);
    orbitBallScene.background = scene.background;
    // orbitBallGroup.position.y += 1;
  // @note: Lewis - end of orbitBall

  context.originalObjectColor = null;
  context.hoverMaterials = {}


  // Label Renderer
  // @note: Brandon - refactor
  const labelRenderer = new CSS2DRenderer();
  labelRenderer.domElement.style.position = 'absolute';
  labelRenderer.domElement.classList.add('labelRenderer');
  labelRenderer.domElement.style.top = '0px';
  labelRenderer.domElement.style.right = '0px';
  labelRenderer.domElement.style.left = '320px';
  labelRenderer.domElement.style.pointerEvents = 'none';
  labelRenderer.setSize(context.width, context.height);
  document.body.appendChild(labelRenderer.domElement);


  // @note: Brandon - refactor
  let dim0 = new RayysLinearDimension(document.body, sceneRenderer, sceneCamera, `${context.objMeasurements.width}`, `${context.objMeasurements.length}`, `${context.objMeasurements.height}`, `${context.objMeasurements.unit}`, scene);
  let dim1 = new RayysLinearDimension(document.body, sceneRenderer, sceneCamera, `${context.objMeasurements.width}`, `${context.objMeasurements.length}`, `${context.objMeasurements.height}`, `${context.objMeasurements.unit}`, scene);

  context.dimensionLines = [dim0, dim1];

  context.renderScene = () => {
    labelRenderer.setSize(context.width, context.height);
    sceneRenderer.autoClear = false;
    // sceneRenderer.render(context.dragAndDropHoverScene, sceneCamera);
    effectComposer.render();
    orbitBallRenderer.render(orbitBallScene, orbitBallCamera);
    currentCustomerViewRenderer.render(scene, currentCustomerViewCamera);
    sceneRenderer.autoClear = true;
    // @note: Brandon - Possible refactor, better spot than in render function?
    if(context.showMeasurements) {
      renderLines(scene, context, sceneCamera, dim0, dim1);
      labelRenderer.render(scene, sceneCamera);
    };
  };

  const { renderScene } = context; 

  // @note: drag and drop gizmos
// GIZMO - gizmo: translate
const translateGizmoMeshes_ = generateTranslateGizmoMeshes(); 
context.translateGizmoMeshes = translateGizmoMeshes_.translateGizmoMeshes; 
context.translateGizmoCenterMesh = translateGizmoMeshes_.translateGizmoCenterMesh; 
const { translateGizmoCenterMesh } = context; 

// GIZMO - menu: translate
const translateMenuMeshes_ = generateTranslateMenuMeshes(); 
context.translateMenuMeshes = translateMenuMeshes_.translateMenuMeshes; 
context.translateMenuCenterMesh = translateMenuMeshes_.translateMenuCenterMesh; 

// GIZMO - gizmo: scale
const scaleGizmoMeshes_ = generateScaleGizmoMeshes(); 
context.scaleGizmoMeshes = scaleGizmoMeshes_.scaleGizmoMeshes; 
context.scaleGizmoCenterMesh = scaleGizmoMeshes_.scaleGizmoCenterMesh; 

// GIZMO - menu: scale
const scaleMenuMeshes_ = generateScaleMenuMeshes(); 
context.scaleMenuMeshes = scaleMenuMeshes_.scaleMenuMeshes; 
context.scaleMenuCenterMesh = scaleMenuMeshes_.scaleMenuCenterMesh; 

// GIZMO - gizmo: rotate
const rotateGizmoMeshes_ = generateRotateGizmoMeshes(); 
context.rotateGizmoMeshes = rotateGizmoMeshes_.rotateGizmoMeshes; 
context.rotateGizmoCenterMesh = rotateGizmoMeshes_.rotateGizmoCenterMesh; 


// GIZMO - menu: rotate
const rotateMenuMeshes_ = generateRotateMenuMeshes(); 
context.rotateMenuMeshes = rotateMenuMeshes_.rotateMenuMeshes; 
context.rotateMenuCenterMesh = rotateMenuMeshes_.rotateMenuCenterMesh; 

context.transformControlsGizmoMeshesGroup = new THREE.Group();
context.transformControlsMenuMeshesGroup = new THREE.Group();
const { transformControlsGizmoMeshesGroup, transformControlsMenuMeshesGroup } = context; 

transformControlsMenuMeshesGroup.add(context.translateMenuMeshes, context.scaleMenuMeshes, context.rotateMenuMeshes); 
transformControlsGizmoMeshesGroup.add(context.translateGizmoMeshes, context.scaleGizmoMeshes, context.rotateGizmoMeshes); 

context.gizmoMeshes = [
  translateGizmoCenterMesh
];

context.transformControls = new TransformControls(sceneCamera, sceneRenderer.domElement, context);
const { transformControls } = context;
transformControls.showX = false;
transformControls.setMode('translate');
context.nameOfSelectedGizmoMeshParentContext = 'translateMenuMeshes';
context.transformControls = transformControls;
scene.add(transformControls);

context.translateMenuMeshes.visible = false;

context.scaleGizmoMeshes.visible = false;
context.scaleMenuMeshes.visible = false;

context.rotateGizmoMeshes.visible = false;
context.rotateMenuMeshes.visible = false;


transformControls.addEventListener("dragging-changed", function (event) {
  cameraControls.enabled = !event.value;
});

window.addEventListener("keydown", function (event) {
  switch (event.key) {
    case 'w': 
      transformControls.setMode("translate");
      break;
      default: return;
  }
  switch (event.key) {
    case 'e': 
      transformControls.setMode("rotate");
      break;
      default: return;
  }
  switch (event.key) {
    case 'r': 
      transformControls.setMode("scale");
      break;
      default: return;
  };
});

context.gizmoMeshes = [];

// @note: end of transform 

// @note: Lewis - start of effect composer for flash on view save. 
const colorifyShader = {
	uniforms: {
		"tDiffuse": { type: "t", value: null },
		"color":    { type: "c", value: new THREE.Color( 0x000ff )}
	},
	vertexShader: [
		"varying vec2 vUv;",
		"void main() {",
			"vUv = uv;",
			"gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );",
		"}"
	].join("\n"),
	fragmentShader: [
		"uniform vec3 color;",
		"uniform sampler2D tDiffuse;",
		"varying vec2 vUv;",
		"void main() {",
			"vec4 texel = texture2D( tDiffuse, vUv );",
			"gl_FragColor = vec4( vec3(texel) + color, texel.w );",
		"}"
	].join("\n")
};
var flashColor = 0;
var flashSpeed = 0.05;

// Effect Composer
const effectComposer = new EffectComposer(sceneRenderer);
const fxaaPass = new ShaderPass(FXAAShader);

const pixelRatio = sceneRenderer.getPixelRatio();

fxaaPass.material.uniforms['resolution'].value.x = 1 / (sceneRenderer.domElement.offsetWidth * pixelRatio);
fxaaPass.material.uniforms['resolution'].value.y = 1 / (sceneRenderer.domElement.offsetHeight * pixelRatio);
const colorifyPass = new ShaderPass(colorifyShader);
colorifyPass.uniforms["color"].value.setRGB(.001, .001, .001);

/* 
  Order of passes added is important,
  ensure effectComposer always ends with renderPass
*/
const renderPass = new RenderPass(scene, sceneCamera);
effectComposer.addPass(fxaaPass)
effectComposer.addPass(colorifyPass);
effectComposer.addPass(renderPass);

// @note: Lewis - start of background post processing 

const saveViewFlashEffectHandler = () => {
  flashColor = 1;
  colorifyPass.uniforms["color"].value.setRGB(flashColor, flashColor, flashColor);
};

// @note: Lewis - start of snapshot
context.takeSnapShot = () => {
  // @note - Lewis: width and height of dataURL is distorted when window is not full screen
  const dataURL = sceneRenderer.domElement.toDataURL();
  const snapShotCameraPosition = new THREE.Vector3().copy(sceneCamera.position);
  const snapShotInfo = {
    dataURL, 
    snapShotCameraPosition,
  };
  saveViewFlashEffectHandler();
  return snapShotInfo;
};

// @note: Lewis - end of snapshot

  const clock = new THREE.Clock();
 
  const handleFrame = () => {

  if (flashColor > 0) {
    flashColor -= flashSpeed;
    colorifyPass.uniforms["color"].value.setRGB(flashColor, flashColor, flashColor);
    
    if (flashColor < 0) {
        flashColor = 0;
    }
  }
// @note: Lewis - end of snapshot

    if (context.shouldAutoRotateModel) {
      if(!context.pauseAutoRotateModel) {
        sceneGroup.rotation.y += (parseFloat(context.modelRotationSpeed) / 360) * 0.2;
        // @todo: improve this later. connect it to fps.
      }
    };

    // @note: Lewis - smooth out model transition by not updating camera when tween is running 
    if (context.isTweenRunning === false) {
      cameraControls.update();
    };

    const delta = clock.getDelta();
    // @todo: re-evaluate?
    if ( context.mixer ) context.mixer.update( delta );

    let didChangeHotspotPositionByIDs = false;
    let didChangeHotspotOpacityByIDs = false;

    // @todo: maybe only do below if camera moved/moving?
    const hotspotPositionByIDs_ = {...context.hotspotPositionByIDsForTJS};
    const hotspotOpacityByIDs_ = {...context.hotspotOpacityByIDsForTJS};
    context.hotspotsForTJS.forEach(hotspot => {
      const hotspotMesh = context.hotspotMeshByHotspotID[hotspot.id];
      if (hotspotMesh) {
        const screenSpacePos = nestedObjectToScreenXY(hotspotMesh,sceneCamera,sceneRenderer.domElement.width,sceneRenderer.domElement.height,true);
        const currentPosition = context.hotspotPositionByIDsForTJS[hotspot.id];
        const newPosition = { x: Math.round(screenSpacePos.x), y: Math.round(screenSpacePos.y) };
        if (!currentPosition || (currentPosition && (newPosition.x !== currentPosition.x && newPosition.y !== currentPosition.y))) {
          hotspotPositionByIDs_[hotspot.id] = { x: newPosition.x, y: newPosition.y };
          didChangeHotspotPositionByIDs = true;
        }
        hotspotMesh.lookAt( sceneCamera.position );
        hotspotMesh.material.depthTest = false;
        hotspotMesh.renderOrder = 1;
        const cameraVector = (new THREE.Vector3( 0, 0, -1 )).applyQuaternion(context.sceneCamera.quaternion);
        // const cameraVector = (new THREE.Vector3( 0, 0, 1 )).applyQuaternion(context.sceneCamera.quaternion);
        const facingVector = new THREE.Vector3( hotspot.facingVector.x, hotspot.facingVector.y, hotspot.facingVector.z );
        if (facingVector.angleTo(cameraVector) > (Math.PI / 2)) {
          if (context.hotspotOpacityByIDsForTJS[hotspot.id] !== 1) {
            hotspotOpacityByIDs_[hotspot.id] = 1; // @todo: could rename to isVisible? and use true/false? isFacing?
            didChangeHotspotOpacityByIDs = true;
          }
        } else {
          if (context.hotspotOpacityByIDsForTJS[hotspot.id] !== 0) {
            hotspotOpacityByIDs_[hotspot.id] = 0;
            didChangeHotspotOpacityByIDs = true;
          }
        }
      }
    });
    if (didChangeHotspotPositionByIDs) setHotspotPositionByIDs(hotspotPositionByIDs_);
    if (didChangeHotspotOpacityByIDs) setHotspotOpacityByIDs(hotspotOpacityByIDs_)
    // @note: Lewis - productShadowIntensity and productShadowBlur start here
    const initialBackground = scene.background;
    scene.background = null;

    scene.overrideMaterial = depthMaterial;

    sceneRenderer.setRenderTarget( renderTarget );
    sceneRenderer.render( scene, shadowCamera );

    scene.overrideMaterial = null;
    
    blurShadow( context.productShadowBlur * 0.05 );
    blurShadow( context.productShadowBlur * 0.01 );

    planeMaterial.opacity = context.productShadowIntensity * 0.01;
    sceneRenderer.setRenderTarget( null );

    scene.background = initialBackground;
    // @note: Lewis - end of blur and shadow intensity

    // @note: Lewis - start of orbitBall
    orbitBallGroup.quaternion.copy(sceneCamera.quaternion).invert();
    // orbitBallCamera.lookAt(0, 0, 0);
    // @note: Lewis - end of orbitBall

    if (context.currentlyAttachedScene) {
      
      const currentlyAttachedSceneWorldPosition = new THREE.Vector3();
      context.currentlyAttachedScene.getWorldPosition(currentlyAttachedSceneWorldPosition);

      context.translateGizmoMeshes.position.copy(currentlyAttachedSceneWorldPosition);
      context.translateGizmoMeshes.lookAt(sceneCamera.position);

      context.scaleGizmoMeshes.position.copy(currentlyAttachedSceneWorldPosition);
      context.scaleGizmoMeshes.lookAt(sceneCamera.position);

      context.rotateGizmoMeshes.position.copy(currentlyAttachedSceneWorldPosition);
      context.rotateGizmoMeshes.lookAt(sceneCamera.position);

      transformControlsMenuMeshesGroup.position.copy(currentlyAttachedSceneWorldPosition);
      transformControlsMenuMeshesGroup.lookAt(sceneCamera.position);

      context.translateMenuMeshes.position.x = 0;
      context.scaleMenuMeshes.position.x = 0;
      context.rotateMenuMeshes.position.x = 0;

      context.translateGizmoMeshes.updateMatrixWorld(true);

      //@note: Lewis - this is for keeping gizmo meshes same scale to screen
      if (context.factor_) {
        context.translateGizmoMeshes.scale.set( 1, 1, 1 ).multiplyScalar( context.factor_ / 2400 );
        context.scaleGizmoMeshes.scale.set( 1, 1, 1 ).multiplyScalar( context.factor_ / 2400 );
        context.rotateGizmoMeshes.scale.set( 1, 1, 1 ).multiplyScalar( context.factor_ / 2400 );
        context.transformControlsMenuMeshesGroup.scale.set( 1, 1, 1 ).multiplyScalar( context.factor_ / 7.2 );
      };

      if (context.nameOfSelectedGizmoMeshParentContext === 'translateGizmoMeshes') {
        transformControls.showX = false; 
        transformControls.showY = false; 
        transformControls.showZ = false; 
        context.scaleMenuMeshes.position.x = 0.6;
        context.rotateMenuMeshes.position.x = 1.2;
        
      } else if (context.nameOfSelectedGizmoMeshParentContext === 'scaleGizmoMeshes') {
        transformControls.showX = false; 
        transformControls.showY = false; 
        transformControls.showZ = false; 
        context.rotateMenuMeshes.position.x = 0.6;
        context.translateMenuMeshes.position.x = -0.6;
        
      } else if (context.nameOfSelectedGizmoMeshParentContext === 'rotateGizmoMeshes') {
        transformControls.showX = false; 
        transformControls.showY = false; 
        transformControls.showZ = false; 

        context.translateMenuMeshes.position.x = -1.2;
        context.scaleMenuMeshes.position.x = -0.6;
    
      } else {
        transformControls.showX = true; 
        transformControls.showY = true; 
        transformControls.showZ = true; 
      }
    };

      TWEEN.update();
      renderScene();

    const fps = 120;
    setTimeout( function() {
      animationFrameRequestID = requestAnimationFrame(handleFrame);
    }, 1000 / fps );
  };

  handleFrame();


  const handleContainerResize = () => {

    // @todo: rename defautl sceneRenderer to sceneRenderer & sceneCamera. or some such.
    updateRendererAndCameraOnResize(sceneRenderer, sceneCamera, context.width, context.height);
    updateRendererAndCameraOnResize(currentCustomerViewRenderer, currentCustomerViewCamera, Math.max(296, context.width * 0.2), Math.max(296, context.height * 0.2));
    updateRendererAndCameraOnResize(orbitBallRenderer, orbitBallCamera, context.width * 0.25, context.height * 0.25); // @todo: 0.25 reconsider.

    renderScene();
  };

  app.handleContainerResize = handleContainerResize;

  const handleWindowResize = () => {
    handleContainerResize();
  };

  window.addEventListener('resize', handleWindowResize);

  const webGLContainerRefToDestroy = webGLContainerRef.current;
  return () => {
    cancelAnimationFrame(animationFrameRequestID);
    window.removeEventListener('resize', handleWindowResize);
    scene.remove(sceneGroup);
    // @todo: research ResourceTracker for proper disposal of gltf assets like geometry.dispose().
    webGLContainerRefToDestroy.removeChild(sceneRenderer.domElement);
  };
};