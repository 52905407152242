import { useState } from 'react'
import styled from 'styled-components';
import { DropDownContainer, DropDownListContainer, DropDownListItemContainer, SelectedObjectContainer } from './shared'

const DropDownInputContainer = styled.div`
display: flex;
flex-direction: colunmn;
box-sizing: border-box;
cursor: pointer;
margin: auto;
width: 80%;
`


const DropDownInput = ({ active, items, setItem}) => {
  const [showDropDown, setShowDropDown] = useState(false);

  return (
    <DropDownInputContainer>
      <DropDownContainer shouldShowDropDown={showDropDown}>
        <SelectedObjectContainer shouldShowDropDown={showDropDown} onClick={() => {
          setShowDropDown(!showDropDown)
        }}>
        <div>{active}</div>
        <div>
          <img src='images/icons/scene-editor-objects/arrow_up_down.svg' alt='up-down-arrow'/>
        </div>
        </SelectedObjectContainer>
        <DropDownListContainer>
          {items.map((unit, index) => (
            <DropDownListItemContainer key={index} id={index} onClick={() => {
              setShowDropDown(false);
              setItem(unit);
            }}>
              {unit}
            </DropDownListItemContainer>
          ))}
        </DropDownListContainer>
      </DropDownContainer>
    </DropDownInputContainer>
  )
}

export default DropDownInput