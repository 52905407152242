import { useState, useContext } from "react";
import styled from "styled-components";
import "rc-slider/assets/index.css";
import { AppContext } from "../../../App";

const SingleSelectInputContainer = styled.div`
  padding: 0 32px;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1023px) {
    justify-content: center;
  }
  @media (max-width: 639px) {
    padding: 0;
    margin-left: 11.43px;
    /* @todo: remove this fix later if better alternative - here for 'Night Mode' */
  }
`;

const SingleSelectOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  cursor: pointer;
  & > div {
    background-color: ${({ backgroundColor }) =>
      `${backgroundColor}!important`};
    width: 56px;
    box-sizing: border-box;
    min-height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 56px;
    border: 1px solid #666;
    ${({ isSelected }) =>
      isSelected &&
      `
      border: 5px solid #ffffff;
      box-shadow: 0 0 0 1px #666; 
    `}
    @media (max-width: 639px) {
      width: 48px;
      min-height: 48px;
      border-radius: 48px;
    }
  }
  & > div > img {
    width: 40px;
  }
  & > p {
    color: #444;
    font-size: 14px;
    margin: 8px 0 16px;
    white-space: nowrap;
    @media (max-width: 639px) {
      /* .. */
    }
  }
  ${({ isSelected }) =>
    isSelected &&
    `
    & > div {
      background-color: rgba(0, 0, 0, 0.1);
    }
  `}
  ${({ shouldShowHoverState }) =>
    shouldShowHoverState &&
    `
    & > div {
      background-color: rgba(0, 0, 0, 0.05);
    }
  `}
  &:active {
    & > div {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  .color-display {
  }
  /* @todo: apply to icon. border shadow etc */
  @media (max-width: 1023px) {
    width: initial;
    padding: 0 12px;
  }
  @media (max-width: 639px) {
    padding: 0 8px;
  }
  &:last-child {
    @media (max-width: 639px) {
      margin-left: -11.43px;
    }
  }
`;

const SubTitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div:first-child {
    align-self: center;
    color: black;
    font-weight: 700;
    font-size: 14px;
    margin: 24px;
  }
`;

const SingleSelectOption = ({ option, onSelect, isSelected }) => {
  const [shouldShowHoverState, setShouldShowHoverState] = useState(false);

  return (
    <>
      <SingleSelectOptionContainer
        backgroundColor={
          option && option.isColorMostRecentlySelected && option.hex
        }
        onMouseEnter={() => setShouldShowHoverState(true)}
        onMouseLeave={() => setShouldShowHoverState(false)}
        onClick={() => {
          if (option) {
            setShouldShowHoverState(false);
            onSelect(option.title);
          }
        }}
        isSelected={isSelected}
        shouldShowHoverState={shouldShowHoverState}
      >
        <div>
          {option && !option.isColorMostRecentlySelected && (
            <img
              alt={option}
              src={option && option.imageFileInput && option.imageFileInput.src}
            />
          )}
        </div>
        <p>{option && option.color}</p>
      </SingleSelectOptionContainer>
    </>
  );
};

const SingleSelectInput = ({ options, optionsTitles }) => {
  const [selectedOptionTitle, setSelectedOptionTitle] = useState(
    options[optionsTitles[0]]
  );

  return (
    <SingleSelectInputContainer>
      {optionsTitles
        .filter((option) => option !== "Add another")
        .map((option, optionIndex) => (
          <SingleSelectOption
            key={optionIndex}
            option={options[option]}
            isSelected={selectedOptionTitle === option}
            onSelect={setSelectedOptionTitle}
          />
        ))}
    </SingleSelectInputContainer>
  );
};

const SelectColourPanel = ({}) => {
  const {
    // @todo: might move these into CustomerOptionsContext or such.
    colorSwatchesTitles,
    // colorSwatches, setColorSwatches,
    colorComponentTitles,
    isSingleComponent,
    unSavedComponents,
    selectedComponent,
  } = useContext(AppContext);

  return (
    <div>
      {isSingleComponent ? (
        <SingleSelectInput
          options={unSavedComponents}
          optionsTitles={colorSwatchesTitles}
        />
      ) : (
        <>
          <SubTitleContainer>
            <div>{"Top Option"}</div>
            <SingleSelectInput
              options={unSavedComponents[selectedComponent].swatches}
              optionsTitles={unSavedComponents[selectedComponent].swatchTitles}
            />
          </SubTitleContainer>
          <SubTitleContainer>
            <div>{"Top Colours"}</div>
            <SingleSelectInput
              options={unSavedComponents}
              optionsTitles={colorComponentTitles}
            />
          </SubTitleContainer>
        </>
      )}
    </div>
  );
};

export default SelectColourPanel;
