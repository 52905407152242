import { useState } from 'react'
import styled from 'styled-components';
import config from '../../../config';
import ColorPickerV2 from '../modals/ColorPickerV2';
import ButtonBase from '../inputs/buttons/ButtonBase';
import { 
  FloatingModalContainer, 
  CancelAndSaveButtonsContainer, ModalContainer, 
  OptionButtonContainer 
} from '../modals/shared';
import UploadBackgroundImage from '../modals/UploadBackgroundImage';
import { TabSelector, TabContainer, RadioButton } from '../panels/shared';
import { loadNewBackgroundDisplayImage, loadNewBackgroundDisplayImageForExr, loadNewBackgroundDisplayImageForHdr } from '../../../threejs/helpers/scene-editor/changeBackgroundDisplay';
import ColorPickerV4 from '../modals/ColorPickerV4';

const TopOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  .tab-selector {
  }
`;
const RadioButtonsContainer = styled.div`
  display: ${({visible}) => !visible ? 'flex' : 'none' };
  flex-direction: column;
  margin-top: 16px;
  background: #F9F9F9;
  padding: 24px 36px;
`;
const RadioButtonAndLabelContainer = styled.div`
  display: flex;
  > *+* {
    margin-left: 16px;
  }
`;

const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid #E8E8E8;
  > div:nth-child(2) {
    display: ${({ shouldShowDropDown }) => shouldShowDropDown ? 'block' : 'none'}
  };
`;

const SelectedBackgroundContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  border-bottom: ${({ shouldShowDropDown }) => shouldShowDropDown ? '1px solid #E8E8E8' : 'none'};
  padding: 12px 16px 8px;
  border-radius: 5px;
  justify-content: space-between;
  > *+* {
    padding-left: 16px;
  }
  .arrows-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2px;
    .arrow-up {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #C4C4C4;
    }
    .arrow-down {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #C4C4C4;
    }
  }
`;

const DropDownListContainer = styled.div`
  text-align: center;
  overflow: auto; 
  max-height: 124px;
  > div:last-child {
    border-bottom: none;
  }
`;

const DropDownListItemContainer = styled.div`
  border-bottom: 1px solid #E8E8E8;
  padding: 12px 24px 8px;
  
  /* text-align: center; */
`;

const ChangeBackgroundDisplayInput = ({ 
  value, onChange, 
  currentlySelectedSceneBackgroundDisplayType, setCurrentlySelectedSceneBackgroundDisplayType,
  currentlySelectedSceneBackgroundDisplayPosition, setCurrentlySelectedSceneBackgroundDisplayPosition,
  whichModalIsOpen, setWhichModalIsOpen,
  backgroundImageFileInput,
  context
}) => {

  const [valueConvertedToHex, setValueConvertedToHex] = useState(value);
  const [isColorTabOpen, setIsColorTabOpen] = useState(true); 
  const [firstRadioButtonSelected, setFirstRadioButtonSelected] = useState(true);
  const [unSavedBackgroundDisplayColor, setUnSavedBackgroundDisplayColor] = useState(value); 
  const [unSavedBackgroundDisplayType, setUnSavedBackgroundDisplayType] = useState(currentlySelectedSceneBackgroundDisplayType); 
  const [unSavedBackgroundDisplayPosition, setUnSavedBackgroundDisplayPosition] = useState(currentlySelectedSceneBackgroundDisplayPosition); 
  const [unSavedBackgroundImage, setUnSavedBackgroundImage] = useState(backgroundImageFileInput); 
  const [shouldShowDropDown, setShouldShowDropDown] = useState(false);

  const handleWhichModalIsOpen = () => {
    if (whichModalIsOpen === 'ChangeBackgroundDisplayInput') {
      setWhichModalIsOpen(null);
      return; 
    };
    setWhichModalIsOpen('ChangeBackgroundDisplayInput');
  };

  const saveBackgroundDisplayChanges = () => {
    onChange(valueConvertedToHex);
    setCurrentlySelectedSceneBackgroundDisplayType(unSavedBackgroundDisplayType);
    setCurrentlySelectedSceneBackgroundDisplayPosition(unSavedBackgroundDisplayPosition); 
    // @todo: Lewis - re-render this scene
    // create new property: didBackgroundChange = true and in animate function re-render it, and after re-render set this to false; 
    // use custom hooks 
    if (unSavedBackgroundImage) {
      const fileName = unSavedBackgroundImage.file.name;
      const fileType = fileName.substring(fileName.length - 3, fileName.length);
      console.log('fileType', fileType);
      if (firstRadioButtonSelected) {
        if (fileType === 'exr') {
          console.log('this ran213')
          loadNewBackgroundDisplayImageForExr(unSavedBackgroundImage.src, context.scene, true)
        } else if (fileType === 'hdr') {
          loadNewBackgroundDisplayImageForHdr(unSavedBackgroundImage.src, context.scene, true)
        } else {
          loadNewBackgroundDisplayImage(unSavedBackgroundImage.src, context.scene, true);
        }
      } else {
        if (fileType === 'exr') {
          loadNewBackgroundDisplayImageForExr(unSavedBackgroundImage.src, context.scene)
        } else if (fileType === 'hdr') {
          loadNewBackgroundDisplayImageForHdr(unSavedBackgroundImage.src, context.scene)
        } else {
          loadNewBackgroundDisplayImage(unSavedBackgroundImage.src, context.scene);
        }
      };
    };
    handleWhichModalIsOpen();
  };

  const setGradientPosition = (position) => {
    const height = Math.floor(position.y / 200 * 100); 
    const width = Math.floor(position.x / 200 * 100); 
    setUnSavedBackgroundDisplayPosition({height, width})
  };

  return (
    <>
    {whichModalIsOpen === 'ChangeBackgroundDisplayInput' && (
      <FloatingModalContainer>
        <ModalContainer>
          <TopOptionsContainer>
              <RadioButtonsContainer visible={isColorTabOpen}>
                <RadioButtonAndLabelContainer>
                  <RadioButton isSelected={firstRadioButtonSelected} onClick={() => setFirstRadioButtonSelected(true)} />
                  <div>{'360 Seamless'}</div>
                </RadioButtonAndLabelContainer>
                <RadioButtonAndLabelContainer>
                  <RadioButton isSelected={!firstRadioButtonSelected} onClick={() => setFirstRadioButtonSelected(false)} />
                  <div>{'Parallel'}</div>
                </RadioButtonAndLabelContainer>
              </RadioButtonsContainer>
              <TabSelector>
                <div className="tab-selector">
                  <TabContainer isSelected={isColorTabOpen} onClick={() => setIsColorTabOpen(true)}>{'Colour'}</TabContainer>
                  <TabContainer isSelected={!isColorTabOpen} onClick={() => setIsColorTabOpen(false)}>{'Image'}</TabContainer>
                </div>
              </TabSelector>
            </TopOptionsContainer>
          {isColorTabOpen ? (
            <>
              <DropDownContainer shouldShowDropDown={shouldShowDropDown}>
              <SelectedBackgroundContainer shouldShowDropDown={shouldShowDropDown} onClick={() => setShouldShowDropDown(!shouldShowDropDown)}>
                <div>{unSavedBackgroundDisplayType}</div>
                <div className="arrows-container">
                  <div className="arrow-up"></div>
                  <div className="arrow-down"></div>
                </div>
              </SelectedBackgroundContainer>
              <DropDownListContainer tabIndex='1'>
                {config.backgroundDisplayTypes.map((display, index) => (
                <DropDownListItemContainer
                  key={index}
                  id={index}
                  tabIndex={index}
                  onClick={() => {
                    setUnSavedBackgroundDisplayType(display.title);
                    setShouldShowDropDown(false);
                  }}
                >
                  {display.title}
                </DropDownListItemContainer>
                ))}
              </DropDownListContainer>
            </DropDownContainer>
            {unSavedBackgroundDisplayType !== 'Solid' && (
              <ColorPickerV4 
                value={valueConvertedToHex}
                onChange={setGradientPosition}
                selectedBackgroundDisplayType={unSavedBackgroundDisplayType}
                selectedBackgroundDisplayPosition={unSavedBackgroundDisplayPosition}
              />)
            }
            <ColorPickerV2
              color={unSavedBackgroundDisplayColor}
              setColor={setUnSavedBackgroundDisplayColor}
              labelText="Hex"
              setValueConvertedToHex={setValueConvertedToHex}
              selectedBackgroundDisplayType={unSavedBackgroundDisplayType}
            />
            </>
          ) : (
            <UploadBackgroundImage
              backgroundImageFileInput={unSavedBackgroundImage}
              setBackgroundImageFileInput={setUnSavedBackgroundImage} 
            />
          )}
          <CancelAndSaveButtonsContainer>
            <OptionButtonContainer onClick={() => setWhichModalIsOpen(null)}>{'Cancel'}</OptionButtonContainer>
            <OptionButtonContainer onClick={saveBackgroundDisplayChanges}>{'Save Changes'}</OptionButtonContainer>
          </CancelAndSaveButtonsContainer>
        </ModalContainer>
     </FloatingModalContainer>
      )}
      <ButtonBase primary className="set-opacity" onClick={handleWhichModalIsOpen}>
        {'Change Background Display'}
      </ButtonBase>
    </>
  );
};

export default ChangeBackgroundDisplayInput;
