import { useState, useEffect } from "react";

/*
 * Responsible for changing customer panels on click
 * Params *
 * @ state for each individual panel being open or closed
 * @ enable measurement -> specific for measurements, shows or hides btn for measurement
 */

export const useCustomerOptions = (
  setIsCustomerOptionsPanelOpen,
  setShouldShowAnnotations,
  enableMeasurement
) => {
  const [selectedPanel, setSelectedPanel] = useState(null);

  useEffect(() => {
    if (selectedPanel !== "annotations") {
      setIsCustomerOptionsPanelOpen(true);
    } else {
      setIsCustomerOptionsPanelOpen(false);
    }

    if (selectedPanel === "annotations") {
      setShouldShowAnnotations(true);
    } else {
      setShouldShowAnnotations(false);
    }

    if (selectedPanel === "measurement-comparison" && !enableMeasurement) {
      setSelectedPanel(null);
    }
  }, [selectedPanel, enableMeasurement]);

  return [selectedPanel, setSelectedPanel];
};
