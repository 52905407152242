import * as THREE from 'three';

// @todo: refactor later.
// Used every time a new object is added to the scene
export const normalizeModelScale = (object, mainModel=false) => {
  const bbox = new THREE.Box3().setFromObject(object);
  var center = bbox.getCenter(new THREE.Vector3());
  var size = bbox.getSize(new THREE.Vector3());
  var maxAxis = Math.max(size.x, size.y, size.z);
  if (mainModel) {
    object.scale.multiplyScalar(1.0 / maxAxis);
  } else {
    object.scale.multiplyScalar(0.5 / maxAxis);
  };
  bbox.setFromObject(object);
  bbox.getCenter(center);
  bbox.getSize(size);
  object.position.copy(center).multiplyScalar(-1);
  object.position.y-= (size.y * 0.5);
  return object;
};

export const placeModelOnFloor = (object) => {
  const bbox = new THREE.Box3().setFromObject(object);
  var center = bbox.getCenter(new THREE.Vector3());
  object.position.y-= (center.y);
  return object;
};

// Not used within code base ? 

// export const createRaycastSphere = (object) => {
//   const bbox = new THREE.Box3().setFromObject(object);
//   const center = bbox.getCenter(new THREE.Vector3());
//   const size = bbox.getSize(new THREE.Vector3());
//   const maxAxis = Math.max(size.x, size.y, size.z);
//   const sphereGeometry = new THREE.SphereGeometry(maxAxis, 32, 16);
//   const sphereMaterial = new THREE.MeshBasicMaterial({ color: 'red'});
//   const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
//   return sphere;
// };