import * as THREE from 'three' 
import { RayysFacingCamera } from "./RaysFacingCamera";
let facingCamera = new RayysFacingCamera();
let rootMain, helper, dimensionGroup, segment1, segment2 = null;

export const renderLines = (scene, context, sceneCamera, dim0, dim1) => {
  if(!rootMain) {
     rootMain = scene.getObjectByName('model_root');
     dimensionGroup = new THREE.Group();
     dimensionGroup.name = 'dimensionGroup';
     scene.add(dimensionGroup);
     helper = new THREE.BoxHelper(rootMain, 0xff0000);
     helper.geometry.computeBoundingBox();
     helper.geometry.computeBoundingSphere();
     helper.geometry.center();
     helper.update();
     segment1 = dim0;
     segment2 = dim1

     facingCamera.cb.facingDirChange.push(function(event) {
      let facingDir = facingCamera.dirs[event.current.best];

      if (dim0.node !== undefined) {
        dim0.detach();
      }
      if (dim1.node !== undefined) {
        dim1.detach();
      }

      let bbox = helper.geometry.boundingBox;
      if (Math.abs(facingDir.x) === 1) {
        let to = new THREE.Vector3(bbox.min.x, bbox.min.y, bbox.min.z);
        let from = new THREE.Vector3(bbox.max.x, bbox.min.y, bbox.max.z);
        let height = new THREE.Vector3(bbox.max.x, bbox.max.y, bbox.max.z);
        let newDimension = dim0.create(from, to, facingDir, height, `${context.objMeasurements.measurements.width}`, `${context.objMeasurements.measurements.length}`, `${context.objMeasurements.measurements.height}`, `${context.objMeasurements.unit}`);
        dimensionGroup.add(newDimension);
      }
      if (Math.abs(facingDir.z) === 1) {
        let to = new THREE.Vector3(bbox.min.x, bbox.min.y, bbox.min.z);
        let from = new THREE.Vector3(bbox.max.x, bbox.min.y, bbox.max.z);
        let height = new THREE.Vector3(bbox.max.x, bbox.max.y, bbox.max.z)

        let newDimension = dim0.create(from, to, facingDir, height, `${context.objMeasurements.measurements.width}`, `${context.objMeasurements.measurements.length}`, `${context.objMeasurements.measurements.height}`, `${context.objMeasurements.unit}`);
        dimensionGroup.add(newDimension);
      }
      if (Math.abs(facingDir.y) === 1) {
        let newArray = event.current.facing.slice();
        let bestIdx = newArray.indexOf(event.current.best);
        newArray.splice(bestIdx, 1);
  
        let facingDir0 = facingCamera.dirs[newArray[1]];
        let facingDir1 = facingCamera.dirs[newArray[0]];
  
        let to = new THREE.Vector3(bbox.min.x, bbox.min.y, bbox.min.z);
        let from = new THREE.Vector3(bbox.max.x, bbox.min.y, bbox.max.z);
        let height = new THREE.Vector3(bbox.max.x, bbox.max.y, bbox.max.z)
     
        let newDimension0 = dim0.create(from, to, facingDir1, height, `${context.objMeasurements.measurements.width}`, `${context.objMeasurements.measurements.length}`, `${context.objMeasurements.measurements.height}`, `${context.objMeasurements.unit}`, true);
        let newDimension1 = dim1.create(from, to, facingDir0, height, `${context.objMeasurements.measurements.width}`, `${context.objMeasurements.measurements.length}`, `${context.objMeasurements.measurements.height}`, `${context.objMeasurements.unit}`, true);
        
        dimensionGroup.add(newDimension0);
        dimensionGroup.add(newDimension1);
      }
    });
  }

  if (document.querySelector('.labelMeasurement') && document.querySelector('.labelMeasurement').parentElement.childElementCount > 2) {
    document.querySelector('.labelMeasurement').remove();
    document.querySelector('.labelTopMeasurement').remove();
  }

  facingCamera.check(sceneCamera);
};

// Convert Measurement Inputs
export const toImperial = (num) => {
  return Math.ceil(num * 0.393701);
};

export const toMetric = (num) => {
  return Math.floor(2.54 * num);
};

// Updates the bounding box of the helper object surronding the main object,
// Updates the Dimension line sizes
export const updateBoundingBox = (position, camera) => {
  if(helper) {
    dimensionGroup.position.copy(position)
    helper.geometry.computeBoundingBox();
    helper.geometry.center();
    helper.update();

    let bbox = helper.geometry.boundingBox;
    let to = new THREE.Vector3(bbox.min.x, bbox.min.y, bbox.min.z);
    let from = new THREE.Vector3(bbox.max.x, bbox.min.y, bbox.max.z);
    let height = new THREE.Vector3(bbox.max.x, bbox.max.y, bbox.max.z);
    segment1.from = from;
    segment1.to = to;
    segment1.height = height;
    segment2.from = from;
    segment2.to = to;
    segment2.height = height;
    segment1.update(camera);
    segment2.update(camera);
  }
}

export const handleMeasurementUnit = (unit, objMeasurements, setObjMeasurements, setMeasurementUnit) => {
  setMeasurementUnit(unit === 'cm' ? 'Metric (cm)' : 'Imperial (inches)');
  const length = unit === 'cm' ? toMetric(objMeasurements.length) : toImperial(objMeasurements.length);
  const width = unit === 'cm' ? toMetric(objMeasurements.width) : toImperial(objMeasurements.width);
  const height = unit === 'cm' ? toMetric(objMeasurements.height) : toImperial(objMeasurements.height);
  setObjMeasurements({...objMeasurements, unit, length, width, height});
};

// Scale is in meters, x = 1m
// Object can be any Object3D
export const sizeObjectForComparison = (object, scale) => {
  const geometry = new THREE.BoxGeometry(scale.x, scale.y, scale.z) ;
  geometry.computeBoundingBox();

  const modelBbox = new THREE.Box3().setFromObject(object);
  
  const minRatio = calcScaleRatio(geometry.boundingBox, modelBbox);

  object.scale.set(object.scale.x * minRatio, object.scale.y * minRatio, object.scale.z * minRatio);
};

export const calcScaleRatio = (bboxScene, bboxModel) => {

  const lengthSceneBounds = {
    x: Math.abs(bboxScene.max.x - bboxScene.min.x),
    y: Math.abs(bboxScene.max.y - bboxScene.min.y),
    z: Math.abs(bboxScene.max.z - bboxScene.min.z),
  };

  const lengthModelBounds = {
    x: Math.abs(bboxModel.max.x - bboxModel.min.x),
    y: Math.abs(bboxModel.max.y - bboxModel.min.y),
    z: Math.abs(bboxModel.max.z - bboxModel.min.z),
  };

  const lengthRatios = [
    (lengthSceneBounds.x / lengthModelBounds.x),
    (lengthSceneBounds.y / lengthModelBounds.y),
    (lengthSceneBounds.z / lengthModelBounds.z)
  ]

  return Math.min(...lengthRatios);
};

export const getScaleSize = (objMeasurements) => {
  let scaleSize = {
    x: objMeasurements.width / 100, 
    y: objMeasurements.height / 100, 
    z: objMeasurements.length / 100
  };

  // Convert from imperial units, to metric. Convert to meters
  if(objMeasurements.unit === 'in.') {
    scaleSize = {...scaleSize,
      x: toMetric(objMeasurements.width) / 100, 
      y: toMetric(objMeasurements.height) / 100,
      z: toMetric(objMeasurements.length) / 100,
    }
  };

  return scaleSize;
}

export const resetScene = (comparisonGroup, mainMesh, objMeasurements) => {
  if(comparisonGroup.currentlyViewed && comparisonGroup.currentlyViewed.visible) {
    comparisonGroup.hideObject(comparisonGroup.currentlyViewed);
    sizeObjectForComparison(mainMesh, getScaleSize(objMeasurements));
  }
}