import { useState } from 'react'; 

export const useMousePosition = (domElement) => {
  let [position, setPosition] = useState({x: 0, y: 0});

  const handleMouseMove = (e) => {
    if (domElement) {
      const bnds = domElement.current.getBoundingClientRect(); 
      setPosition({
        x: e.clientX - bnds.left, 
        y: e.clientY - bnds.top
      });
    };
  };

  return [position, handleMouseMove]; 
}
