import React, { useState } from 'react'
import { FloatingModalContainerV3 } from '../modals/shared';
import { deleteAllObjectsFromScene  } from '../../../threejs/helpers/scene-editor/dragAndDropObjects';
import styled from 'styled-components';
import ButtonBase from './buttons/ButtonBase';

const EditingEnviornmentOptionsParentContainer = styled.div`
    position: relative;
    width: 720px;
  
  .boxclose { 
    > img {
    }
  };
`;


const EditingEnviornmentOptionsContainer = styled.div`
  position: relative;
  display: flex; 
  flex-direction: column;
  justify-content: center;
  width: 100%; 
  height: 100%; 
  padding: 0 36px 0 36px;
  background: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;

  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  /* Firefox < 16 */
  @-moz-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }
  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }
  /* Internet Explorer */
  @-ms-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  backdrop-filter: blur(8px);
  border: 1px solid #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  .text { 
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    padding: 24px 48px 0 48px;
  }
`;

const ButtonsParentContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 16px 24px 16px;
  > *+* {
    margin-left: 16px; 
  }
`
const ButtonContainer = styled.div`
  border: 1px solid #ffffff; 
  text-align: center;
  flex: 1;
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color linear 200ms; 
  &:hover {
    background-color: rgba(1, 1, 1, 0.3);
  }
`;

const ButtonsParentContainerV2 = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 124px 24px 124px;
  > *+* {
    margin-left: 16px; 
  }
`;

const NewSceneNameContainer = styled.div`
  align-self: center;
  margin-top: 24px;
  > input {
    padding: 8px 64px 8px 64px;
    text-align: center;
  }
`;

const CloseSaveMenuButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  position: absolute;
  right: -1rem;
  top: -1rem;
  z-index: 10;
  cursor:pointer;
  border: 1px solid #ffffff;
  background:rgb(249, 249, 249);
  border-radius: 50%;
  transition: background-color linear 200ms; 
  
  > svg {
    color: #908E8A;
    transition: background-color linear 200ms; 
  }

  :hover {
    /* background-color: linear-gradient(to right bot, rgba(77, 77, 77), red); */
    background-color: rgba(110, 110, 110);
    color: #ffffff;
  }
`;

const FinishEditingEnviornmentInput = ({ 
  value, onChange, 
  selectedBackgroundDisplayType, setSelectedBackgroundDisplayType, 
  whichModalIsOpen, setWhichModalIsOpen,
  context
}) => {
  const [isNewSceneInputOpen, setIsNewSceneInputOpen] = useState(false); 
  const [newSceneTitle, setNewSceneTitle] = useState(''); 
  // @todo: better name.
  const handleWhichModalIsOpen = () => {
    if (whichModalIsOpen === 'FinishEditingEnviornmentInput') {
      setWhichModalIsOpen(null);
      return; 
    };
    setWhichModalIsOpen('FinishEditingEnviornmentInput');
    setIsNewSceneInputOpen(false);
  };

  const exitNoSaveHandler = () => {
    deleteAllObjectsFromScene(context);
    context.gridHelper.material.visible = false;
    context.setSelectedPanelForClientSide('scene');
    context.setPauseAutoRotateModel(!context.pauseAutoRotateModel);
    // const sceneBackground = new THREE.Color(value);
    // context.scene.background = new THREE.Color(sceneBackground.getHex()); 
  };

  const exitWithSaveHandler = () => {
    context.setScenes(prev => {
      console.log('currentcurrent scene objectscurrent scene objectscurrent scene objectscurrent scene objects scene objects', context.currentSceneObjects)
      const copy = {...prev};
      copy[context.selectedScene].sceneObjects = [...context.currentSceneObjects];
      copy[context.selectedScene].backgroundDisplayColor = value;
      context.setPauseAutoRotateModel(!context.pauseAutoRotateModel);
      return copy; 
    });
    // deleteAllObjectsFromScene(context);
    context.gridHelper.material.visible = false;
    context.setSelectedPanelForClientSide('scene');
  };

  const handleNewSceneName = (event) => {
    setNewSceneTitle(event.target.value);
  };
  
  const exitWithNewSceneHandler = () => {
    const newSceneSavedObjects = [...context.currentSceneObjects]; 
    const newScene = {
        title: newSceneTitle, 
        sceneObjects: newSceneSavedObjects,
        backgroundDisplayColor: value, 
        backgroundDisplayType: 'Solid', 
        backgroundImageFileInput: null,
        buttonOutlineColor: 'ffffff',
    };
    context.setScenes(prev => {
      const copy = {...prev};
      copy[newSceneTitle] = newScene; 
      return copy; 
    }); 
    context.setSceneTitles(prev => {
      const copy = [...prev];
      copy.push(newSceneTitle);
      return copy;
    })
    context.setPauseAutoRotateModel(!context.pauseAutoRotateModel);
    context.setSelectedScene(newSceneTitle);
    exitNoSaveHandler();
  };
  
  return (
    <>
      {whichModalIsOpen === 'FinishEditingEnviornmentInput' && (
        <FloatingModalContainerV3>
          {!isNewSceneInputOpen ? (
            <EditingEnviornmentOptionsParentContainer>
              <CloseSaveMenuButton onClick={() => handleWhichModalIsOpen()}>
                <svg width="25px" height="25px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                  <polygon fill="var(--ci-primary-color, currentColor)" points="427.314 107.313 404.686 84.687 256 233.373 107.314 84.687 84.686 107.313 233.373 256 84.686 404.687 107.314 427.313 256 278.627 404.686 427.313 427.314 404.687 278.627 256 427.314 107.313" className="ci-primary"/>
                </svg>
              </CloseSaveMenuButton>
              <EditingEnviornmentOptionsContainer>
                <div className="text">{'It looks like you\'re done editing! Save changes to keep your scene progress'}</div>
                <ButtonsParentContainer>
                  <ButtonContainer id="THIS ONE" onClick={exitNoSaveHandler}>
                    {'Don\'t save and leave'}
                  </ButtonContainer>
                  <ButtonContainer onClick={exitWithSaveHandler}>
                    {'Save Changes'}
                  </ButtonContainer>
                  <ButtonContainer onClick={() => {
                    setIsNewSceneInputOpen(true);
                    context.transformControls.showX = false; 
                    context.transformControls.showY = false; 
                    context.transformControls.showZ = false; 
                  }}>
                    {'Save as new scene'}
                  </ButtonContainer>
                </ButtonsParentContainer>
              </EditingEnviornmentOptionsContainer>
            </EditingEnviornmentOptionsParentContainer>
          ) : (
            <EditingEnviornmentOptionsParentContainer>
              {/* <a class="boxclose" id="boxclose"></a> */}
              <EditingEnviornmentOptionsContainer>
                <div className="text">{'Give your Scene a name!'}</div>
                <NewSceneNameContainer>
                  <input type="text" name="name" value={newSceneTitle} onChange={handleNewSceneName}/>
                </NewSceneNameContainer>
                <ButtonsParentContainerV2>
                  <ButtonContainer onClick={() => setIsNewSceneInputOpen(false)}>
                      {'Go back'}
                  </ButtonContainer>
                  <ButtonContainer onClick={exitWithNewSceneHandler}>
                      {'Save new scene'}
                  </ButtonContainer>
                </ButtonsParentContainerV2>
              </EditingEnviornmentOptionsContainer>
            </EditingEnviornmentOptionsParentContainer>
          )}
        </FloatingModalContainerV3>
      )}
      <div style={{marginTop: '24px'}}>
      <ButtonBase secondary onClick={handleWhichModalIsOpen}>
        {'Finish Editing Enviornment'}
      </ButtonBase>
      </div>
    </>
  )
}

export default FinishEditingEnviornmentInput;
