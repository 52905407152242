import React, { useState } from 'react';
import styled from 'styled-components';
import { DropDownContainer, DropDownListContainer, DropDownListItemContainer, SelectedObjectContainer } from './shared';

const DragAndDropObjectsInputContainer = styled.div`
  ${({ isLimitHit }) => isLimitHit && 'opacity: 0.5'};
  ${({ isLimitHit }) => isLimitHit && 'pointer-events: none'};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  /* margin: 12px;  */
  cursor: pointer;
  > *+* {
    margin-top: 16px;
  }
`;

const ObjectListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: auto;
  width: 100%;
  max-height: 180px;
  > * {
    width: 33.33%;
  }
`;

const ObjectListItemContainer = styled.div`
  background: rgba(196, 196, 196, 0.1);
  padding: 12px;
  /* width: 64px; */
  box-sizing: border-box;
  &:focus {
    background: rgba(196, 196, 196, 0.5);
  }
`;

const DragAndDropObjectsInput = ({ 
  value,
  selectedSceneTheme, setSelectedSceneTheme,
  maxSceneObjectCount, context
}) => {
  const [shouldShowDropDown, setShouldShowDropDown] = useState(false);
  return (
    <DragAndDropObjectsInputContainer isLimitHit={context.currentSceneObjects.length === maxSceneObjectCount}>
      <DropDownContainer shouldShowDropDown={shouldShowDropDown}>
        <SelectedObjectContainer shouldShowDropDown={shouldShowDropDown} onClick={() => setShouldShowDropDown(!shouldShowDropDown)}>
          <div>{selectedSceneTheme}</div>
          <div>
            <img src='images/icons/scene-editor-objects/down-arrow.svg' alt='down-arrow'/>
          </div>
        </SelectedObjectContainer>
        <DropDownListContainer>
          {value.map((object, index) => (
          <DropDownListItemContainer
            key={index}
            id={index}
            onClick={() => {
              setSelectedSceneTheme(object.title);
              setShouldShowDropDown(false);
            }}
          >
            {object.title}
          </DropDownListItemContainer>
          ))}
        </DropDownListContainer>
      </DropDownContainer>
      <ObjectListContainer>
        {value.filter(object => object.title === selectedSceneTheme)[0].items.map((img, imgIndex) => (
          <ObjectListItemContainer 
            key={imgIndex} 
            tabIndex={imgIndex}
            
            draggable={true}
            onDragStart={(e) => {
              e.dataTransfer.setData('model-object', JSON.stringify(img));
            }}
          >
            <img src={img.iconImagePath} alt="drag and drop object"/>
          </ObjectListItemContainer>
        ))}
      </ObjectListContainer>
    </DragAndDropObjectsInputContainer>
  );
};

export default DragAndDropObjectsInput;
