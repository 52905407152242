import { useContext } from 'react';

import { AppContext } from '../../../App';
import { lightingOptions } from '../../../config';
import MultiSelectInput from '../inputs/MultiSelectInput';
import ToggleInputWithLabel from '../inputs/ToggleInputWithLabel';
import { PanelOptionHeading } from './shared';
import SliderWithInputField from '../../ui/SliderWithInputField';

const LightingPanel = () => {
  const {
    // @todo: move these into ClientOptionsContext or such.
    shouldEnableLightingFeature, setShouldEnableLightingFeature,
    selectedLightingOptions, setSelectedLightingOptions,
    lightBrightness, setLightBrightness, context,
  } = useContext(AppContext);
  return (
    <div>
      <ToggleInputWithLabel
        label={'Enable Feature'}
        value={shouldEnableLightingFeature}
        onChange={newValue => setShouldEnableLightingFeature(newValue)}
      />
      <PanelOptionHeading variation={2}>{'Lighting Options'}</PanelOptionHeading>
      <MultiSelectInput
        options={lightingOptions.filter(o => !o.isDefault)}
        selectedOptions={selectedLightingOptions}
        onChange={updatedSelectedOptions => setSelectedLightingOptions(updatedSelectedOptions)}
      />
       <PanelOptionHeading variation={2}>{'Brightness'}</PanelOptionHeading>
       <SliderWithInputField lightBrightness={lightBrightness} setLightBrightness={setLightBrightness} context={context}/>
    </div>
  );
};

export default LightingPanel;

