import { useContext, useEffect } from 'react';
import { AppContext } from '../../../App';
import ButtonBase from '../inputs/buttons/ButtonBase';
import ColorComponentInput from '../inputs/ColorComponentInput';
import ColorSwatchInput from '../inputs/ColorSwatchInput';
import { ElementContainer } from './shared';
import { ButtonsContainer, DualButtonsContainer, SingleButtonContainer } from './shared';

const CustomizationPanel = ({ setSelectedPanel }) => {
  const {
    selectedSwatch, setSelectedSwatch,
    colorSwatches, setColorSwatches,
    colorSwatchesTitles, setColorSwatchesTitles,
    selectedComponent, setSelectedComponent, 
    colorComponentTitles, setColorComponentTitles,
    colorComponents, setColorComponents,
    isSingleComponent,
    unSavedComponents, setUnSavedComponents,
  } = useContext(AppContext);

  useEffect(() => {
    setUnSavedComponents(isSingleComponent ? JSON.parse(JSON.stringify(colorSwatches)) :  JSON.parse(JSON.stringify(colorComponents)));
  }, [colorComponents, colorSwatches])
  const saveComponents = () => {
    isSingleComponent ? 
      setColorSwatches(unSavedComponents)
    : 
      setColorComponents(unSavedComponents);
      setSelectedPanel(null)
  };

  return (
    <div>
      <ElementContainer>  
        {isSingleComponent ? (
          <ColorSwatchInput
            value={unSavedComponents}
            onChange={setUnSavedComponents}
            colorSwatchesTitles={colorSwatchesTitles}
            setColorSwatchesTitles={setColorSwatchesTitles}
            selectedSwatch={selectedSwatch}
            setSelectedSwatch={setSelectedSwatch}
          />
        ) : (
          // Legend #5
          <ColorComponentInput 
           colorComponents={unSavedComponents}
           setColorComponents={setUnSavedComponents}
           colorComponentTitles={colorComponentTitles}
           setColorComponentTitles={setColorComponentTitles}
           selectedComponent={selectedComponent}
           setSelectedComponent={setSelectedComponent}
          />
        )}
        <ButtonsContainer>
          <DualButtonsContainer>
            <ButtonBase primary fontWeight={600} >{'redo'}</ButtonBase>
            <ButtonBase primary fontWeight={600} >{'undo'}</ButtonBase>
          </DualButtonsContainer>
          <SingleButtonContainer onClick={saveComponents}>{'Save'}</SingleButtonContainer>
        </ButtonsContainer>
      </ElementContainer>
    </div>
  );
};

export default CustomizationPanel;

