import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { sizeObjectForComparison, updateBoundingBox } from './measurementHelpers';

export class ComparisonGroup {
  gltfLoader = new GLTFLoader();
  mainInitialPosition = new THREE.Vector3();
  
  constructor(scene, controls) {
    this.group = new THREE.Group();
    // This will change depending on which object is shown, Mannequin/Table
    this.position = new THREE.Vector3(0, -0.3 ,0);
    this.currentlyViewed = null;
    this.children = [];
    this.mainScene = scene;
    this.controls = controls;

    this.group.name = 'comparisonGroup';
    this.group.position.copy(this.position);
    scene.add(this.group)
  }

  addToGroup({ modelFilePath, size, name }) {
    this.gltfLoader.load(modelFilePath, (gltfModel) => {
      const model =  gltfModel.scene;
      model.name = name
      sizeObjectForComparison(model, size)
      
      this.children.push(model);
      this.group.add(model);
      
      if(this.currentlyViewed === null) {
        this.currentlyViewed = model;
      } else {
        this.hideObject(this.currentlyViewed);
        this.currentlyViewed = model;
      }

      if(name === 'Table') {
        this.positionOnTable();
      }

      if(name === 'Mannequin') {
        this.positionForMannequin();
      }
    });
  }

  hideObject(object) {
    this.mainScene.getObjectByName('model_root').parent.position.copy(this.mainInitialPosition);
    this.controls.target.set(0,0,0);
    updateBoundingBox(this.mainInitialPosition, this.camera);
    return object.visible = false;
  }

  showObject(object) {
    if(object.name === 'Table') {
      this.positionOnTable();
    }

    if(object.name === 'Mannequin') {
      this.positionForMannequin();
    }

   return object.visible = true;
  }

  changeCurrentlyViewed(object) {
    this.currentlyViewed = object;
    if(object.name === 'Table') {
      this.positionOnTable()
    }

    if(object.name === 'Mannequin') {
      this.positionForMannequin();
    }
    return object.visible = true;
  }

  find(objectName) {
    const object = this.group.getObjectByName(objectName);

    return object
  }

  repositionMain() {
    if(this.currentlyViewed.name === 'Table') {
      this.positionOnTable();
    }

    if(this.currentlyViewed.name === 'Mannequin') {
      this.positionForMannequin();
    }
  }

  // Refactor this
  // Re-compute comparison bounding box, center main mesh on it
  // Add half height of bounding box to main mesh position 
  positionOnTable() {
    this.group.position.x = 0;
    const mainMesh = this.mainScene.getObjectByName('model_root').parent;
    this.mainInitialPosition.copy(mainMesh.parent.position);
    updateBoundingBox(mainMesh.position, this.camera);

    const modelBbox = new THREE.Box3().setFromObject(mainMesh);

    const comparisonBbox = new THREE.Box3().setFromObject(this.currentlyViewed);
    comparisonBbox.getCenter(mainMesh.position);

    mainMesh.position.set(0, Math.abs(comparisonBbox.max.y - modelBbox.min.y), 0);
    this.controls.target.set(0, .4, 0);
  }

  positionForMannequin() {
    const mainMesh = this.mainScene.getObjectByName('model_root').parent;
    this.mainInitialPosition.copy(mainMesh.position);
    updateBoundingBox(mainMesh.position, this.camera);

    mainMesh.position.y = 1.08;
    mainMesh.position.z = 0.3;

    this.controls.target.set(0, 1, 0);
  }
}