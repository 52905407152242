import { useEffect, useState } from "react";

const useGetModalPosition = (parentElementRef) => {
  const [modalPosition, setModalPosition] = useState(null); 

  useEffect(() => {
    if (parentElementRef) {
      getModalPositionHandler(parentElementRef)
    }
  }, [parentElementRef])

  const getModalPositionHandler = (elementRef) => {
    const boundingBox = elementRef.current.getBoundingClientRect(); 
    setModalPosition({x: boundingBox.left + boundingBox.width, y: boundingBox.top}); 
  }
  return [modalPosition, getModalPositionHandler];
}

export default useGetModalPosition; 
