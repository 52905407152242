import { useContext } from 'react';
import { AppContext } from '../../../App';
import TextInput from '../inputs/TextInput';
import RichTextInput from '../inputs/RichTextInput';
import { PanelOption, PanelOptionHeading, ElementContainer } from './shared';

const ProductRedirectPanel = () => {
  const {
    productCheckoutURL, setProductCheckoutURL,
    briefProductDescription, setBriefProductDescription,
  } = useContext(AppContext);
  return (
    <div>
      <PanelOption>
        <PanelOptionHeading>{'Product Checkout URL'}</PanelOptionHeading>
        <ElementContainer>
          <TextInput value={productCheckoutURL} onChange={newValue => setProductCheckoutURL(newValue)} />
        </ElementContainer>
      </PanelOption>
      <PanelOption>
        <PanelOptionHeading>{'Brief Product Description'}</PanelOptionHeading>
        <ElementContainer>
          <RichTextInput value={briefProductDescription} onChange={newValue => setBriefProductDescription(newValue)} />
        </ElementContainer>
      </PanelOption>
    </div>
  );
};

export default ProductRedirectPanel;

