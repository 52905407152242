import { useContext } from "react";
import styled from "styled-components";
import RadioInput from "../../client/inputs/RadioInput";
import ToggleInput from "../../client/inputs/ToggleInput";
import { PanelOptionHeading } from "../../client/panels/shared";
import { AppContext } from "../../../App";
import {
  getScaleSize,
  handleMeasurementUnit,
  sizeObjectForComparison,
  updateBoundingBox,
} from "../../../threejs/comparisonClasses/measurementHelpers";

const flexRowCentered = `
display: flex;
justify-content: center;
align-items: center;
`;

const StyledToggle = styled.div`
  ${flexRowCentered}
  padding: 0 2rem;
  color: #000;

  > span {
    padding: 1rem;
  }
`;

const RadioContainer = styled.div`
  ${flexRowCentered}
  margin: 1rem 0;
  gap: 1rem;
`;

const StyledButton = styled.button`
  border-radius: 50%;
  border: 1px solid #000;
  height: 5rem;
  width: 5rem;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: #7688a3;
  }

  &:active {
    opacity: 0.5;
  }
`;

const ComparisonContainer = styled.div`
  ${flexRowCentered}
`;

const MeasurementComparisonPanel = () => {
  const {
    setMeasurementUnit,
    showMeasurements,
    setShowMeasurements,
    objMeasurements,
    setObjMeasurements,
    comparisonObjects,
    context,
  } = useContext(AppContext);

  const handleDimensions = (e) => {
    setShowMeasurements(e);
    context.setPauseAutoRotateModel(e);

    context.themeModelsGroup.visible = !context.themeModelsGroup.visible;

    const scene = context.scene;

    const mainMesh = scene.getObjectByName("model_root").parent;
    updateBoundingBox(mainMesh.position, context.sceneCamera);

    if (!e && document.querySelector(".labelRenderer")) {
      document.querySelector(".labelRenderer").style.display = "none";
    } else if (e && document.querySelector(".labelRenderer")) {
      document.querySelector(".labelRenderer").style.display = "block";
    }

    const dimensionGroup = scene.getObjectByName("dimensionGroup");
    if (dimensionGroup) {
      dimensionGroup.visible = e;
    }
  };

  // @Note Brandon: Refactor
  const viewCompareObjectHandler = (object) => {
    const scene = context.scene;
    const comparisonGroup = context.comparisonGroup;
    setShowMeasurements(false);
    context.setPauseAutoRotateModel(true);
    context.tween.current && context.tween.current.stop();
    context.themeModelsGroup.visible = !context.themeModelsGroup.visible;
    document.querySelector(".labelRenderer").style.display = "none";
    const dimensionGroup = scene.getObjectByName("dimensionGroup");
    if (dimensionGroup) {
      dimensionGroup.visible = false;
    }

    const mainMesh = scene.getObjectByName("model_root");

    if (
      !comparisonGroup.currentlyViewed ||
      !comparisonGroup.currentlyViewed.visible
    ) {
      sizeObjectForComparison(
        mainMesh.parent,
        getScaleSize(objMeasurements.measurements)
      );
    } else {
      sizeObjectForComparison(
        mainMesh.parent,
        context.objMeasurements.originalScale
      );
    }
    // needs refactoring, but works for now
    updateBoundingBox(mainMesh.parent.position, context.sceneCamera);
    context.dimensionLines.forEach((item) => {
      item.updateLabels(
        objMeasurements.measurements.width,
        objMeasurements.measurements.length,
        objMeasurements.measurements.height,
        objMeasurements.unit
      );
    });

    if (!comparisonGroup.find(object.name)) {
      return comparisonGroup.addToGroup(object);
    } else if (comparisonGroup.currentlyViewed.name !== object.name) {
      comparisonGroup.hideObject(comparisonGroup.currentlyViewed);
      return comparisonGroup.changeCurrentlyViewed(
        comparisonGroup.find(object.name)
      );
    }

    if (!comparisonGroup.currentlyViewed.visible) {
      return comparisonGroup.showObject(comparisonGroup.currentlyViewed);
    }

    context.setPauseAutoRotateModel(false);
    context.tween.current && context.tween.current.start();
    comparisonGroup.hideObject(comparisonGroup.currentlyViewed);
  };

  const createViewButtons = comparisonObjects.map((item, index) => (
    <div
      style={{
        display: item.enabled && item.name !== "None" ? "block" : "none",
      }}
      key={index}
    >
      <ComparisonContainer>
        <StyledButton onClick={() => viewCompareObjectHandler(item)}>
          <img src={item.iconPath} alt='comparison' />
        </StyledButton>
      </ComparisonContainer>
    </div>
  ));

  return (
    <div>
      <PanelOptionHeading
        style={{
          padding: "0 2rem 1rem 2rem",
          textAlign: "center",
          color: "#000",
          fontSize: "1em",
        }}
      >
        Dimensions
      </PanelOptionHeading>
      <StyledToggle>
        <span>Off</span>
        <ToggleInput
          value={showMeasurements}
          onChange={(e) => {
            handleDimensions(e);
          }}
        />
        <span>On</span>
      </StyledToggle>
      <RadioContainer>
        <RadioInput
          value={objMeasurements.unit === "cm"}
          id={"cm"}
          name='metric'
          label='cm'
          onClick={() =>
            handleMeasurementUnit(
              "cm",
              objMeasurements,
              setObjMeasurements,
              setMeasurementUnit
            )
          }
        />
        <RadioInput
          value={objMeasurements.unit === "in."}
          id={"inches"}
          name='imperial'
          label='inches'
          onClick={() =>
            handleMeasurementUnit(
              "in.",
              objMeasurements,
              setObjMeasurements,
              setMeasurementUnit
            )
          }
        />
      </RadioContainer>
      <br />
      <PanelOptionHeading
        style={{
          display: !comparisonObjects[0].enabled ? "block" : "none",
          padding: "0 2rem 1rem 2rem",
          textAlign: "center",
          color: "#000",
          fontSize: "1em",
        }}
      >
        View With Comparison
      </PanelOptionHeading>
      {createViewButtons}
    </div>
  );
};

export default MeasurementComparisonPanel;
