import { useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../../../App";
import ToggleInput from "../inputs/ToggleInput";
import { PanelOption, PanelOptionHeading } from "./shared";

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -16px;
  margin-left: -16px;
  padding-top: 16px;
`;

const GridItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-left: 16px;
  width: calc(50% - 16px);
  position: relative;
  > *:first-child {
    background-color: #d1d1d1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 96px;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background-color: #aaaaaa;
    }
    margin-bottom: 12px;
  }
  > *:last-child {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    > *:first-child {
      font-size: 14px;
      margin-bottom: 0;
    }
    > *:last-child {
      zoom: 0.65;
      > * > * {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        top: -2px;
        left: -2px;
      }
    }
  }
  .question-mark {
    font-size: 14px;
    background-color: #fff;
    position: absolute;
    top: 6px;
    right: 6px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 20px;
    pointer-events: none;
    transition: opacity linear 100ms;
  }
  &:hover {
    .question-mark {
      opacity: 0;
    }
  }
`;

const LabelContainer = styled.div`
  margin-bottom: 12px;
`;
// @todo: are above dups?

const AugmentedRealityExperiencesPanel = () => {
  const {
    isVerticalARModeEnabled,
    setIsVerticalARModeEnabled,
    isHorizontalARModeEnabled,
    setIsHorizontalARModeEnabled,
    isTryOnARModeEnabled,
    setIsTryOnARModeEnabled,
    isSnapchatARModeEnabled,
    setIsSnapchatARModeEnabled,
    isImageARMarkerARModeEnabled,
    setIsImageARMarkerARModeEnabled,
  } = useContext(AppContext);
  return (
    <div>
      <PanelOption>
        <PanelOptionHeading>{"Shown In The Showroom"}</PanelOptionHeading>
        <GridContainer>
          <GridItemContainer>
            <div>
              <svg
                width='55'
                height='71'
                viewBox='0 0 55 71'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M13.75 12.8286L32.5364 4.16798V58.7737L13.8701 67.1053L13.75 12.8286Z'
                  fill='#F8F8F8'
                />
                <path
                  d='M32.784 58.2378L54.7828 58.2376L36.2102 67.1053L13.8697 67.1053L32.784 58.2378Z'
                  fill='#FBFBFB'
                />
                <path
                  d='M5.38891 16.6111C5.17412 16.3963 4.82588 16.3963 4.61109 16.6111L1.11091 20.1113C0.896125 20.3261 0.896125 20.6743 1.11091 20.8891C1.3257 21.1039 1.67394 21.1039 1.88873 20.8891L5 17.7778L8.11127 20.8891C8.32606 21.1039 8.6743 21.1039 8.88909 20.8891C9.10388 20.6743 9.10388 20.3261 8.88909 20.1113L5.38891 16.6111ZM4.61109 68.3889C4.82588 68.6037 5.17412 68.6037 5.38891 68.3889L8.88909 64.8887C9.10388 64.6739 9.10388 64.3257 8.88909 64.1109C8.6743 63.8961 8.32606 63.8961 8.11127 64.1109L5 67.2222L1.88873 64.1109C1.67394 63.8961 1.3257 63.8961 1.11091 64.1109C0.896126 64.3257 0.896126 64.6739 1.11091 64.8887L4.61109 68.3889ZM4.45 17L4.45 68L5.55 68L5.55 17L4.45 17Z'
                  fill='white'
                />
                <path
                  d='M41.3889 0.611091C41.1741 0.396303 40.8259 0.396303 40.6111 0.611091L37.1109 4.11127C36.8961 4.32606 36.8961 4.6743 37.1109 4.88909C37.3257 5.10388 37.6739 5.10388 37.8887 4.88909L41 1.77782L44.1113 4.88909C44.3261 5.10388 44.6743 5.10388 44.8891 4.88909C45.1039 4.6743 45.1039 4.32606 44.8891 4.11127L41.3889 0.611091ZM40.6111 51.3889C40.8259 51.6037 41.1741 51.6037 41.3889 51.3889L44.8891 47.8887C45.1039 47.6739 45.1039 47.3257 44.8891 47.1109C44.6743 46.8961 44.3261 46.8961 44.1113 47.1109L41 50.2222L37.8887 47.1109C37.6739 46.8961 37.3257 46.8961 37.1109 47.1109C36.8961 47.3257 36.8961 47.6739 37.1109 47.8887L40.6111 51.3889ZM40.45 1L40.45 51L41.55 51L41.55 1L40.45 1Z'
                  fill='white'
                />
              </svg>
            </div>
            <div className='question-mark'>?</div>
            <div>
              <LabelContainer>{"Vertical"}</LabelContainer>
              <ToggleInput
                value={isVerticalARModeEnabled}
                onChange={(newValue) => setIsVerticalARModeEnabled(newValue)}
              />
            </div>
          </GridItemContainer>
          <GridItemContainer>
            <div>
              <svg
                width='98'
                height='44'
                viewBox='0 0 98 44'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M35.3947 27.5534L97.003 27.5532L78.4304 36.421L16.4805 36.4209L35.3947 27.5534Z'
                  fill='#FBFBFB'
                />
                <path
                  d='M0.611091 17.6111C0.396303 17.8259 0.396303 18.1741 0.611091 18.3889L4.11127 21.8891C4.32606 22.1039 4.6743 22.1039 4.88909 21.8891C5.10388 21.6743 5.10388 21.3261 4.88909 21.1113L1.77782 18L4.88909 14.8887C5.10388 14.6739 5.10388 14.3257 4.88909 14.1109C4.6743 13.8961 4.32606 13.8961 4.11127 14.1109L0.611091 17.6111ZM83.3889 18.3889C83.6037 18.1741 83.6037 17.8259 83.3889 17.6111L79.8887 14.1109C79.6739 13.8961 79.3257 13.8961 79.1109 14.1109C78.8961 14.3257 78.8961 14.6739 79.1109 14.8887L82.2222 18L79.1109 21.1113C78.8961 21.3261 78.8961 21.6743 79.1109 21.8891C79.3257 22.1039 79.6739 22.1039 79.8887 21.8891L83.3889 18.3889ZM1 18.55H83V17.45H1V18.55Z'
                  fill='white'
                />
                <path
                  d='M30.6111 4.61109C30.3963 4.82588 30.3963 5.17412 30.6111 5.38891L34.1113 8.88909C34.3261 9.10388 34.6743 9.10388 34.8891 8.88909C35.1039 8.6743 35.1039 8.32606 34.8891 8.11127L31.7778 5L34.8891 1.88873C35.1039 1.67394 35.1039 1.3257 34.8891 1.11091C34.6743 0.896124 34.3261 0.896124 34.1113 1.11091L30.6111 4.61109ZM97.3889 5.38891C97.6037 5.17412 97.6037 4.82588 97.3889 4.61109L93.8887 1.11091C93.6739 0.896124 93.3257 0.896124 93.1109 1.11091C92.8961 1.3257 92.8961 1.67394 93.1109 1.88873L96.2222 5L93.1109 8.11127C92.8961 8.32606 92.8961 8.6743 93.1109 8.88909C93.3257 9.10388 93.6739 9.10388 93.8887 8.88909L97.3889 5.38891ZM31 5.55H97V4.45H31V5.55Z'
                  fill='white'
                />
              </svg>
            </div>
            <div className='question-mark'>?</div>
            <div>
              <LabelContainer>{"Horizontal"}</LabelContainer>
              <ToggleInput
                value={isHorizontalARModeEnabled}
                onChange={(newValue) => setIsHorizontalARModeEnabled(newValue)}
              />
            </div>
          </GridItemContainer>
          <GridItemContainer>
            <div>
              <svg
                width='69'
                height='54'
                viewBox='0 0 69 54'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle cx='42' cy='27' r='27' fill='white' />
                <rect
                  x='10'
                  y='21'
                  width='22'
                  height='12'
                  rx='2'
                  fill='#999999'
                />
                <rect
                  x='38'
                  y='21'
                  width='21'
                  height='12'
                  rx='2'
                  fill='#999999'
                />
                <line x1='30' y1='27.5' x2='39' y2='27.5' stroke='#999999' />
                <path
                  d='M1 21.6667C2.26762 19.9425 2.8512 18.3955 3.5 15C3.65506 17.78 4.0598 19.2807 6 21.6667C3.99393 23.6496 3.76849 24.8486 3.5 27C3.1087 24.3949 2.70794 23.0978 1 21.6667Z'
                  fill='white'
                  stroke='white'
                />
                <path
                  d='M8 12.8889C8.76057 11.8831 9.11072 10.9807 9.5 9C9.59303 10.6216 9.83588 11.4971 11 12.8889C9.79636 14.0456 9.6611 14.745 9.5 16C9.26522 14.4804 9.02477 13.7237 8 12.8889Z'
                  fill='white'
                  stroke='white'
                />
              </svg>
            </div>
            <div className='question-mark'>?</div>
            <div>
              <LabelContainer>{"Try-On"}</LabelContainer>
              <ToggleInput
                value={isTryOnARModeEnabled}
                onChange={(newValue) => setIsTryOnARModeEnabled(newValue)}
              />
            </div>
          </GridItemContainer>
          <GridItemContainer>
            <div>
              <img
                style={{ height: 80, width: 80 }}
                src='/images/icons/client-panel/Button_Snapchat_White_Clear.svg'
                alt='SnapChat'
              />
            </div>
            <div className='question-mark'>?</div>
            <div>
              <LabelContainer>{"Snapchat"}</LabelContainer>
              <ToggleInput
                value={isSnapchatARModeEnabled}
                onChange={(newValue) => setIsSnapchatARModeEnabled(newValue)}
              />
            </div>
          </GridItemContainer>
        </GridContainer>
      </PanelOption>
      <PanelOption>
        <PanelOptionHeading>{"Image AR Marker"}</PanelOptionHeading>
        <GridContainer>
          <GridItemContainer style={{ width: "100%" }}>
            <div>
              <img
                style={{ height: 110, width: 110 }}
                src='/images/icons/client-panel/image_based_icon.svg'
                alt='AR Marker'
              />
            </div>
            <div className='question-mark'>?</div>
            <div>
              <LabelContainer>{"Image AR Marker"}</LabelContainer>
              <ToggleInput
                value={isImageARMarkerARModeEnabled}
                onChange={(newValue) =>
                  setIsImageARMarkerARModeEnabled(newValue)
                }
              />
            </div>
          </GridItemContainer>
        </GridContainer>
      </PanelOption>
    </div>
  );
};

export default AugmentedRealityExperiencesPanel;
