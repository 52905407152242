import { useRef } from "react";
import styled from "styled-components";
import ButtonBase from "./buttons/ButtonBase";

const ImageFileInputContainer = styled.div`
  > * + * {
    margin-top: 24px;
  }
`;

const ImagePreviewContainer = styled.div`
  background: #eeeeee;
  color: rgba(0, 0, 0, 0.125);
  border: 1px dashed #000000;
  box-sizing: border-box;
  align-self: center;
  text-align: center;
  padding: 24px;
  /* margin: 4px 24px; */
`;

export const ImageContainer = styled.div`
  align-self: center;
  > img {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
  }
`;

const FileSelectorContainer = styled.div`
  display: flex;
  justify-content: center;
  /* padding: 8px 16px; */
  /* padding-top: 16px; */
  > div {
    display: flex;
    justify-content: space-between;
    flex: 1;
    > * + * {
      margin-left: 16px;
    }
  }
`;

const FileNameDisplayContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border: ${({ dashedborder }) => (dashedborder ? "1px dashed #969595" : null)};
  border-radius: 5px;
  box-sizing: border-box;
  align-self: center;
  padding: 11px 0;
  text-align: center;
  ${({ isEmpty }) =>
    isEmpty &&
    `
    color: rgba(0, 0, 0, 0.125);
  `}
  flex: 1;
  > div {
    padding: 0 12px;
  }
  /* margin-left: 16px; */
`;

const UploadButtonContainer = styled.div`
  background: #072b62;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 5px;
  color: #ffffff;
  align-self: center;
  padding: 12px 16px;
  cursor: pointer;
`;

const DeleteButtonContainer = styled.div`
  align-self: center;
  box-sizing: border-box;
`;

const ImageFileInput = ({ value, onChange }) => {
  const imageFileRef = useRef(null);

  const onFileUpload = (input) => {
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = async function (e) {
        await onChange({ file: input.files[0], src: e.target.result });
      };
      reader.readAsDataURL(input.files[0]);
    }
  };

  const onFileDelete = () => {
    onChange(null);
  };

  return (
    <ImageFileInputContainer>
      {value ? (
        <ImageContainer>
          <img src={value.src} alt='File Input' />
        </ImageContainer>
      ) : (
        <ImagePreviewContainer>{"Image Preview"}</ImagePreviewContainer>
      )}
      <FileSelectorContainer>
        <div>
          <FileNameDisplayContainer dashedborder={true} isEmpty={!value}>
            <div>{value ? value.file.name : "Empty"}</div>
          </FileNameDisplayContainer>
          <ButtonBase secondary onClick={() => imageFileRef.current.click()}>
            <input
              type='file'
              accept='.png, .svg'
              ref={imageFileRef}
              onChange={(event) => onFileUpload(event.target)}
              style={{ display: "none" }}
            />
            <span>{"Upload"}</span>
          </ButtonBase>
          {value && (
            <DeleteButtonContainer onClick={onFileDelete}>
              <img
                style={{ width: "32px", height: "32px" }}
                alt='Delete Icon'
                src='images/icons/scene-panel/delete_icon.svg'
              />
            </DeleteButtonContainer>
          )}
        </div>
      </FileSelectorContainer>
    </ImageFileInputContainer>
  );
};

export default ImageFileInput;
