import React, { useState } from "react";
import {
  ButtonsRowV2,
  List,
  ItemV2,
  Indicator,
  Label,
  FractionBarContainer,
  FractionBar,
} from "../panels/shared";
import {
  toggleModelVisibility,
  toggleModelLock,
} from "../../../threejs/helpers/scene-editor/dragAndDropObjects";
const ObjectsOnSceneInput = ({
  selectedSceneObject,
  setSelectedSceneObject,
  deleteSelectedObjectFromScene,
  deleteAllObjectsFromScene,
  maxSceneObjectCount,
  switchTransformControlsToSelectedObject,
  context,
}) => {
  const [forceUpdateSceneEditor, setForceUpdateSceneEditor] = useState(false);
  return (
    <>
      <ButtonsRowV2>
        <div
          id='underneathButtonsRow'
          onClick={() => {
            deleteSelectedObjectFromScene(context);
          }}
        >
          <img
            style={{ width: "24px", height: "24px" }}
            src='/images/icons/client-panel/delete_icon.svg'
            alt=''
          />
        </div>
        <div
          onClick={() => {
            deleteAllObjectsFromScene(context);
          }}
        >
          {"Delete All"}
        </div>
      </ButtonsRowV2>
      <List>
        {/* @todo: re-evaluate isSelected etc below. */}
        {/* scenes[selectedScene].sceneObjects.map */}
        {context.currentSceneObjects.map((object, index) => (
          <ItemV2
            key={object.id}
            isSelected={selectedSceneObject === object.label}
            tabIndex={index}
          >
            <div
              onClick={() => {
                object.visible = !object.visible;
                toggleModelVisibility(object, context);
                setForceUpdateSceneEditor(!forceUpdateSceneEditor);
              }}
            >
              {object.visible ? (
                <img src='/images/icons/client-panel/Show-Black.svg' alt='' />
              ) : (
                <img src='/images/icons/client-panel/Hide-Black.svg' alt='' />
              )}
            </div>
            <div
              onClick={() => {
                setSelectedSceneObject(object.label);
                switchTransformControlsToSelectedObject(
                  object.modelScene,
                  object.locked,
                  object.visible,
                  context
                );
              }}
            >
              {object.label}
            </div>
            <div
              onClick={() => {
                object.locked = !object.locked;
                toggleModelLock(object, context);
                setForceUpdateSceneEditor(!forceUpdateSceneEditor);
              }}
            >
              {object.locked ? (
                <img
                  src='images/icons/scene-editor-objects/lock.svg'
                  alt='lock-icon'
                  style={{ width: "16px", paddingLeft: "32px" }}
                />
              ) : (
                <img
                  src='images/icons/scene-editor-objects/unlock.svg'
                  alt='unlock-icon'
                  style={{ width: "16px", paddingLeft: "32px" }}
                />
              )}
            </div>
          </ItemV2>
        ))}
      </List>
      <Indicator>
        <Label>
          <div>{"Objects Placed"}</div>
          <div>
            {context.currentSceneObjects.length + "/" + maxSceneObjectCount}
          </div>
        </Label>
        <FractionBarContainer>
          <FractionBar
            fillFraction={
              context.currentSceneObjects.length / maxSceneObjectCount
            }
          />
        </FractionBarContainer>
      </Indicator>
    </>
  );
};

export default ObjectsOnSceneInput;
