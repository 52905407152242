import { css } from "@emotion/react";

export const PrimaryColours = (props) =>
  css`
    color: #072b62;
    border: 1px solid #072b62;

    &:hover {
      background-color: #7688a3;
      color: #fff;
    }
    &:active {
      background-color: #d3d8de;
      color: #072b62;
    }
  `;

export const SecondaryColours = (props) =>
  css`
    color: #fff;
    border: 1px solid #072b62;
    background-color: #072b62;

    &:hover {
      background-color: #7689a4;
      border-color: #7689a4;
      color: #fff;
    }
    &:active {
      background-color: #d3d8de;
      border-color: #d3d8de;
      color: #072b62;
    }
  `;
export const dynamicFontWeight = (props) =>
  css`
    font-weight: ${props.fontWeight};
  `;
