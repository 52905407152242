import React, { useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../../../App";
import ToggleInputWithLabel from "../inputs/ToggleInputWithLabel";
import HotspotsView from "../inputs/HotspotsView";
import ButtonBase from "../inputs/buttons/ButtonBase";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

  > button {
    width: 75%;
  }
`;

const AnnotationsPanel = () => {
  const {
    shouldEnableAnnotationsFeature,
    setShouldEnableAnnotationsFeature,
    shouldShowAnnotations,
    isEditingAnnotations,
    setIsEditingAnnotations,
  } = useContext(AppContext);
  return (
    <Layout>
      <div style={{ width: "100%" }}>
        <ToggleInputWithLabel
          label={"Enable Feature"}
          value={shouldEnableAnnotationsFeature}
          onChange={(newValue) => setShouldEnableAnnotationsFeature(newValue)}
        />
      </div>
      <ButtonBase
        secondary
        onClick={() => {
          if (isEditingAnnotations) {
            // @todo: save, etc.
          }
          setIsEditingAnnotations(!isEditingAnnotations);
        }}
      >
        {!isEditingAnnotations ? "Edit Annotations" : "Finish Editing"}
      </ButtonBase>
      {(isEditingAnnotations || shouldShowAnnotations) && <HotspotsView />}
    </Layout>
  );
};

export default AnnotationsPanel;
